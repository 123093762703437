import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  InferType,
  either,
  exact,
  exclude,
  objectOf,
  object,
  string,
  unknown,
} from '~/utils/type-guardian';
import { useReceiver } from '../../../../../comments/use-receiver';
import {
  createComment,
  addReaction,
  removeReaction,
  editComment,
  deleteComment,
} from '../../../../../API/comments';
import { Observable, reduce } from '../../../../../utils/observable';
import firebase from '../../../../../services/firebase';
import { de } from 'date-fns/locale';

export const createEventSchema = object({
  type: exact('CREATE'),
  data: object({
    author: unknown(),
    commentBody: string(),
    time: unknown(),
  }),
});

export const addReactionEventSchema = object({
  type: exact('ADD_REACTION'),
  data: object({
    author: unknown(),
    commentId: string(),
  }),
});

export const removeReactionEventSchema = object({
  type: exact('REMOVE_REACTION'),
  data: object({
    author: unknown(),
    commentId: string(),
  }),
});

export const editEventSchema = object({
  type: exact('EDIT'),
  data: object({
    author: unknown(),
    commentId: string(),
    commentBody: string(),
  }),
});

export const deleteEventSchema = object({
  type: exact('DELETE'),
  data: object({
    author: unknown(),
    commentId: string(),
  }),
});

/**
 * The schema of a single event.
 */
export const eventSchema = either(
  createEventSchema,
  addReactionEventSchema,
  removeReactionEventSchema
);

// TODO: perhaps move this somewhere else? Some other code needs it/
type CreateEvent = InferType<typeof createEventSchema>;
export type AddReactionEvent = InferType<typeof addReactionEventSchema>;
export type RemoveReactionEvent = InferType<typeof removeReactionEventSchema>;

type Event = CreateEvent | AddReactionEvent | RemoveReactionEvent;
type EventType = Event['type'];

export const untrustedEventSchema = object({
  data: exclude(unknown(), either(exact(null), exact(undefined))),
  eventId: string(),
  createdAt: string(),
});

// TODO: there is code duplication here. Get rid of it.
export type UntrustedEvent = InferType<typeof untrustedEventSchema>;

type PerformanceComment = {
  id: string;
  author: any;
  commentBody: string;
  createdAt: string;
  time: number;

  // NOTE: eventually this will become a lot richer
  reactions: string[];
};

function commentReducer(
  state: PerformanceComment[],
  { data, createdAt, eventId }: UntrustedEvent
): PerformanceComment[] {
  if (typeof data !== 'object' || !data) return state;
  if (!('type' in data)) return state;

  switch (data?.type) {
    case 'CREATE': {
      const validation = createEventSchema.validate(data);
      if (validation.isValid) {
        const result: PerformanceComment[] = [
          {
            id: eventId,
            author: validation.value.data.author,
            commentBody: validation.value.data.commentBody,
            time: validation.value.data.time || 0,
            createdAt,
            reactions: [],
          },
          ...state,
        ];
        return result;
      }
      return state;
    }
    case 'ADD_REACTION':
      {
        const validation = addReactionEventSchema.validate(data);
        if (validation.isValid) {
          const result = state.map(comment => {
            if (comment.id === validation.value.data.commentId) {
              const reactions = new Set(comment.reactions);
              const authorValidation = authorSchema.validate(
                validation.value.data.author
              );
              if (authorValidation.isValid) {
                reactions.add(
                  `${authorValidation.value.type}${authorValidation.value.id}`
                );
              }
              return {
                ...comment,
                reactions: [...reactions],
              };
            }
            return comment;
          });
          return result;
        }
      }
      break;
    case 'REMOVE_REACTION':
      {
        const validation = removeReactionEventSchema.validate(data);
        if (validation.isValid) {
          const result = state.map(comment => {
            if (comment.id === validation.value.data.commentId) {
              const reactions = new Set(comment.reactions);
              const authorValidation = authorSchema.validate(
                validation.value.data.author
              );
              if (authorValidation.isValid) {
                console.log(
                  `Deleting commnt ${authorValidation.value.type}${authorValidation.value.id}`
                );
                reactions.delete(
                  `${authorValidation.value.type}${authorValidation.value.id}`
                );
              }
              return {
                ...comment,
                reactions: [...reactions],
              };
            }
            return comment;
          });
          return result;
        }
      }
      break;
    case 'DELETE':
      {
        const validation = deleteEventSchema.validate(data);
        if (validation.isValid) {
          const result = state.filter(
            comment => comment.id !== validation.value.data.commentId
          );
          return result;
        }
      }
      break;
    case 'EDIT':
      {
        const validation = editEventSchema.validate(data);
        if (validation.isValid) {
          const result = state.map(comment => {
            if (comment.id === validation.value.data.commentId) {
              return {
                ...comment,
                commentBody: validation.value.data.commentBody,
              };
            }
            return comment;
          });
          return result;
        }
      }
      break;
    default:
      console.error('Unknown event', data);
  }

  return state;
}

export function useObservable<T>(observable: Observable<T>, initialValue: T): T;
export function useObservable<T>(observable: Observable<T>): T | undefined;
export function useObservable<T>(
  observable: Observable<T>,
  initialValue?: T
): T | undefined {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    const unsubscribe = observable.subscribe(setValue);
    return () => {
      unsubscribe();
    };
  }, [observable]);
  return value;
}

export function useChat({
  projectId,
  performanceId,
}: {
  projectId: string;
  performanceId: string;
}): PerformanceComment[] {
  const commentEvents = useReceiver({ projectId, performanceId });
  const [state, setState] = useState<PerformanceComment[]>([]);
  useEffect(
    () => reduce(commentEvents, commentReducer, []).subscribe(setState),
    [commentEvents]
  );
  return state;
}

export const userAuthorSchema = object({
  type: exact('USER_AUTHOR'),
  id: string(),
});

export const guestAuthorSchema = object({
  type: exact('GUEST_AUTHOR'),
  id: string(),
});

export const authorSchema = either(userAuthorSchema, guestAuthorSchema);

export type UserAuthor = InferType<typeof userAuthorSchema>;
export type GuestAuthor = InferType<typeof guestAuthorSchema>;

export type Author = InferType<typeof authorSchema>;

const ChatContext = createContext<{
  comments: PerformanceComment[];
  createComment: (commentBody: string, time: number, author: Author) => void;
  addReaction: (commentId: string, author: Author) => void;
  removeReaction: (commentId: string, author: Author) => void;
  editComment: (commentId: string, commentBody: string, author: Author) => void;
  deleteComment: (commentId: string, author: Author) => void;
}>({
  comments: [],
  createComment: () => {},
  addReaction: () => {},
  removeReaction: () => {},
  editComment: () => {},
  deleteComment: () => {},
});

export const ChatProvider = ({
  projectId,
  performanceId,
  children,
}: {
  projectId: string;
  performanceId: string;
  children: React.ReactNode;
}) => {
  const comments = useChat({ projectId, performanceId });
  return (
    <ChatContext.Provider
      value={{
        comments,
        createComment: (body, time, author) => {
          createComment(
            { projectId, performanceId },
            { commentBody: body, time, author }
          );
        },
        addReaction: (commentId, author) => {
          addReaction({ projectId, performanceId }, { commentId, author });
        },
        removeReaction: (commentId, author) => {
          removeReaction({ projectId, performanceId }, { commentId, author });
        },
        editComment: (commentId, commentBody, author) => {
          editComment(
            { projectId, performanceId },
            { commentId, commentBody, author }
          );
        },
        deleteComment: (commentId, author) => {
          deleteComment({ projectId, performanceId }, { commentId, author });
        },
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
