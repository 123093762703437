import React, { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ReactPortal from '~/components/ReactPortal';
import { CloseButton, Container, ContentContainer } from './styles';

type BaseModalProps = {
  children: React.ReactNode;
  handleOnClose: () => void;
  isOpen: boolean;
  customContainerStyles?: React.CSSProperties;
  customContentContainerStyles?: React.CSSProperties;
  hideDefaultCloseButton?: boolean;
};

export type BaseModalSharedProps = Pick<
  BaseModalProps,
  'handleOnClose' | 'isOpen'
>;

const BaseModal = ({
  isOpen,
  children,
  handleOnClose,
  customContainerStyles,
  customContentContainerStyles,
  hideDefaultCloseButton,
}: BaseModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? handleOnClose() : null;

    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleOnClose]);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="react-portal-dashboard-base-modal">
      <Container style={customContainerStyles}>
        <ContentContainer style={customContentContainerStyles}>
          {!hideDefaultCloseButton && (
            <CloseButton onClick={handleOnClose}>
              <AiOutlineClose color="white" size={28} />
            </CloseButton>
          )}

          {children}
        </ContentContainer>
      </Container>
    </ReactPortal>
  );
};

export default BaseModal;
