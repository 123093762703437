import React, { useEffect, useState } from 'react';

type FallbackImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export function FallbackImg({ src, ...props }: FallbackImgProps) {
  const [imageSource, setImageSource] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      const actualSrc = src;
      const i = document.createElement('img'); // or new Image()
      i.onload = function () {
        setImageSource(actualSrc);
      };
      i.src = src;
    }
  }, [src]);

  return (
    <img
      {...props}
      src={
        imageSource
          ? imageSource
          : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOc8R8AAjUBmVXS+tkAAAAASUVORK5CYII='
      }
    />
  );
}
