import React from 'react';
import { FieldError } from 'react-hook-form';
import { ErrorMessage } from '../styles';
import { Container } from './styles';

type InputWithLabelAndBottomBorderProps = {
  label: string;
  customStyle?: React.CSSProperties;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  error?: FieldError;
  maxLength?: number;
};

const InputWithLabelAndBottomBorder = (
  props: InputWithLabelAndBottomBorderProps
) => {
  return (
    <>
      <Container style={props.customStyle}>
        <p>{props.label}</p>

        <input
          min="0"
          {...props.inputProps}
          maxLength={props.maxLength || 50}
        />
        {props.error && <ErrorMessage>{props.error.message}</ErrorMessage>}
      </Container>
    </>
  );
};

export default InputWithLabelAndBottomBorder;
