import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPerformance, updatePerformance } from '~/API/performances';
import { useUserStore } from '~/store/userStore';
import FullPageLoader from '~/components/Loaders/FullPageLoader';
import { Project } from './ProjectEditor/app/Project/Project';
import { ThemeProvider } from './ProjectEditor/contexts/theme';
import { ChatProvider } from './ProjectEditor/app/Project/chat';
import useIsMobile from '~/hooks/useIsMobile';
import { MobileContainer } from './styles';
import { MobileProject } from './ProjectEditor/app/Project/MobileProject';

export const ProjectViewer = ({
  initialProject,
  isPreview,
  projectId,
  performanceId,
  isMobile,
}: {
  initialProject: ProjectPerformance;
  isPreview: boolean;
  projectId: string;
  performanceId: string;
  isMobile: boolean;
}) => {
  const [project, setProject] = useState(initialProject);
  const user = useUserStore(state => state.user);

  if (!projectId || !performanceId) {
    throw new Error('What the fuck?');
  }

  return (
    <ChatProvider projectId={projectId} performanceId={performanceId}>
      <ThemeProvider override="dark">
        {!isMobile && (
          <Project
            user={user}
            performance={project}
            onProjectUpdated={proj => {
              const newProject = { ...project, ...proj };
              setProject(newProject);
              updatePerformance(projectId, performanceId, newProject);
            }}
            isPreview={isPreview}
          />
        )}
        {isMobile && (
          <MobileProject
            user={user}
            performance={project}
            onProjectUpdated={proj => {
              const newProject = { ...project, ...proj };
              setProject(newProject);
              updatePerformance(projectId, performanceId, newProject);
            }}
            isPreview={isPreview}
          />
        )}
      </ThemeProvider>
    </ChatProvider>
  );
};

type LoadingState<T> =
  | {
      type: 'LOADING';
    }
  | {
      type: 'SUCCESS';
      value: T;
    }
  | {
      type: 'ERROR';
    };

const ProjectView = () => {
  const [loadState, setLoadState] = useState<LoadingState<ProjectPerformance>>({
    type: 'LOADING',
  });

  const [selectedPerformance, setSelectedPerformance] =
    useState<ProjectPerformance>();

  const { projectId, performanceId } = useParams();
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (projectId && performanceId) {
          const response: ProjectPerformance = (
            await getPerformance(projectId, performanceId)
          ).data.data;
          if (response) {
            if (selectedPerformance?._id !== response?._id) {
              setSelectedPerformance(response);
            }
            setLoadState({ type: 'SUCCESS', value: response });
          } else {
            setLoadState({ type: 'ERROR' });
          }
        }
      } catch (e) {
        console.error(e);
        setLoadState({ type: 'ERROR' });
      }
    };

    fetchData();
  }, []);

  if (!projectId || !performanceId) {
    return <FullPageLoader />;
  }

  // if (isMobile) {
  //   return (
  //     <MobileContainer>
  //       <h2>Try a bigger screen</h2>
  //       <p>
  //         Our mobile app is on it’s way. In the mean time, please use StageKeep
  //         on your laptop or deskop for the best experience.
  //       </p>
  //       <a href="https://stagekeep.com/">Back to Website</a>
  //     </MobileContainer>
  //   );
  // }

  return (
    <>
      {(() => {
        const loadStateType = loadState.type;
        switch (loadStateType) {
          case 'LOADING':
            return <FullPageLoader />;
          case 'SUCCESS':
            return (
              <ProjectViewer
                projectId={projectId}
                performanceId={performanceId}
                initialProject={loadState.value}
                isPreview={false}
                isMobile={isMobile}
              />
            );
          case 'ERROR':
            return (
              <span style={{ color: 'red' }}>
                Error! Failed to contact the performances API
              </span>
            );
          default:
            const _exhaustiveCheck: never = loadStateType;
        }
      })()}
    </>
  );
};

export default ProjectView;
