import formations from './assets/formations-icon.png';
import performers from './assets/performers-icon.png';
import presets from './assets/presets-icon.png';
import performerAdd from './assets/person-add.svg';
import colorPicker from './assets/color-picker.svg';
import trash from './assets/trash.svg';
import shape from './assets/shape.svg';
import circle from './assets/circle.svg';
import triangle from './assets/triangle.svg';
import square from './assets/square.svg';
import duplicate from './assets/duplicate.svg';
import stageKeep from './assets/stagekeep-icon.svg';
import undo from './assets/undo.svg';
import redo from './assets/redo.svg';
import film from './assets/film.svg';

export default Object.freeze({
  formations,
  performers,
  presets,
  performerAdd,
  colorPicker,
  trash,
  shape,
  circle,
  triangle,
  square,
  duplicate,
  stageKeep,
  undo,
  redo,
  film,
});
