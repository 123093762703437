import React, { useEffect, useState } from 'react';

import firebaseService from '~/services/firebase';
import { useUserStore } from '~/store/userStore';

import UnauthorizedRoutes from '~/routes/UnauthorizedRoutes';
import AuthorizedRoutes from '~/routes/AuthorizedRoutes';
import FullPageLoader from '~/components/Loaders/FullPageLoader';
import { LSTORAGE_USER_KEY } from './config/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const [loading, setLoading] = useState(true);
  const setTokens = useUserStore(state => state.setTokens);
  const authorized = useUserStore(state => state.authorized);
  const setAuthorized = useUserStore(state => state.setAuthorized);
  const setUnauthorized = useUserStore(state => state.setUnauthorized);
  const getMeDispatch = useUserStore(state => state.getMe);

  const authStateListener = () => {
    firebaseService.auth.onAuthStateChanged(async firebaseUser => {
      if (
        !firebaseUser ||
        firebaseUser.uid !== localStorage.getItem(LSTORAGE_USER_KEY)
      ) {
        setLoading(false);
        return setUnauthorized();
      }

      setTokens({
        //@ts-ignore
        accessToken: firebaseUser.accessToken,
      });

      await getMeDispatch(firebaseUser);

      setLoading(false);
      return setAuthorized();
    });
  };

  useEffect(() => {
    authStateListener();
  }, [authorized]);

  return (
    <>
      <ToastContainer
        position={'top-right'}
        autoClose={10000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
      {loading ? (
        <FullPageLoader />
      ) : authorized ? (
        <AuthorizedRoutes />
      ) : (
        <UnauthorizedRoutes />
      )}
    </>
  );
}

export default App;
