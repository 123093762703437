import React from 'react';
import { MusicIcon } from '~/assets/styles/styledBaseComponents';

export const PRICING = {
  free: 'FREE',
  basic: 'BASIC',
  pro: 'PRO',
  saltare: 'SALTARE',
  enterprise: 'ENTERPRISE',
};

export const DEFAULT_ENTITY_POSITION: Readonly<{
  x: number;
  y: number;
  z: number;
}> = {
  x: 0,
  y: 0,
  z: 1,
};

export const DEFAULT_ENTITY_SIZE = 20;

export const DEFAULT_ENTITY_CIRCLE_RADIUS = DEFAULT_ENTITY_SIZE / 2;

export const DEFAULT_FORMATION_DURATION = 15000;

export const DEFAULT_FORMATION_TRANSITION_DURATION = 3000;

export const FIVE_SECONDS_IN_PIXELS = 85;

export const SECOND_IN_PIXELS = FIVE_SECONDS_IN_PIXELS / 5;

export const TEN_MILLISECOND_IN_PIXELS = SECOND_IN_PIXELS / 100;

export const DEFAULT_THUMBNAIL_SIZE = 58;

export const PERFORMANCE_STATUS_MAP = {
  approved: {
    name: 'Approved',
    color: '#89FFA3',
  },
  needToReview: {
    name: 'Need to Review',
    color: '#FFE142',
  },
  notApproved: {
    name: 'Not Approved',
    color: '#FF8989',
  },
  inProgress: {
    name: 'In Progress',
    color: '#3D67A6',
  },
};

export const PERFORMER_COLORS = [
  '#FFFFFF',
  '#CECECE',
  '#000000',
  '#40B1AB',
  '#4299FF',
  '#806BFF',
  '#FFC042',
  '#EE3636',
  '#F7FC31',
];

export enum MusicTypes {
  noMusic = 'noMusic',
  '8CountBeat' = '8CountBeat',
  custom = 'custom',
}

export const PERFORMANCE_MUSIC_TYPES = [
  {
    name: 'No Music',
    value: 'noMusic' as const,
    icon: <MusicIcon src="/icons/MuteIcon.svg" alt="No Music" />,
  },
  {
    name: '8 Count',
    value: '8CountBeat' as const,
    icon: <MusicIcon src="/icons/8CountBeatIcon.svg" alt="8 Count Beat" />,
  },
  {
    name: 'Upload',
    value: 'custom' as const,
    icon: <MusicIcon src="/icons/UploadCloudIcon.svg" alt="Custom Music" />,
  },
];

export const DEFAULT_FORMATION: Formation = {
  id: '1',
  positions: [],
  duration: DEFAULT_FORMATION_DURATION,
  name: 'Default Formation',
  transitionDuration: DEFAULT_FORMATION_TRANSITION_DURATION,
};

export const DELIMITATION_RECTANGLE_Z = 0.5;

export const BACKEND_ROOT =
  import.meta.env.MODE === 'development'
    ? 'http://localhost:8080/v1'
    : 'https://stagekeep-api.onrender.com/v1';

export const WEBSOCKET_BACKEND_ROOT =
  import.meta.env.MODE === 'development'
    ? 'ws://localhost:8080/v1'
    : 'wss://stagekeep-api.onrender.com/v1';

const auth = '/auth';

export const AUTH_ENDPOINTS = {
  login: `${auth}/login`,
  register: `${auth}/register`,
  authProvider: `${auth}/provider`,
  forgot: `${auth}/forgot-password`,
  refresh: `${auth}/refresh`,
};

export const PROJECTS_ENDPOINTS = {
  projects: '/projects',
  getProject: function (projectId: string) {
    return `${this.projects}/${projectId}`;
  },
};

export const PERFORMANCES_ENDPOINTS = {
  performances: function (projectId: string) {
    return `/projects/${projectId}/performances`;
  },
  createPerformance: function (projectId: string) {
    return `/projects/${projectId}/performances`;
  },
  performance: function (projectId: string, performanceId: string) {
    return `/projects/${projectId}/performances/${performanceId}`;
  },
  publicPerformance: function (projectId: string, performanceId: string) {
    return `/projects/${projectId}/performances/public/${performanceId}`;
  },
};

export const PAYMENTS_ENDPOINTS = {
  pricing: function (sessionId: string) {
    return `/payments/pricing/${sessionId}`;
  },
};

export const USER_ENDPOINTS = {
  onboarding: `${auth}/onboarding`,
  priced: `${auth}/priced`,
  getMe: `${auth}/me`,
};

export const ROUTES_MAP = {
  forgot: '/forgot-password',
  register: '/register',
  onboarding: '/onboarding',
  thankyou: '/thankyou',
  login: '/',
  logout: '/logout',
  dashboard: '/',
  project: '/project/:projectId/:performanceId',
  preview: '/preview/:projectId/:performanceId',
};

export const LSTORAGE_USER_KEY = 'user';

export const DEFAULT_CANVAS_SIZE = {
  width: 1130,
  height: 620,
};

// https://cloudinary.com/documentation/upload_images#uploading_with_a_direct_call_to_the_rest_api
export const CLOUDINARY_URLS = {
  image: `https://api.cloudinary.com/v1_1/${
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
  }/image/upload`,
  auto: `https://api.cloudinary.com/v1_1/${
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME
  }/auto/upload`,
};

/**
 * How many pixels equals a meter.
 */
const BASE_UNIT_MULTIPIER = 14;

/**
 * All multipliers to convert units to pixels.
 */
export const UNITS_AS_PIXELS = Object.freeze({
  meter: BASE_UNIT_MULTIPIER,
  foot: 3.281 * BASE_UNIT_MULTIPIER,
  yard: 1.094 * BASE_UNIT_MULTIPIER,
  kilometer: 0.001 * BASE_UNIT_MULTIPIER,
  inch: 39.37 * BASE_UNIT_MULTIPIER,
});
