import dark from './dark/dark';
import light from './light/light';

// TODO: this is pointless. Just use CSS invert

type Icons = Readonly<{
  formations: string;
  performers: string;
  presets: string;
  performerAdd: string;
  colorPicker: string;
  trash: string;
  shape: string;
  circle: string;
  triangle: string;
  square: string;
  duplicate: string;
  stageKeep: string;
  undo: string;
  redo: string;
  film: string;
}>;

export function icon(mode: 'dark' | 'light'): Icons {
  switch (mode) {
    case 'dark':
      return dark;
    case 'light':
      return light;
  }
}
