import React, { Fragment } from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

import { useUserStore } from '~/store/userStore';
import { PRICING } from '~/config/constants';
import Checkbox from '~/components/Inputs/Checkbox';
import BaseModal from './BaseModal';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

type PriceModalProps = {
  isOpen: boolean;
  isPriced: boolean;
  handleOnClose: () => void;
};

const PriceModal = (props: PriceModalProps) => {
  const { isPriced, handleOnClose } = props;
  const pricedDispatch = useUserStore(state => state.priced);
  const user = useUserStore(state => state.user);

  const handlePriced = (checked: boolean): void => {
    pricedDispatch({
      isPriced: checked,
    });
  };

  return (
    <BaseModal
      {...props}
      handleOnClose={handleOnClose}
      hideDefaultCloseButton
      customContentContainerStyles={{
        backgroundColor: '#141712',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.4)',
        width: user?.pricing === PRICING.free ? '1035px' : '640px',
        minHeight: user?.pricing === PRICING.free ? '630px' : '290px',
        maxHeight: 'calc(100vh - 60px)',
        height: 'auto',
        padding: '55px 28px 50px 28px',
        overflow: 'auto',
        alignItems: user?.pricing === PRICING.free ? 'stretch' : 'center',
      }}
    >
      <Fragment>
        <CloseButton onClick={handleOnClose}>
          <AiOutlineClose color="white" size={32} />
        </CloseButton>
        {!user?.permission?.PERFORMANCES && (
          <Text>
            You’ve reached your limit of performances on your plan. Please
            upgrade your plan for more performances or delete some performances.
          </Text>
        )}
        {user?.pricing === PRICING.free ? (
          <>
            <Text>
              You are currently on a free plan. This means you have 1
              performance available. You can delete your performance when you're
              done and create a new one or upgrade to one of our paid plans.
            </Text>
            <stripe-pricing-table
              pricing-table-id={import.meta.env.VITE_STRIPE_PRICING_TABLE_ID}
              publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
              customer-email={user?.email}
            />
            <CheckboxContainer>
              <Checkbox
                label="Don't show this again"
                checked={isPriced}
                handleChange={handlePriced}
              />
            </CheckboxContainer>
          </>
        ) : (
          <>
            <ExternalLink
              href={import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_LINK}
            >
              Redirect to Customer Portal
            </ExternalLink>
            <MuteText>
              * You can manage subscription, billing, and invoicing on Customer
              Portal
            </MuteText>
          </>
        )}
      </Fragment>
    </BaseModal>
  );
};

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 21px;
`;

const Text = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #d6d6d6;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 28px;
  margin-bottom: 25px;
`;

const CheckboxContainer = styled.div`
  padding-top: 20px;
  padding-left: 28px;
`;

const ExternalLink = styled.a`
  border-radius: 7px;
  padding: 12px 30px;
  background-color: var(--mainGreen);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: fit-content;
  margin-top: 20px;
`;

const MuteText = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 20px;
  padding: 0 28px;
  margin-top: 15px;
`;

export default PriceModal;
