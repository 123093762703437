export type Optional<T> = [T] | null;

/**
 * A class that sends jobs to a worker and receives results.
 */
export class JobSender {
  private listener: ((message: Optional<any>) => void) | null = null;

  constructor(private worker: Worker) {
    worker.onmessage = event => {
      if (this.listener) {
        this.listener([event.data]);
        this.listener = null;
      }
    };
  }

  sendJob([message, transfer]: [any, Transferable[]] | [any]): Promise<
    Optional<any>
  > {
    if (this.listener) {
      return Promise.resolve(null);
    }

    return new Promise(resolve => {
      this.listener = resolve;
      if (transfer) this.worker.postMessage(message, transfer);
      else this.worker.postMessage(message);
    });
  }

  sendMessage([message, transfer]: [any, Transferable[]] | [any]): void {
    if (transfer) this.worker.postMessage(message, transfer);
    else this.worker.postMessage(message);
  }
}
