import React from 'react';
import styled from 'styled-components';

interface Props {
  fnClick: Function;
  children: string | JSX.Element;
}

export const Btn = styled.button`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  width: 100%;
  background-color: var(--mainGreen);
  color: var(--mainBlack);
  line-height: 1.5rem;
  text-align: center;
  border: 1px solid var(--mainGreen);
  cursor: pointer;
  border-radius: 9px;
  padding: 13px;
  &:hover {
    background-color: var(--btnHoverGreen);
    transform: scale(0.99, 0.99);
    -webkit-transform: scale(0.99, 0.99);
    -moz-transform: scale(0.99, 0.99);
    transition-timing-function: ease;
    transition: transform 0.2s;
  }
`;

export const MainBtn = ({ fnClick, children }: Props) => (
  <Btn onClick={e => fnClick(e)}>{children}</Btn>
);
