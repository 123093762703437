import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { uploadAsset } from '~/API/cloudinary';
import {
  generateInitialPositions,
  readFile,
  requestImageFromUser,
} from '~/config/utils';
import { DEFAULT_FORMATION, PERFORMANCE_MUSIC_TYPES } from '~/config/constants';
import {
  createPerformanceSelector,
  setLoadingPerformanceStateSelector,
  selectedProjectIdSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import { useUserStore } from '~/store/userStore';
import LargeButton from '~/components/Buttons/LargeButton';
import InputWithLabelAndBottomBorder from '~/components/Inputs/InputWithLabelAndBottomBorder';
import Spinner from '~/components/Loaders/Preloader';
import {
  BaseButton,
  Column,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import BaseModal, { BaseModalSharedProps } from './BaseModal';
import {
  BlueText,
  Body,
  BodyContent,
  GoldText,
  HeaderConatiner,
  HeaderTitle,
  ImageDropZone,
  MusicDropZone,
  MusicTypeItem,
  MusicTypeList,
  PerformanceContainer,
  PerformanceImagePreview,
  StageDesignPreviewContainer,
  Subtitle,
} from './styles';
import { AiFillRocket } from 'react-icons/ai';

type FormData = {
  performanceName: string;
  image: File;
  backgroundImage: File;

  // TODO: it's either '8CountBeat' or 'custom'. I actually don't like the
  //   distinction anyways. It should be either custom music, or some beat with
  //   a specified interval. Or maybe I'll need to talk to Axel about the type
  //   of music that is typically played by choreographers.
  musicType: MusicTypes | 'noMusic';
  music: File;
  whereDoYouStart: 'onStage' | 'offStage';
  howManyPerformers: number;
  width: number;
  height: number;
};

const CreatePerformanceModal = (props: BaseModalSharedProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      whereDoYouStart: 'onStage',
      musicType: 'noMusic',
      width: 40,
      height: 40,
    },
  });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pickedImage, setPickedImage] = useState<null | string>(null);
  const [pickedBackgroundImage, setPickedBackgroundImage] = useState<
    null | string
  >(null);
  const createPerformance = useProjectsStore(createPerformanceSelector);
  const setLoadingPerformanceState = useProjectsStore(
    setLoadingPerformanceStateSelector
  );
  const selectedProjectId = useProjectsStore(selectedProjectIdSelector);
  const user = useUserStore(state => state.user);
  const getMeDispatch = useUserStore(state => state.getMe);

  const {
    musicType,
    music,
    whereDoYouStart,
    performanceName,
    howManyPerformers,
  } = watch();

  const closeModal = () => {
    reset();
    setPickedImage(null);
    setIsLoading(false);
    props.handleOnClose();
  };

  useEffect(() => {
    register('image', { required: false });
    register('musicType', { required: true });
    register('whereDoYouStart', { required: false });
  }, [props.isOpen]);

  useEffect(() => {
    register('music', { required: musicType === 'custom' });
  }, [props.isOpen, musicType]);

  const handleOnDropImage = async (acceptedFiles: File[]) => {
    try {
      const imageDataString = await readFile(acceptedFiles[0]);
      setPickedImage(imageDataString as string);
      setValue('image', acceptedFiles[0]);
    } catch (error) {
      console.log('There was an error picking the image: ', error);
    }
  };

  const handleOnDropMusic = async (acceptedFiles: File[]) => {
    setValue('music', acceptedFiles[0]);
  };

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      onDrop: handleOnDropImage,
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },
      multiple: false,
    });

  const { getRootProps: getRootPropsMusic, getInputProps: getInputPropsMusic } =
    useDropzone({
      onDrop: handleOnDropMusic,
      accept: {
        'audio/*': [],
      },
      multiple: false,
    });

  const handleOnCreatePerformance = handleSubmit(async data => {
    setIsLoading(true);
    try {
      const musicObject: PerformancePayload['music'] =
        data.musicType === 'custom'
          ? {
              type: 'custom',
              value: {
                name: data.music.name,
                url: (await uploadAsset(music, 'auto'))?.secure_url as string,
              },
            }
          : data.musicType === '8CountBeat'
          ? { type: '8CountBeat' }
          : null;

      const imageResponse = await uploadAsset(data.image, 'image');
      const backgroundImageResponse = await uploadAsset(
        data.backgroundImage,
        'image'
      );

      const entities: [string, Entity][] = new Array(
        parseInt(howManyPerformers as unknown as string)
      )
        .fill(undefined)
        .map((_, index) => {
          return [
            uuidv4(),
            {
              name: `Performer ${index + 1}`,
              shape: 'circle',
              color: '#FFC042',
            },
          ];
        });

      const initialFormation: Formation = {
        ...DEFAULT_FORMATION,
        name: 'Formation 1',
        positions: generateInitialPositions(entities),
      };

      const payload: PerformancePayload = {
        name: data.performanceName,
        music: musicObject,
        imageUrl: imageResponse?.secure_url as string,
        entities,
        formations: [initialFormation],
        // stageDesign: {
        //   width: data.width,
        //   height: data.height,
        //   unit: 'feet',
        //   backgroundImage: backgroundImageResponse?.secure_url as string,
        // },
      };

      const performance = await createPerformance(payload);
      await getMeDispatch(user);
      toast.success('Performance was created');
      closeModal();

      setIsLoading(false);
      if (performance) {
        setTimeout(() => {
          setLoadingPerformanceState(false);
          navigate(`/project/${selectedProjectId}/${performance._id}`);
        }, 1000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('There was an error creating the performance', error);
      toast.error('Error creating the Performance');
    }
  });

  return (
    <BaseModal
      {...props}
      handleOnClose={closeModal}
      customContentContainerStyles={{
        height: '600px',
      }}
    >
      <Fragment>
        <HeaderConatiner style={{ height: '126px' }}>
          <HeaderTitle>NEW PERFORMANCE</HeaderTitle>
        </HeaderConatiner>

        <Body
          style={{
            paddingTop: '14px',
          }}
        >
          <BodyContent>
            <PerformanceContainer>
              <div>
                <InputWithLabelAndBottomBorder
                  maxLength={50}
                  label="Performance Name*"
                  error={errors.performanceName}
                  inputProps={{
                    type: 'text',
                    ...register('performanceName', {
                      required: 'The Performance Name is required',
                      value: '',
                    }),
                  }}
                />
                <InputWithLabelAndBottomBorder
                  label="How many performers?*"
                  error={errors.howManyPerformers}
                  customStyle={{
                    marginTop: '25px',
                  }}
                  inputProps={{
                    type: 'number',
                    ...register('howManyPerformers', {
                      required: 'The number of performers is required',
                    }),
                  }}
                />
                <Column
                  style={{
                    marginTop: '25px',
                  }}
                >
                  {/* <Subtitle>Stage Background</Subtitle>
                  <StageDesignPreviewContainer>
                    {pickedBackgroundImage ? (
                      <img
                        src={pickedBackgroundImage}
                        alt="Stage background image"
                      />
                    ) : (
                      <img src="/images/Grid.svg" alt="Canvas preview" />
                    )}
                  </StageDesignPreviewContainer> */}
                </Column>
              </div>
              <div>
                <Subtitle>Music Upload</Subtitle>
                <VerticalCenteredRow>
                  <MusicTypeList>
                    {[...PERFORMANCE_MUSIC_TYPES].reverse().map(_musicType => {
                      const shouldHide = _musicType.value === '8CountBeat';

                      if (shouldHide) {
                        return null;
                      }

                      return (
                        <MusicTypeItem
                          // style={
                          //   _musicType.value === 'custom'
                          //     ? {
                          //         borderColor: '#FFE142',
                          //       }
                          //     : {}
                          // }
                          key={_musicType.value}
                          isSelected={
                            !!musicType && _musicType.value === musicType
                          }
                          onClick={() => {
                            setValue('musicType', _musicType.value);
                          }}
                        >
                          {_musicType.icon}
                          <p
                          // style={
                          //   _musicType.value === 'custom'
                          //     ? {
                          //         color: '#FFE142',
                          //       }
                          //     : {}
                          // }
                          >
                            {_musicType.name}
                          </p>
                          {/* {_musicType.name === 'Upload' ? (
                            <AiFillRocket
                              style={{ paddingLeft: '5px' }}
                            ></AiFillRocket>
                          ) : null} */}
                        </MusicTypeItem>
                      );
                    })}
                  </MusicTypeList>

                  {musicType === 'custom' && (
                    <MusicDropZone
                      error={!!errors.music}
                      style={{
                        borderColor: errors.music ? '#ff4242' : '#FFE142',
                        pointerEvents: user?.permission?.CUSTOM_MUSIC_UPLOAD
                          ? 'auto'
                          : 'none',
                      }}
                    >
                      <div {...getRootPropsMusic()}>
                        <input {...getInputPropsMusic()} />
                        {user?.permission.CUSTOM_MUSIC_UPLOAD ? (
                          <img
                            src="/icons/MusicPaperIcon.svg"
                            alt="Music icon"
                          />
                        ) : (
                          <AiFillRocket
                            color="#FFE142"
                            size={26}
                            style={{ marginBottom: '4px' }}
                          ></AiFillRocket>
                        )}
                        <p
                          style={{
                            color: errors.music ? '#ff4242' : '#FFE142',
                          }}
                        >
                          {!!music ? (
                            <>{music.name}</>
                          ) : (
                            <>
                              {user?.permission.CUSTOM_MUSIC_UPLOAD ? (
                                <>
                                  Drop your music here, or{' '}
                                  <BlueText>click to upload</BlueText>
                                </>
                              ) : (
                                <>
                                  <GoldText>Upgrade</GoldText> your plan to
                                  upload music!
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </MusicDropZone>
                  )}
                </VerticalCenteredRow>
                <Subtitle>Image Upload</Subtitle>
                <VerticalCenteredRow>
                  <PerformanceImagePreview
                    src={
                      pickedImage
                        ? pickedImage
                        : '/images/PerformanceImageDefault.svg'
                    }
                    alt="Performance's Image"
                  />

                  <ImageDropZone error={!!errors.image}>
                    <div {...getRootPropsImage()}>
                      <input {...getInputPropsImage()} />
                      <img
                        src="/images/MiniLandscape.png"
                        alt="Mini landscape"
                      />
                      <p
                        style={{
                          color: errors.image ? '#ff4242' : 'white',
                        }}
                      >
                        Drop your image here, or{' '}
                        <BlueText>click to upload</BlueText>
                      </p>
                    </div>
                  </ImageDropZone>
                </VerticalCenteredRow>
              </div>
            </PerformanceContainer>
          </BodyContent>

          {!isLoading && (
            <LargeButton
              backgroundColor="#AAF17F"
              handleOnClick={handleOnCreatePerformance}
              text="CREATE PERFORMANCE"
            />
          )}
          {isLoading && <Spinner fz="4rem" h="100%" color="white" />}
        </Body>
      </Fragment>
    </BaseModal>
  );
};

export default CreatePerformanceModal;
