import { USER_ENDPOINTS } from '~/config/constants';
import { OnboardData, PricedData } from '~/config/interfaces';
import { SGApi } from './axios';

export function getMe() {
  return SGApi.get(USER_ENDPOINTS.getMe);
}

export function submitOnboarding(inputs: OnboardData) {
  return SGApi.post(USER_ENDPOINTS.onboarding, inputs);
}

export function submitIsPriced(inputs: PricedData) {
  return SGApi.post(USER_ENDPOINTS.priced, inputs);
}
