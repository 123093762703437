import styled, { css } from 'styled-components';

const baseStyles = css`
  border: 3px solid var(--mainBlack);
  width: 100%;
  border-radius: 7px;
  font-weight: 400;
  font-size: var(--fontRegular);
  padding: 13px;
  line-height: 1.4rem;
`;

const baseFlex = css`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 0 10px;
  width: 600px;
  margin: 0 auto;
  justify-content: center;
`;

export const ColumnContainer = styled.div`
  padding: 0 10px;
  width: 370px;
  margin: 0 auto;
  justify-content: center;
`;

export const itemWidth = css `
width: 370px;
`;

export const Logo = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 80px 0 60px;

  img {
    width: 30px;
    height: auto;
    object-fit: contain;
    margin-bottom: 20px;
  }

  p {
    font-family: 'Montserrat';
    letter-spacing: 5px;
    font-size: 22px;
  }
`;

export const GoogleAuth = styled.div`
  border: 3px solid var(--mainBlack);
  border-radius: 7px;
  position: relative;
  text-align: center;
  &:hover {
    outline: none !important;
    border:3px solid var(--mainGreen);
    border-radius: 7px;
`;

export const ImgContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  ${baseFlex}
`;

export const GoogleImg = styled.img`
  height: 27px;
  width: auto;
`;

export const Img = styled.img`
  height: 200px;
`;

export const GoogleAuthBtn = styled.button`
  font-weight: 800;
  line-height: 23px;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  border: 0px;
`;

export const Text = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  text-align: center;
  padding: 80px 0 40px;
`;

export const OrText = styled.div`
  text-align: center;
  padding: 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const FormControl = styled.div``;

export const FlexIt = styled.div`
  ${baseFlex}
  font-size: 15px;
  & > input {
    margin: 0 6px;
    height: 15px;
    width: 15px;
  }
`;

export const Label = styled.label``;

export const Input = styled.input`
  &::placeholder {
    color: inherit;
    font-size: 15px;
  }
  &:focus {
    outline: none !important;
    border:3px solid var(--mainGreen);
  }
  ${baseStyles}
`;

export const ForgotPwd = styled.div`
  margin: 10px;
  color: var(--darkGreen);
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 23px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const NoAccount = styled(ForgotPwd)`
  & > span {
    color: var(--darkGreen);
    font-weight: 600;
    cursor: pointer;
  }
  cursor: auto;
  padding: 0;
  color: var(--mainBlack);
`;

export const Select = styled.select`
  ${baseStyles};
`;
