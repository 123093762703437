import create, { StateSelector } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  createProject,
  getProject,
  getProjects,
  updateProject,
} from '~/API/projects';
import {
  createPerformance,
  getPerformance,
  updatePerformance,
} from '~/API/performances';

export const useProjectsStore = create(
  devtools<ProjectsStore>((set, get) => ({
    projects: [],
    loadingProject: false,
    selectedProject: null,
    selectedProjectId: null,
    savingProject: false,
    savingPerformance: false,
    loadingPerformance: false,
    selectedPerformance: null,
    selectedPerformanceId: null,
    setSelectedPerformance: performance => {
      const _performances = get().selectedProject?.performances.map(
        _performance => {
          if (_performance._id === performance._id) {
            return performance;
          } else {
            return _performance;
          }
        }
      );
      set({
        selectedPerformance: performance,
        selectedProject: {
          ...get().selectProject,
          // @ts-ignore
          performances: _performances,
        },
      });
    },
    selectPerformance: id => {
      const _selectedPerformance = get().selectedProject?.performances.find(
        performance => performance._id === id
      );

      set({
        selectedPerformanceId: id,
        selectedPerformance: _selectedPerformance,
      });
    },
    loadProjects: async () => {
      try {
        const response = (await getProjects()).data.data;
        const _projects = response;

        set({ projects: _projects });
      } catch (error) {
        console.log('There was an error loading the projects', error);
      }
    },
    selectProject: async id => {
      set({ loadingProject: true });
      try {
        const response = (await getProject(id)).data.data;
        set({
          selectedProjectId: id,
          selectedProject: response,
          loadingProject: false,
          selectedPerformance: null,
          selectedPerformanceId: null,
        });
      } catch (error) {
        console.log('There was an error getting the project: ', error);
        set({ loadingProject: false });
      }
    },
    createProject: async name => {
      try {
        const createdProject = (
          await createProject({
            name,
          })
        ).data.data;

        set({
          projects: [
            ...get().projects,
            {
              ...createdProject,
            },
          ],
        });
      } catch (error) {
        console.log('Error creating the project', error);
      }
    },
    updateProject: async updatedProject => {
      set({ savingProject: true });

      try {
        const response: Project = (
          await updateProject(get().selectedProjectId!, updatedProject)
        ).data.data;

        const oldProjects = get().projects;
        const projects = oldProjects.map(project =>
          project._id === get().selectedProjectId
            ? { ...project, ...response }
            : project
        );
        set({
          savingProject: false,
          selectedProject: {
            ...(get().selectedProject as Project),
            ...response,
          },
          projects,
        });
      } catch (error) {
        console.log('There was an error saving the project', error);
        set({ savingProject: false });
      }
    },
    openPerformance: async id => {
      try {
        get().selectPerformance(id);
        get().setLoadingPerformanceState(true);

        const response: ProjectPerformance = (
          await getPerformance(get().selectedProjectId!, id)
        ).data.data;

        set({ selectedPerformance: response });

        get().setLoadingPerformanceState(false);

        // we return the performance because we need its entities and formations to set them into the canvas store.
        return response;
      } catch (error) {
        console.log('There was an error getting the performance: ', error);
      }
    },
    createPerformance: async payload => {
      try {
        const _selectedProject = { ...(get().selectedProject as Project) };
        const response = (
          await createPerformance(get().selectedProjectId as string, payload)
        ).data.data;
        _selectedProject.performances = [
          ..._selectedProject.performances,
          response,
        ];

        const oldProjects = get().projects;
        const projects = oldProjects.map(project =>
          project._id === get().selectedProjectId
            ? { ...project, ..._selectedProject }
            : project
        );

        set({
          selectedProject: _selectedProject,
          projects,
        });

        return response;
      } catch (error) {
        console.log('There was an error creating the performance: ', error);
        return null;
      }
    },
    updatePerformance: async updatedPerformance => {
      set({ savingPerformance: true });

      try {
        // To fix: This have to be setted on the store. Not for the Project view save case, but for the dashboard save case. But for the moment its not possible to do since this endpoint is replacing/overwriting the entire object.
        await updatePerformance(
          get().selectedProjectId!,
          get().selectedPerformanceId!,
          updatedPerformance
        );

        set({ savingPerformance: false });
      } catch (error) {
        console.error('There was an error saving the performance', error);
        set({ savingPerformance: false });
      }
    },
    setLoadingPerformanceState: newState => {
      set({ loadingPerformance: newState });
    },
  }))
);

export const createProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['createProject']
> = state => state.createProject;

export const setLoadingPerformanceStateSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['setLoadingPerformanceState']
> = state => state.setLoadingPerformanceState;

export const projectsSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['projects']
> = state => state.projects;

export const loadProjectsSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['loadProjects']
> = state => state.loadProjects;

export const selectedProjectIdSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectedProjectId']
> = state => state.selectedProjectId;

export const createPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['createPerformance']
> = state => state.createPerformance;

export const selectProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectProject']
> = state => state.selectProject;

export const selectPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectPerformance']
> = state => state.selectPerformance;

export const selectedPerformanceIdSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectedPerformanceId']
> = state => state.selectedPerformanceId;

export const openPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['openPerformance']
> = state => state.openPerformance;

export const selectedProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectedProject']
> = state => state.selectedProject;

export const loadingProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['loadingProject']
> = state => state.loadingProject;

export const updateProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['updateProject']
> = state => state.updateProject;

export const savingProjectSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['savingProject']
> = state => state.savingProject;

export const selectedPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['selectedPerformance']
> = state => state.selectedPerformance;

export const loadingPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['loadingPerformance']
> = state => state.loadingPerformance;

export const updatePerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['updatePerformance']
> = state => state.updatePerformance;

export const savingPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['savingPerformance']
> = state => state.savingPerformance;

export const setSelectedPerformanceSelector: StateSelector<
  ProjectsStore,
  ProjectsStore['setSelectedPerformance']
> = state => state.setSelectedPerformance;
