import React, { useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';

import { ROUTES_MAP } from '~/config/constants/index';
import { LoginInputs } from '~/config/interfaces';
import firebaseService from '~/services/firebase';
import { useUserStore } from '~/store/userStore';
import Spinner from '~/components/Loaders/Preloader';
import { SKLogo } from './auxiliaries/Logo';
import { MainBtn } from './auxiliaries/MainBtn';
import {
  Container,
  GoogleAuth,
  ImgContainer,
  GoogleAuthBtn,
  GoogleImg,
  OrText,
  Form,
  FormControl,
  Label,
  Input,
  ForgotPwd,
  NoAccount,
  ColumnContainer,
} from './style';
import { loginInputs, turnArrToObj } from './data';
import googleImg from '~/assets/images/google.png';
import { version } from '../../../package.json';

const Login = () => {
  const loginDispatch = useUserStore(state => state.login);
  const authProviderDispatch = useUserStore(state => state.authProvider);
  const loading = useUserStore(state => state.loading);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState(turnArrToObj(loginInputs));

  async function handleLogin(e: React.MouseEvent) {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(
        firebaseService.auth,
        inputs.Email,
        inputs.Password
      );
      if (user) {
        loginDispatch(user, navigate);
      }
    } catch (error) {
      let errorMessage =
        'Sorry, something went wrong - please contact support@stagekeep.com';

      if ((error as any).code == 'auth/wrong-password') {
        errorMessage =
          'Bad Credentials: Please double check your email and password.';
      } else if ((error as any).code == 'auth/user-not-found') {
        errorMessage =
          'This email address is not in use, please register to use Stagekeep.';
      } else if (
        ['auth/invalid-email', 'auth/missing-email'].includes(
          (error as any).code
        )
      ) {
        errorMessage = 'Please provide a valid email address.';
      }
      toast.error(errorMessage);
    }
  }

  const signInWithGoogle = async () => {
    try {
      const user = await signInWithPopup(
        firebaseService.auth,
        firebaseService.googleProvider
      );
      if (user) {
        authProviderDispatch(user, navigate);
      }
    } catch (err) {
      console.log('There is an error signing up the user w/ Google', err);
    }
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  return (
    <ColumnContainer>
      <SKLogo />
      <GoogleAuth onClick={signInWithGoogle}>
        <ImgContainer>
          <GoogleImg src={googleImg} alt="Google logo" />
        </ImgContainer>
        <GoogleAuthBtn>Continue With Google</GoogleAuthBtn>
      </GoogleAuth>
      <OrText>or</OrText>
      <Form>
        {loginInputs.map((i, ind) => (
          <FormControl key={i}>
            <Label htmlFor={i}></Label>
            <Input
              type={i}
              placeholder={i}
              autoFocus={ind === 0 ? true : false}
              id={i}
              value={inputs[i]}
              name={i}
              onChange={handleChange}
            ></Input>
          </FormControl>
        ))}

        <MainBtn fnClick={handleLogin}>
          {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Login'}
        </MainBtn>
      </Form>
      <ForgotPwd>
        <Link to={ROUTES_MAP.forgot}>Forgot password?</Link>
      </ForgotPwd>

      <NoAccount>
        No account?{' '}
        <span>
          <Link to={ROUTES_MAP.register}>Register</Link>
        </span>
      </NoAccount>

      <span
        style={{
          color: '#c1c1c1',
          fontSize: '12px',
          position: 'absolute',
          bottom: 16,
          left: 16,
        }}
      >
        Version: {version}
      </span>
    </ColumnContainer>
  );
};

export default Login;
