import React, { Fragment } from 'react';

import BaseModal from './BaseModal';
import { Body, BodyContent, HeaderConatiner, HeaderTitle } from './styles';

type AcknowledgeModalProps = {
  isOpen: boolean;
  title: string;
  children: JSX.Element;
  handleOnClose: () => void;
};

const AcknowledgeModal = ({
  title,
  children,
  handleOnClose,
  ...props
}: AcknowledgeModalProps) => {
  return (
    <BaseModal
      {...props}
      handleOnClose={handleOnClose}
      customContentContainerStyles={{
        height: 'auto',
      }}
    >
      <Fragment>
        <HeaderConatiner style={{ height: '100px' }}>
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderConatiner>

        <Body>
          <BodyContent
            style={{ color: '#FFF', fontSize: '12px', lineHeight: 1.6 }}
          >
            {children}
          </BodyContent>
        </Body>
      </Fragment>
    </BaseModal>
  );
};

export default AcknowledgeModal;
