import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #b4ff87;
    outline: none;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 15px rgba(180, 255, 135, 0.3);
    }

    &:checked {
      background-color: #b4ff87;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 4px;
        top: 0;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:disabled {
      border-color: #c0c0c0;
      background-color: #c0c0c0;
      cursor: not-allowed;
    }

    &:disabled + span {
      color: #c0c0c0;
      cursor: not-allowed;
    }
  }

  span {
    color: #fff;
    font-size: 13px;
    line-height: 20px;
  }
`;
