import { useEffect, useRef } from 'react';
import { UntrustedEvent } from './types';
import { WEBSOCKET_BACKEND_ROOT } from '../config/constants';
import { Receiver } from './receiver';
import { createSubject } from '../utils/observable';

export function useReceiver({
  projectId,
  performanceId,
}: {
  projectId: string;
  performanceId: string;
}) {
  const subjectRef = useRef(createSubject<UntrustedEvent>());
  const observableRef = useRef({ subscribe: subjectRef.current.subscribe });
  const acknowledgedEventsRef = useRef(new Set<string>());

  useEffect(() => {
    acknowledgedEventsRef.current.clear();
    const receiver = new Receiver({
      objectRequestUrl: `/comments/${projectId}/comments-events/${performanceId}`,
      eventStreamUrl: `${WEBSOCKET_BACKEND_ROOT}/comments-events?subject=${`${projectId}${performanceId}`}`,
    });

    const unsubscribe = receiver.eventsStream.subscribe(event => {
      if (acknowledgedEventsRef.current.has(event.eventId)) return;
      acknowledgedEventsRef.current.add(event.eventId);
      subjectRef.current.emit(event);
    });

    return () => {
      acknowledgedEventsRef.current.clear();
      receiver.close();
      unsubscribe();
    };
  }, [projectId, performanceId]);

  return observableRef.current;
}
