import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: bold;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
  }
`;
