import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import project from 'vectorious/dist/core/project';

import { getPublicPerformance } from '~/API/performances';
import FullPageLoader from '~/components/Loaders/FullPageLoader';
import { ProjectViewer } from './ProjectView';
import useIsMobile from '~/hooks/useIsMobile';
import { MobileContainer } from './styles';

const PreviewView: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { projectId, performanceId } = useParams();

  const [selectedPerformance, setSelectedPerformance] =
    useState<ProjectPerformance | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!projectId && !performanceId) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      try {
        const response: ProjectPerformance = (
          await getPublicPerformance(
            projectId as string,
            performanceId as string
          )
        ).data.data;

        if (!response) {
          navigate('/');
          return;
        }

        setSelectedPerformance(response);
      } catch (e) {
        navigate('/');
        return;
      }
    };

    fetchData();
  }, [performanceId, projectId]);

  if (
    !selectedPerformance ||
    !selectedPerformance.formations ||
    !selectedPerformance.entities ||
    // TODO: this is bad
    !projectId ||
    !performanceId
  ) {
    return <FullPageLoader />;
  }

  // if (isMobile) {
  //   return (
  //     <MobileContainer>
  //       <h2>Try a bigger screen</h2>
  //       <p>
  //         Our mobile app is on it’s way. In the mean time, please use StageKeep
  //         on your laptop or deskop for the best experience.
  //       </p>
  //       <a href="https://stagekeep.com/">Back to Website</a>
  //     </MobileContainer>
  //   );
  // }

  return (
    <body>
      <ProjectViewer
        projectId={projectId}
        performanceId={performanceId}
        initialProject={selectedPerformance}
        isPreview={true}
        isMobile={isMobile}
      />
    </body>
  );
};

export default PreviewView;
