import { PROJECTS_ENDPOINTS } from '~/config/constants';
import { SGApi } from './axios';

export function getProjects() {
  return SGApi.get(PROJECTS_ENDPOINTS.projects);
}

export function getProject(projectId: string) {
  const _endpoint = PROJECTS_ENDPOINTS.getProject(projectId);
  return SGApi.get(_endpoint);
}

export function createProject(payload: Pick<Project, 'name'>) {
  return SGApi.post(PROJECTS_ENDPOINTS.projects, payload);
}

export function updateProject(projectId: string, payload: Partial<Project>) {
  return SGApi.put(PROJECTS_ENDPOINTS.getProject(projectId), payload);
}

export function deleteProject(projectId: string) {
  return SGApi.delete(PROJECTS_ENDPOINTS.getProject(projectId));
}

export function archiveProject(projectId: string) {
  return SGApi.post(PROJECTS_ENDPOINTS.getProject(projectId));
}
