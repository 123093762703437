export const loginInputs: ['Email', 'Password'] = ['Email', 'Password'];

export function turnArrToObj(arr: string[]): {
  [K in typeof loginInputs[number]]: string;
} {
  return arr.reduce((final: any, i) => {
    final[i] = '';
    return final;
  }, {});
}
