import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';

type VideoTimeline = {
  offset: number;
  totalLength: number;
  timestamp: number;
  onDrag: (n: number) => void;
};

export function VideoTimeline({
  offset,
  totalLength,
  timestamp,
  onDrag,
}: VideoTimeline) {
  const [cursorMouseDown, setCursorMouseDown] = useState(false);
  const parentDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (cursorMouseDown) {
        console.log(cursorMouseDown);

        const parentDiv = parentDivRef.current;
        if (!parentDiv) return;
        const rect = parentDiv.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const n = (x / rect.width) * totalLength;
        onDrag(Math.min(Math.max(0, n), totalLength));
      }
    };

    document.body.addEventListener('mousemove', listener);
    return () => {
      document.body.removeEventListener('mousemove', listener);
    };
  }, [cursorMouseDown]);

  useEffect(() => {
    const mouseUpListener = () => {
      setCursorMouseDown(false);
    };
    document.body.addEventListener('mouseup', mouseUpListener);
    return () => {
      document.body.removeEventListener('mouseup', mouseUpListener);
    };
  }, []);

  return (
    <div
      ref={parentDivRef}
      className={css`
        width: 100%;
        position: relative;
      `}
    >
      <div
        className={css`
          width: 100%;
          height: 5px;
          margin-top: 10px;
          display: flex;
        `}
      >
        <div
          style={{
            width: `${(offset / totalLength) * 100}%`,
          }}
          className={css`
            background: grey;
          `}
        ></div>
        <div
          style={{
            width: `${(1 - offset / totalLength) * 100}%`,
          }}
          className={css`
            background: #b4ff87;
            height: 100%;
          `}
        ></div>
      </div>

      <div
        style={{
          width: 15,
          height: 15,
          borderRadius: 10,
          background: 'white',
          position: 'absolute',
          top: 5,
          left: `calc(-5px + ${(timestamp / totalLength) * 100}%)`,
        }}
        onMouseDown={() => {
          setCursorMouseDown(true);
        }}
      ></div>
    </div>
  );
}
