import { css } from '@emotion/css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';

import useDrag from '~/hooks/use-drag';
import { Performance } from '../performance-project';
import { TimelineState, time } from '../timeline-state';

const cursorSize = 14;
const paddingSize = 10;

type TimelineProps = {
  performance: Performance;
  timelineState: TimelineState;
  timelineSeeked: (time: number) => void;
};

export function PlaybackTimeline({
  performance,
  timelineState,
  timelineSeeked,
}: TimelineProps) {
  const [dragHandlers, dragState, setDragState, dragRef] = useDrag();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [localFormations, setLocalFormations] = useState(
    performance.formations
  );

  const { isDragging, offsetX } = dragState;
  const { onMouseDown, onMouseUp, onTouchStart, onTouchEnd } = dragHandlers;
  const debouncedSetDragState = useCallback(debounce(setDragState, 20), []);

  const playbackProgress = time(performance, timelineState);

  const ratio = useMemo(
    () => (wrapperRef?.current?.offsetWidth || 0) / performance.totalTime,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wrapperRef?.current, performance.totalTime]
  );

  useEffect(() => {
    setLocalFormations(performance.formations);
  }, [performance.formations]);

  useEffect(() => {
    if (isDragging) {
      timelineSeeked(offsetX / ratio);
    }
  }, [isDragging, offsetX, ratio, timelineSeeked]);

  useEffect(() => {
    if (wrapperRef.current && !isDragging) {
      let position = playbackProgress * ratio;
      if (
        wrapperRef?.current &&
        position > wrapperRef?.current?.offsetWidth - cursorSize
      ) {
        position = wrapperRef?.current?.offsetWidth - cursorSize;
      }

      debouncedSetDragState(prevState => ({
        ...prevState,
        dragStartX: position,
        offsetX: position,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDragging,
    ratio,
    playbackProgress,
    localFormations,
    performance.totalTime,
    wrapperRef?.current,
    debouncedSetDragState,
  ]);

  const onMouseClickHandler = (event: React.MouseEvent): void => {
    const { clientX } = event;

    setDragState(prevState => ({
      ...prevState,
      dragStartX: clientX - paddingSize - cursorSize / 2,
      offsetX: clientX - paddingSize - cursorSize / 2,
    }));
    timelineSeeked((clientX - paddingSize - cursorSize / 2) / ratio);
  };

  const onTouchStartHandler = (event: React.TouchEvent): void => {
    const { clientX } = event.touches[0];

    setDragState(prevState => ({
      ...prevState,
      dragStartX: clientX - paddingSize - cursorSize / 2,
      offsetX: clientX - paddingSize - cursorSize / 2,
    }));
    timelineSeeked((clientX - paddingSize - cursorSize / 2) / ratio);
  };

  const getCursorPosition = (): number => {
    if (offsetX < 0) {
      return 0;
    }
    if (
      wrapperRef?.current &&
      offsetX > wrapperRef?.current?.offsetWidth - cursorSize
    ) {
      return wrapperRef?.current?.offsetWidth - cursorSize;
    }
    return offsetX;
  };

  return (
    <div
      className={css`
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      `}
    >
      <div
        style={{
          display: 'flex',
          height: '100%',
          padding: `12px ${paddingSize}px 0`,
          overflowY: 'hidden',
        }}
      >
        <div
          ref={wrapperRef}
          style={{
            background: '#A4A4A4',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
          onClick={onMouseClickHandler}
          onTouchStart={onTouchStartHandler}
        >
          <div
            style={{
              background: 'var(--mainGreen)',
              borderRadius: '16px',
              display: 'inline-block',
              width: getCursorPosition() + cursorSize / 2 + 2,
              height: cursorSize,
            }}
          ></div>
          <div
            style={{
              background: '#6d9c4f',
              borderRadius: '50%',
              width: cursorSize,
              height: cursorSize,
              position: 'absolute',
              left: getCursorPosition(),
              top: 0,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
            ref={dragRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
          ></div>
        </div>
      </div>
    </div>
  );
}
