let canvas: HTMLCanvasElement | null | undefined;
let context: CanvasRenderingContext2D | null | undefined;

export function canvasContext(): {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null;
} {
  if (!canvas) {
    canvas = document.createElement('canvas');
  }
  if (!context) {
    context = canvas.getContext('2d');
  }
  return { canvas, context };
}
