import React, { useEffect, useState } from 'react';

import {
  loadingProjectSelector,
  selectedProjectSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import { useUserStore } from '~/store/userStore';
import { PRICING } from '~/config/constants';
import useIsMobile from '~/hooks/useIsMobile';
import Spinner from '~/components/Loaders/Preloader';
import PriceModal from '~/components/Modals/PriceModal';
import Sidebar from './auxiliaries/Sidebar';
import ProjectHeader from './auxiliaries/ProjectHeader';
import PerformancesTable from './auxiliaries/PerformancesTable';
import ProjectInfo from './auxiliaries/ProjectInfo';
import { Body, Container, MobileContainer } from './styles';

const Dashboard: React.FunctionComponent = () => {
  const selectedProject = useProjectsStore(selectedProjectSelector);
  const loadingProject = useProjectsStore(loadingProjectSelector);
  const user = useUserStore(state => state.user);

  const [selectedTab, setSelectedTab] = useState<string>('table');
  const [isPriceModalOpen, setIsPriceModalOpen] = useState<boolean>(false);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const handleUpgrade = async (): Promise<void> => {
    if (user?.pricing === PRICING.free) {
      setIsPriceModalOpen(true);
    } else {
      window.location.href = import.meta.env.VITE_STRIPE_CUSTOMER_PORTAL_LINK;
    }
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <h2>Try a bigger screen</h2>
        <p>
          Our mobile app is on it’s way. In the mean time, please use StageKeep
          on your laptop or deskop for the best experience.
        </p>
        <a href="https://stagekeep.com/">Back to Website</a>
      </MobileContainer>
    );
  }

  return (
    <Container>
      <Sidebar handleUpgrade={handleUpgrade} />

      <Body>
        {loadingProject && <Spinner fz="4rem" h="100%" color="white" />}
        {!loadingProject && !!selectedProject && (
          <>
            <ProjectHeader
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleOpenPriceModal={() => setIsPriceModalOpen(true)}
            />
            {selectedTab === 'table' && <PerformancesTable />}
            {selectedTab === 'info' && <ProjectInfo />}
          </>
        )}
      </Body>

      <PriceModal
        isPriced={Boolean(user?.isPriced)}
        isOpen={isPriceModalOpen}
        handleOnClose={() => setIsPriceModalOpen(false)}
      />
    </Container>
  );
};

export default Dashboard;
