import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { AiFillCaretDown, AiOutlineClose } from 'react-icons/ai';
import { IoCopySharp, IoEarthSharp } from 'react-icons/io5';

import { getPerformance } from '~/API/performances';
import {
  savingPerformanceSelector,
  selectedPerformanceSelector,
  selectedProjectIdSelector,
  updatePerformanceSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import {
  BaseButton,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import Spinner from '~/components/Loaders/Preloader';
import BaseModal, { BaseModalSharedProps } from './BaseModal';

const SharePerformanceModal = (props: BaseModalSharedProps) => {
  const selectedPerfomance = useProjectsStore(selectedPerformanceSelector);
  const updatePerformance = useProjectsStore(updatePerformanceSelector);
  const selectedProjectId = useProjectsStore(selectedProjectIdSelector);
  const savingPerformance = useProjectsStore(savingPerformanceSelector);

  const [isPublic, setIsPublic] = useState(
    selectedPerfomance?.public ? selectedPerfomance?.public : false
  );

  const handleOnClickPublicButton = async (): Promise<void> => {
    try {
      const performanceData: ProjectPerformance = (
        await getPerformance(
          selectedProjectId as string,
          selectedPerfomance?._id as string
        )
      ).data.data;

      const payload = {
        ...performanceData,
        public: !isPublic,
      };

      await updatePerformance(payload);
      setIsPublic(!isPublic);
    } catch (error) {
      console.log('There was an error setting the project to public', error);
    }
  };

  const handleOnClickCopyLink = (): void => {
    navigator.clipboard.writeText(getUrl());
  };

  const getUrl = (): string => {
    const host = window.location.protocol + '//' + window.location.host;
    const url = `${host}/preview/${selectedProjectId as string}/${
      selectedPerfomance?._id as string
    }`;

    return url;
  };

  return (
    <BaseModal
      {...props}
      handleOnClose={props.handleOnClose}
      hideDefaultCloseButton
      customContainerStyles={{
        backgroundColor: '#00000063',
      }}
      customContentContainerStyles={{
        backgroundColor: '#2a2a2a',
        width: '637px',
        height: '271px',
        padding: '16px 25px 32px 25px',
      }}
    >
      <Fragment>
        <CloseButton onClick={props.handleOnClose}>
          <AiOutlineClose color="white" size={32} />
        </CloseButton>

        <Title>Share Your Performance</Title>
        <Subtitle>Link Access</Subtitle>

        <VerticalCenteredRow
          style={{
            marginBottom: '26px',
          }}
        >
          {isPublic && (
            <BaseButton
              onClick={handleOnClickCopyLink}
              backgroundColor="#4299FF"
            >
              <IoCopySharp
                style={{
                  marginRight: '7.5px',
                }}
                color="#FFF"
                size={18}
              />
              <p>Copy Link</p>
            </BaseButton>
          )}
          <BaseButton
            onClick={handleOnClickPublicButton}
            backgroundColor={isPublic ? '#7D7D7D' : '#4299FF'}
          >
            <IoEarthSharp
              style={{
                marginRight: '7.5px',
              }}
              color="#FFF"
              size={18}
            />
            <p>{isPublic ? 'Hide Performance' : 'Generate Link'}</p>
          </BaseButton>
          {/* <BaseButton onClick={() => {}} backgroundColor="#7D7D7D">
            <p>Options</p>
            <AiFillCaretDown
              color="#FFF"
              size={12}
              style={{
                marginLeft: '7.5px',
              }}
            />
          </BaseButton> */}

          {savingPerformance && <Spinner fz="28px" h="28px" color="black" />}
        </VerticalCenteredRow>

        {isPublic && (
          <VerticalCenteredRow>
            <Input
              style={{
                    backgroundColor: '#0f0f0f',
                    color: 'white',
                    border: '0.5px solid grey',
                  }}
             readOnly defaultValue={getUrl()} />
          </VerticalCenteredRow>
        )}
      </Fragment>
    </BaseModal>
  );
};

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 21px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 33px;
`;

const Subtitle = styled.h3`
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  color: #929292;
  margin-bottom: 12px;
`;

const Input = styled.input`
  background-color: #cacaca;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 8px 9px 9px 9px;
  line-height: 1.2rem;
`;

export default SharePerformanceModal;
