import React, { useState } from 'react';
// import { AiFillCaretDown } from 'react-icons/ai';
// import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_FORMATION } from '~/config/constants';
import { generateInitialPositions } from '~/config/utils';
import {
  BaseButton,
  Row,
  SpacedBetweenRow,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import {
  createPerformanceSelector,
  selectedPerformanceSelector,
  selectedProjectSelector,
  setLoadingPerformanceStateSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import { useUserStore } from '~/store/userStore';
import CreatePerformanceModal from '~/components/Modals/CreatePerformanceModal';
import SharePerformanceModal from '~/components/Modals/SharePerformanceModal';
import Spinner from '~/components/Loaders/Preloader';
import {
  AltBase,
  Body,
  Container,
  // Filter,
  Image,
  // MoreOptions,
  // SearchPerformanceInput,
  // SelectButton,
  Tab,
  Title,
} from './styles';

type ProjectHeaderProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  handleOpenPriceModal: () => void;
};

const ProjectHeader: React.FunctionComponent<ProjectHeaderProps> = (
  props: ProjectHeaderProps
) => {
  const selectedPerformance = useProjectsStore(selectedPerformanceSelector);
  const selectedProject = useProjectsStore(selectedProjectSelector) as Project;
  const createPerformance = useProjectsStore(createPerformanceSelector);
  const setLoadingPerformanceState = useProjectsStore(
    setLoadingPerformanceStateSelector
  );
  const user = useUserStore(state => state.user);
  const getMeDispatch = useUserStore(state => state.getMe);

  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const navigate = useNavigate();

  const { selectedTab, setSelectedTab, handleOpenPriceModal } = props;

  const handelCreateNew = (e: React.MouseEvent) => {
    e.preventDefault();

    if (user?.permission?.PERFORMANCES) {
      setIsCreateModalOpen(true);
    } else {
      handleOpenPriceModal();
    }
  };

  const handelQuickCreate = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (user?.permission?.PERFORMANCES) {
      setIsLoading(true);
      const entities: [string, Entity][] = new Array(10)
        .fill(undefined)
        .map((_, index) => {
          return [
            uuidv4(),
            {
              name: `Performer ${index + 1}`,
              shape: 'circle',
              color: '#FFC042',
            },
          ];
        });

      const initialFormation: Formation = {
        ...DEFAULT_FORMATION,
        name: 'Formation 1',
        positions: generateInitialPositions(entities),
      };

      const payload: PerformancePayload = {
        name: 'Untitled Performance',
        music: null,
        imageUrl: '/images/SKImageGreen.png',
        entities,
        formations: [initialFormation],
      };

      const performance = await createPerformance(payload);
      setIsLoading(false);
      if (performance) {
        await getMeDispatch(user);

        setTimeout(() => {
          setLoadingPerformanceState(false);
          navigate(`/project/${selectedProject?._id}/${performance._id}`);
        }, 1000);
      }
    } else {
      handleOpenPriceModal();
    }
  };

  return (
    <>
      <Container>
        {/*This will be added later - Axel*/}
        {/*<SearchPerformanceInput placeholder="Jump to performance" />*/}

        <Body>
          <VerticalCenteredRow>
            <Title>{selectedProject.name}</Title>
          </VerticalCenteredRow>

          <VerticalCenteredRow>
            {/*This will be used to play all performances in order - Axel*/}
            {/* <BaseButton
              // onClick={() => navigate(ROUTES_MAP.project)}
              backgroundColor="var(--mainRenoSand)"
            >
              <p>Present</p>
            </BaseButton>*/}
            <AltBase
              onClick={handelQuickCreate}
              backgroundColor="var(--mainRenoSand)"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner fz="28px" h="28px" color="black" />
              ) : (
                <p>Quick Create</p>
              )}
            </AltBase>
            <AltBase
              onClick={handelCreateNew}
              backgroundColor="var(--mainGreen)"
            >
              <p>New</p>
            </AltBase>
            {selectedPerformance && user?.permission?.PUBLIC_SHARING && (
              <BaseButton
                onClick={() => setIsShareModalOpen(true)}
                backgroundColor="#4299FF"
              >
                <p>Share</p>
              </BaseButton>
            )}
          </VerticalCenteredRow>
        </Body>

        <SpacedBetweenRow>
          <Row>
            <Tab
              isSelected={selectedTab === 'table'}
              onClick={() => setSelectedTab('table')}
            >
              <p>Performances</p>
            </Tab>
            <Tab
              isSelected={selectedTab === 'info'}
              onClick={() => setSelectedTab('info')}
            >
              <p>Project Info</p>
            </Tab>
          </Row>

          {/*<VerticalCenteredRow>
            <SelectButton backgroundColor="#CECECE">
              <p>Select</p>
            </SelectButton>
            <Filter>
              <p>Name</p>
              <AiFillCaretDown color="#CECECE" size={14} />
            </Filter>

            <MoreOptions>
              <BsThreeDots color="#FFFFFF" size={28} />
            </MoreOptions>
          </VerticalCenteredRow>*/}
        </SpacedBetweenRow>
      </Container>
      <CreatePerformanceModal
        handleOnClose={() => setIsCreateModalOpen(false)}
        isOpen={isCreateModalOpen}
      />
      <SharePerformanceModal
        handleOnClose={() => setIsShareModalOpen(false)}
        isOpen={isShareModalOpen}
      />
    </>
  );
};

export default ProjectHeader;
