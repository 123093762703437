import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES_MAP } from '~/config/constants';
import { useUserStore } from '~/store/userStore';
import Dashboard from '~/views/Dashboard/Dashboard';
import Onboarding from '~/views/Auth/Onboarding';
import ProjectView from '~/views/Project/ProjectView';
import PreviewView from '../views/Project/PreviewView';
import ThankYou from '../views/ThankYou/ThankYou';
import Logout from '~/views/Auth/Logout';

export default function AuthorizedRoutes() {
  const user = useUserStore(state => state.user);

  return (
    <Routes>
      <Route path={ROUTES_MAP.preview} element={<PreviewView />} />
      <Route path={ROUTES_MAP.preview} element={<PreviewView />} />
      <Route path={ROUTES_MAP.thankyou} element={<ThankYou />} />
      {user?.isOnboarded ? (
        <>
          <Route path={ROUTES_MAP.dashboard} element={<Dashboard />} />
          <Route path={ROUTES_MAP.project} element={<ProjectView />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </>
      ) : (
        <>
          <Route path={ROUTES_MAP.onboarding} element={<Onboarding />} />
          <Route path="*" element={<Onboarding />} />
        </>
      )}
      <Route path={ROUTES_MAP.logout} element={<Logout />} />
    </Routes>
  );
}
