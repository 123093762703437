import { VerticalCenteredRow } from '~/assets/styles/styledBaseComponents';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: var(--altBlack);
  overflow-y: scroll;
`;

export const BaseText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export const BaseGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.2fr 1fr 0.4fr;
`;

export const TableHeader = styled(BaseGrid)`
  padding-top: 16px;
  padding-bottom: 22px;
  padding-left: 40px;
  border-bottom: 0.5px solid #5f5f5f;
  margin-bottom: 0px;

  p {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }
`;

export const PerformanceRow = styled(BaseGrid)<{
  isSelected: boolean;
}>`
  box-sizing: border-box;
  background-color: ${props => (props.isSelected ? '#2F333E' : 'unset')};
  border: ${props =>
    props.isSelected ? '2px solid transparent' : '2px solid transparent'};
  border-radius: ${props => (props.isSelected ? '5px' : 'unset')};
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  margin-bottom: 0px;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => (props.isSelected ? '#2F333E' : '#2F333E')};
    border-radius: 5px;
    border: ${props =>
      props.isSelected ? '2px solid #406DB1' : '2px solid #406DB1'};
  }
`;

export const PerformanceNameContainer = styled(VerticalCenteredRow)`
  cursor: pointer;
  img {
    border-radius: 10px;
    height: 87px;
    width: 115px;
    margin-right: 43px;

    object-fit: cover;
  }
`;

export const StatusCircle = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-radius: calc(10px / 2);
  background-color: ${props => props.color};
`;

export const PerformanceOptions = styled.div`
  cursor: pointer;
`;
