import React, { useEffect, useRef, useState, createContext } from 'react';

type IsVisibleProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
> & { children: (isVisible: boolean) => React.ReactNode };

/**
 * A div wrapper to determine whether the component is visible on the screen.
 *
 * I'm being lazy here. Not going to 100% implement the IntersectionObserver
 * API.
 * @param props The properties for the component.
 */
export function IsVisible(props: IsVisibleProps) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    let observer = new IntersectionObserver(entries => {
      const entry = entries.find(entry => entry.target === ref.current);
      if (!entry) return;
      setIsVisible(entry.isIntersecting);
    });

    observer.observe(ref.current);
  }, []);

  return (
    <div ref={ref} {...props}>
      {props.children(isVisible)}
    </div>
  );
}

// type AutoHidingelementProps = {
//   element?: () => React.ReactNode;
// };

// /**
//  * Wraps an element so that it is only rendered when it is visible on the
//  * screen.
//  * @param props The properties for the component.
//  * @returns A div element wrapping the element that we want to display.
//  */
// function AutoHidingElement({ element }: AutoHidingelementProps) {
//   return (
//     <IsVisible
//       style={{
//         height: 200,
//       }}
//     >
//       <IsVisibleContext.Consumer>
//         {isVisible => (isVisible ? element?.() : null)}
//       </IsVisibleContext.Consumer>
//     </IsVisible>
//   );
// }
