/**
 * Returns a string representing the distance between two dates.
 *
 * In the format of
 *
 * "now", "< 1min", "1h", "1d", "1w", "1m", "1y
 * @param current The current date
 * @param start The start date
 * @returns A string representing the distance between the two dates
 */
export function formatDistance(current: Date, start: Date): string {
  const elapsed = current.getTime() - start.getTime();
  if (elapsed < 1000) {
    return 'now';
  }
  const seconds = Math.round(elapsed / 1000);
  if (seconds < 60) {
    return `< 1min`;
  }
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) {
    return `${minutes}min`;
  }

  const hours = Math.round(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }
  const days = Math.round(hours / 24);
  if (days < 7) {
    return `${days}d`;
  }

  const weeks = Math.round(days / 7);
  if (weeks < 4) {
    return `${weeks}w`;
  }

  const months = Math.round(days / 30);
  if (months < 12) {
    return `${months}m`;
  }

  const years = Math.round(months / 12);
  return `${years}y`;
}

export function formatDuration(
  duration: number,
  showMilliseconds = true
): string {
  // TODO: check if `toLocaleString` uses non-roman script for numbers
  const seconds = ((duration / 1000) % 60).toLocaleString(undefined, {
    minimumFractionDigits: showMilliseconds ? 3 : 0,
    maximumFractionDigits: showMilliseconds ? 3 : 0,
  });
  const minutes = Math.floor(duration / 1000 / 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${!/^\d\d/.test(seconds) ? `0${seconds}` : seconds}`;
}
