import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: var(--altBlack);
  overflow-y: scroll;
  padding: 22px 40px 22px 40px;
`;

export const Content = styled.div`
  max-width: 60%;

  @media (max-width: 1200px) {
    max-width: 80%;
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextGroup = styled.div`
  margin-bottom: 40px;
`;

export const BaseText = styled.p`
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
`;

export const Number = styled.p`
  font-size: 60px;
  font-weight: 600;
  line-height: 60px;
  color: #ffffff;
`;

export const Title = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 20px;
`;

export const Button = styled.button<{
  backgroundColor: string;
  color: string;
  disabled: boolean;
}>`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 11px;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  line-height: 12px;
  text-align: center;
  border: 1px solid ${props => props.backgroundColor};
  cursor: pointer;
  border-radius: 5px;
  padding: 6px 10px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  &:hover {
    opacity: 0.8;
    transform: scale(0.99, 0.99);
    -webkit-transform: scale(0.99, 0.99);
    -moz-transform: scale(0.99, 0.99);
    transition-timing-function: ease;
    transition: transform 0.2s;
  }
`;

export const Textarea = styled.textarea`
  background-color: #313131;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;
