/**
 * According to Our World Data, the average human height in centimetres is
 * 165.25 https://ourworldindata.org/human-height
 */
export const HUMAN_HEIGHT = 1.6525;

/**
 * We're going to go with 20 pixels for the diameter of an entitty. This
 * quantity was totally arbitrary, when it was chosen.
 *
 * Will be useful for doing all sorts of calculations with converting pixels
 * to metric, imperial, etc.
 */
export const ENTITY_DIAMETER_IN_PIXELS = 20;

/**
 * The number of pixels per meter
 */
export const PIXElS_PER_METER = ENTITY_DIAMETER_IN_PIXELS / HUMAN_HEIGHT;

/**
 * The number of pixels per feet
 */
export const PIXELS_PER_FEET = PIXElS_PER_METER * 0.3048;

/**
 * The minimum diameter of an entity in pixels
 */
export const MIN_ENTITY_DIAMETER = PIXELS_PER_FEET * (16 / 12);

/**
 * The maximum diameter of an entity in pixels
 */
export const MAX_ENTITY_DIAMETER = PIXElS_PER_METER * HUMAN_HEIGHT;
