import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

import { uploadAsset } from '~/API/cloudinary';
import { getPerformance } from '~/API/performances';
import { readFile, requestImageFromUser } from '~/config/utils';
import { PERFORMANCE_MUSIC_TYPES } from '~/config/constants';
import {
  selectedPerformanceSelector,
  selectedProjectIdSelector,
  setSelectedPerformanceSelector,
  updatePerformanceSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import LargeButton from '~/components/Buttons/LargeButton';
import InputWithLabelAndBottomBorder from '~/components/Inputs/InputWithLabelAndBottomBorder';
import Spinner from '~/components/Loaders/Preloader';
import {
  BaseButton,
  Column,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import BaseModal, { BaseModalSharedProps } from './BaseModal';
import {
  BlueText,
  Body,
  BodyContent,
  GoldText,
  HeaderConatiner,
  HeaderTitle,
  ImageDropZone,
  MusicDropZone,
  MusicTypeItem,
  MusicTypeList,
  PerformanceContainer,
  PerformanceImagePreview,
  StageDesignPreviewContainer,
  Subtitle,
} from './styles';
import { useUserStore } from '~/store/userStore';
import { AiFillRocket } from 'react-icons/ai';

type FormData = {
  performanceName: string;
  image: File;
  musicType: MusicTypes;
  music: File;
  stageDesign:
    | 'custom'
    | '7PannelMatt'
    | '9PannelMatt'
    | 'basketballCourt'
    | 'footballField';
  width?: number;
  height?: number;
  backgroundImage: File;
};

const UpdatePerformanceModal = (props: BaseModalSharedProps) => {
  const selectedPerfomance = useProjectsStore(selectedPerformanceSelector);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [pickedImage, setPickedImage] = useState<null | string>(null);
  const [pickedBackgroundImage, setPickedBackgroundImage] = useState<
    null | string
  >(null);
  const updatePerformance = useProjectsStore(updatePerformanceSelector);
  const selectedProjectId = useProjectsStore(selectedProjectIdSelector);
  const setSelectedPerformance = useProjectsStore(
    setSelectedPerformanceSelector
  );

  const user = useUserStore(state => state.user);

  const { musicType, music, performanceName } = watch();

  const closeModal = () => {
    reset();
    setPickedImage(null);
    setIsLoading(false);
    props.handleOnClose();
  };

  useEffect(() => {
    register('image');
    register('musicType');
  }, [props.isOpen]);

  useEffect(() => {
    register('music', {
      required:
        musicType === 'custom' && selectedPerfomance?.music?.type !== 'custom',
    });
  }, [props.isOpen, musicType]);

  useEffect(() => {
    reset({
      performanceName: selectedPerfomance?.name as string,
      musicType:
        selectedPerfomance?.music?.type ||
        (PERFORMANCE_MUSIC_TYPES[0].value as MusicTypes),
      width: selectedPerfomance?.stageDesign?.width,
      height: selectedPerfomance?.stageDesign?.height,
    });
  }, [selectedPerfomance]);

  const handleOnDropImage = async (acceptedFiles: File[]) => {
    try {
      const imageDataString = await readFile(acceptedFiles[0]);
      setPickedImage(imageDataString as string);
      setValue('image', acceptedFiles[0]);
    } catch (error) {
      console.log('There was an error picking the image: ', error);
    }
  };

  const handleOnDropMusic = async (acceptedFiles: File[]) => {
    setValue('music', acceptedFiles[0]);
  };

  const { getRootProps: getRootPropsImage, getInputProps: getInputPropsImage } =
    useDropzone({
      onDrop: handleOnDropImage,
      accept: {
        'image/jpeg': [],
        'image/png': [],
      },
      multiple: false,
    });

  const { getRootProps: getRootPropsMusic, getInputProps: getInputPropsMusic } =
    useDropzone({
      onDrop: handleOnDropMusic,
      accept: {
        'audio/*': [],
      },
      multiple: false,
    });

  const handleOnUpdatePerformance = handleSubmit(async data => {
    setIsLoading(true);
    try {
      const performanceData: ProjectPerformance = (
        await getPerformance(
          selectedProjectId as string,
          selectedPerfomance?._id as string
        )
      ).data.data;

      const backgroundImageResponse = await uploadAsset(
        data.backgroundImage,
        'image'
      );

      let payload = {
        ...performanceData,
      };

      payload = {
        ...payload,
        music: {
          type: data.musicType,
          value: {
            name: '',
            url: '',
          },
        },
      };

      if (data.musicType === 'custom') {
        if (data.music) {
          const musicResponse = await uploadAsset(music, 'auto');
          payload = {
            ...payload,
            music: {
              type: data.musicType,
              value: {
                name: data.music.name,
                url: musicResponse?.secure_url as string,
              },
            },
          };
        } else {
          payload = { ...payload, music: { ...performanceData.music } };
        }
      }

      if (data.image) {
        const imageResponse = await uploadAsset(data.image, 'image');
        payload = {
          ...payload,
          imageUrl: imageResponse?.secure_url as string,
        };
      }

      payload = {
        ...payload,
        name: data.performanceName,
        stageDesign:
          data.width && data.height
            ? {
                width: data.width,
                height: data.height,
                unit: 'feet',
                backgroundImage: backgroundImageResponse?.secure_url as string,
              }
            : null,
      };

      await updatePerformance(payload);
      setSelectedPerformance(payload);
      toast.success('Performance was updated');
      closeModal();
    } catch (error) {
      setIsLoading(false);
      console.log('There was an error updating the performance', error);
      toast.error('Error updating the Performance');
    }
  });

  return (
    <BaseModal
      {...props}
      handleOnClose={closeModal}
      customContentContainerStyles={{
        height: '524px',
      }}
    >
      <Fragment>
        <HeaderConatiner style={{ height: '126px' }}>
          <HeaderTitle>UPDATE PERFORMANCE</HeaderTitle>
        </HeaderConatiner>

        <Body
          style={{
            paddingTop: '14px',
          }}
        >
          <BodyContent>
            <PerformanceContainer>
              <div>
                <InputWithLabelAndBottomBorder
                  maxLength={50}
                  label="Performance Name*"
                  error={errors.performanceName}
                  inputProps={{
                    type: 'text',
                    ...register('performanceName', {
                      required: 'The Performance Name is required',
                    }),
                  }}
                />
                {/* <Column
                  style={{
                    marginTop: '25px',
                  }}
                >
                  <Subtitle>Stage Background</Subtitle>
                  <StageDesignPreviewContainer>
                    {pickedBackgroundImage ? (
                      <img
                        src={pickedBackgroundImage}
                        alt="Stage background image"
                      />
                    ) : (
                      <img src="/images/Grid.svg" alt="Canvas preview" />
                    )}
                  </StageDesignPreviewContainer>
                  <BaseButton
                    onClick={() => {
                      // TODO: handle the error properly!
                      requestImageFromUser()
                        .then(async file => {
                          const imageDataAsString = await readFile(file);
                          if (typeof imageDataAsString === 'string') {
                            setPickedBackgroundImage(imageDataAsString);
                          } else {
                            throw new Error('Image data is not a string');
                          }
                          setValue('backgroundImage', file);
                        })
                        .catch(console.error);
                    }}
                    backgroundColor="#229bfa"
                    style={{
                      borderRadius: '3px',
                      color: 'white',
                      fontSize: '9px',
                      textTransform: 'uppercase',
                      marginBottom: 10,
                      marginTop: 14,
                      height: '21px',
                    }}
                  >
                    Upload Background Image
                  </BaseButton>
                </Column> */}
              </div>
              <div>
                <Subtitle>Music Upload</Subtitle>
                <VerticalCenteredRow>
                  <MusicTypeList>
                    {[...PERFORMANCE_MUSIC_TYPES].reverse().map(_musicType => {
                      // Add your condition here to hide a specific music type
                      const shouldHide = _musicType.value === '8CountBeat';

                      if (shouldHide) {
                        return null; // Skip rendering this music type
                      }

                      return (
                        <MusicTypeItem
                          // style={
                          //   _musicType.value === 'custom'
                          //     ? {
                          //         borderColor: '#FFE142',
                          //       }
                          //     : {}
                          // }
                          key={_musicType.value}
                          isSelected={
                            !!musicType && _musicType.value === musicType
                          }
                          onClick={() => {
                            setValue('musicType', _musicType.value);
                          }}
                        >
                          {_musicType.icon}
                          <p
                          // style={
                          //   _musicType.value === 'custom'
                          //     ? {
                          //         color: '#FFE142',
                          //       }
                          //     : {}
                          // }
                          >
                            {_musicType.name}
                          </p>
                          {/* {_musicType.name === 'Upload' ? (
                            <AiFillRocket
                              style={{ paddingLeft: '5px' }}
                            ></AiFillRocket>
                          ) : null} */}
                        </MusicTypeItem>
                      );
                    })}
                  </MusicTypeList>

                  {musicType === 'custom' && (
                    <MusicDropZone
                      error={!!errors.music}
                      style={{
                        borderColor: errors.music ? '#ff4242' : '#FFE142',
                        pointerEvents: user?.permission?.CUSTOM_MUSIC_UPLOAD
                          ? 'auto'
                          : 'none',
                      }}
                    >
                      <div {...getRootPropsMusic()}>
                        <input {...getInputPropsMusic()} />
                        {user?.permission.CUSTOM_MUSIC_UPLOAD ? (
                          <img
                            src="/icons/MusicPaperIcon.svg"
                            alt="Music icon"
                          />
                        ) : (
                          <AiFillRocket
                            color="#FFE142"
                            size={26}
                            style={{ marginBottom: '4px' }}
                          ></AiFillRocket>
                        )}
                        <p
                          style={{
                            color: errors.music ? '#ff4242' : '#FFE142',
                          }}
                        >
                          {!!music ? (
                            <>{music.name}</>
                          ) : (
                            <>
                              {user?.permission.CUSTOM_MUSIC_UPLOAD ? (
                                <>
                                  Drop your music here, or{' '}
                                  <BlueText>click to upload</BlueText>
                                </>
                              ) : (
                                <>
                                  <GoldText>Upgrade</GoldText> your plan to
                                  upload music!
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </MusicDropZone>
                  )}
                </VerticalCenteredRow>
                <Subtitle>Image Upload</Subtitle>
                <VerticalCenteredRow>
                  <PerformanceImagePreview
                    src={
                      pickedImage
                        ? pickedImage
                        : selectedPerfomance?.imageUrl ||
                          '/images/SKImageGreen.png'
                    }
                    alt="Performance's Image"
                  />

                  <ImageDropZone error={false}>
                    <div {...getRootPropsImage()}>
                      <input {...getInputPropsImage()} />
                      <img
                        src="/images/MiniLandscape.png"
                        alt="Mini landscape"
                      />
                      <p
                        style={{
                          color: 'white',
                        }}
                      >
                        Drop your image here, or{' '}
                        <BlueText>click to upload</BlueText>
                      </p>
                    </div>
                  </ImageDropZone>
                </VerticalCenteredRow>
              </div>
            </PerformanceContainer>
          </BodyContent>

          {!isLoading && (
            <LargeButton
              backgroundColor="#AAF17F"
              handleOnClick={handleOnUpdatePerformance}
              text="UPDATE PERFORMANCE"
            />
          )}
          {isLoading && <Spinner fz="4rem" h="100%" color="white" />}
        </Body>
      </Fragment>
    </BaseModal>
  );
};

export default UpdatePerformanceModal;
