import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { HiOutlineMail } from 'react-icons/hi';
import {
  BsPencilSquare,
  BsPerson,
  BsThreeDots,
  BsTrashFill,
} from 'react-icons/bs';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import useSwr from 'swr';
import { BACKEND_ROOT } from '../../../../../config/constants';
import { editComment, upsertGuestUser } from '../../../../../API/comments';
import useInterval from '../../../../../hooks/use-interval';
import { TbBoxPadding } from 'react-icons/tb';
import { ContextMenu } from '~/assets/styles/styledBaseComponents';
import { MenuItem } from '@szhsin/react-menu';
import { PerformanceOptions } from '~/views/Dashboard/auxiliaries/PerformancesTable/styles';
import { id } from 'date-fns/locale';
import { IUser } from '~/config/interfaces';
import { GuestAuthor, UserAuthor, authorSchema, useChatContext } from './chat';
import { useUserStore } from '~/store/userStore';
import { ThemeContext } from '../../contexts/theme';
// import { formatDistance } from 'date-fns';
import { Button } from '../Button';
import { css } from '@emotion/css';
import { TimelineState, time } from '../timeline-state';
import ReactPortal from '~/components/ReactPortal';
import { formatDistance, formatDuration } from '~/utils/format';

type MiniModalProps = {
  children?: React.ReactNode | null | undefined;
};
function MiniModal({ children }: MiniModalProps) {
  return (
    <ReactPortal wrapperId="react-portal-dashboard-base-modal">
      <div
        className={css`
          width: 100pw;
          height: 100vh;

          position: fixed;
          inset: 0;
          z-index: 999;

          transition: all 0.3s ease-in-out;
          overflow: hidden;

          display: flex;
          justify-content: center;
          align-items: center;

          background-color: rgba(0, 0, 0, 0.58);

          color: white;
        `}
      >
        {children}
      </div>
    </ReactPortal>
  );
}

function GuestPromptModal({
  onCancel,
  onGuestUserId,
}: {
  onCancel: () => void;
  onGuestUserId: (userId: string) => void;
}) {
  const [emailAddress, setEmailAddress] = useState('');
  const [name, setName] = useState('');

  return (
    <MiniModal>
      <div
        style={{
          width: 400,
          background: 'rgb(38, 38, 38)',
          color: 'white',
          borderRadius: 10,
        }}
      >
        <div
          style={{
            padding: 13,
          }}
        >
          <h3
            style={{
              marginBottom: 13,
            }}
          >
            Want to leave a comment?
          </h3>
          <p
            style={{
              fontSize: '0.75em',
              color: 'rgba(255, 255, 255, 0.7)',
              marginBottom: 13,
            }}
          >
            Add your info here, so people know who left the comment
          </p>
          <div style={{ position: 'relative', marginBottom: 13 }}>
            <div
              style={{
                position: 'absolute',
                left: 10,
                top: 5,
                paddingRight: 10,
                borderRight: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <HiOutlineMail />
            </div>
            <input
              style={{
                width: '100%',
                background: 'rgb(55, 55, 55)',
                border: 'none',
                borderRadius: 5,
                color: 'white',
                padding: '5px 10px 5px 55px',
              }}
              type="email"
              value={emailAddress}
              onChange={e => {
                setEmailAddress(e.target.value);
              }}
            />
          </div>

          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                left: 10,
                top: 5,
                paddingRight: 10,
                borderRight: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <BsPerson />
            </div>
            <input
              style={{
                width: '100%',
                background: 'rgb(55, 55, 55)',
                border: 'none',
                borderRadius: 5,
                color: 'white',
                padding: '5px 10px 5px 55px',
              }}
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            padding: 13,
            marginTop: 13,
            textAlign: 'right',
            background: 'rgb(27, 27, 27)',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <Button
            style={{
              background: 'none',
              color: 'white',
              fontWeight: 'normal',
              cursor: 'pointer',
            }}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={e => {
              const expression: RegExp =
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
              if (expression.test(emailAddress)) {
                upsertGuestUser({ emailAddress, name }).then(response => {
                  onGuestUserId(response.data.data._id);
                });
              } else {
                toast.error('Please enter a valid email address.');
              }
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </MiniModal>
  );
}

const idToColor = (str: string) => {
  if (str === '0') return '0deg';
  let hash = 0;
  str.split('').forEach(char => {
    hash = (char.charCodeAt(0) + ((hash << 5) - hash)) % 360;
  });
  return `${hash}deg`;
};

const SimpleForm = ({
  onSubmit,
  value,
  onChange,
  userId,
}: {
  onSubmit: (commentBody: string) => void;
  onChange: (value: string) => void;
  value: string;
  userId: string | null;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const user = useUserStore(state => state.user);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  return (
    <div
      style={{ display: 'flex', padding: 10, background: 'rgb(22, 22, 22)' }}
    >
      <div
        style={{
          marginRight: 13,
        }}
      >
        <img
          src={user?.photoURL || '/images/avatar.svg'}
          style={{
            width: 24,
            filter:
              userId !== null &&
              (user?.photoURL === null || user?.photoURL === undefined)
                ? `brightness(100%) saturate(1000%) hue-rotate(${idToColor(
                    userId
                  )})`
                : 'none',
            borderRadius: '12px',
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <textarea
          ref={textareaRef}
          placeholder="Write a comment here…"
          style={{
            width: '100%',
            marginBottom: 13,
            background: 'rgb(38, 38, 38)',
            borderRadius: 5,
            color: 'white',
            border: 'none',
            padding: '5px 10px',
            minHeight: '50px',
          }}
          value={value}
          onChange={e => {
            onChange(e.target.value);
            adjustTextareaHeight();
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey && !!value.trim()) {
              e.preventDefault();
              e.stopPropagation();
              onSubmit(value);
            }
          }}
        />
        <Button
          disabled={!value.trim()}
          style={{
            width: '100%',
          }}
          onClick={() => {
            onSubmit(value);
          }}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

type PerformanceComment = {
  time: number;
  id: string;
  author: any;
  commentBody: string;
  createdAt: string;

  // NOTE: eventually this will become a lot richer
  reactions: string[];
};

export const AuthorInfo = ({
  profilePic,
  name,
  userID,
}: {
  profilePic: string;
  name: string;
  userID: string;
}) => {
  const user = useUserStore(state => state.user);
  return (
    <>
      <div>
        <img
          alt={`${name}'s Profile Picture`}
          src={
            userID === 'user_' + user?.uid && user?.photoURL
              ? user?.photoURL
              : '/images/avatar.svg'
          }
          style={{
            width: 24,
            filter:
              userID !== null &&
              (!user ||
                (user?.photoURL !== undefined &&
                  user.uid !== userID.split('_')[1]))
                ? `brightness(100%) saturate(1000%) hue-rotate(${idToColor(
                    userID
                  )})`
                : 'none',
            borderRadius: '12px',
          }}
        />
      </div>
      <div
        style={{
          color: '#fff',
          fontSize: '0.9em',
        }}
      >
        {name}
      </div>
    </>
  );
};

export const GuestAuthorLoader = ({ user }: { user: GuestAuthor }) => {
  const { data, error, isLoading } = useSwr(
    `${BACKEND_ROOT}/guest-users/${user.id}`,
    (u: string) => fetch(u).then(r => r.json())
  );

  if (error) {
    // TODO; find a better way to handle this error.
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  if (isLoading) {
    return <div>Loading…</div>;
  }

  if (!data) {
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  if (!data.name) {
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  return (
    <AuthorInfo
      profilePic="/images/avatar.svg"
      name={data.name || 'Unknown'}
      userID={'guest_' + user.id}
    />
  );
};

export const UserAuthorLoader = ({ user }: { user: UserAuthor }) => {
  const { data, error, isLoading } = useSwr(
    `${BACKEND_ROOT}/public-users/${user.id}`,
    (u: string) => fetch(u).then(r => r.json())
  );

  if (error) {
    // TODO; find a better way to handle this error.
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  if (isLoading) {
    return <div>Loading…</div>;
  }

  if (!data) {
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  const firstName = data.firstName as unknown;
  const lastName = data.lastName as unknown;

  const name = [firstName, lastName]
    .filter(n => typeof n === 'string' && !!n)
    .join(' ')
    .trim();

  if (!name) {
    return (
      <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
    );
  }

  return (
    <AuthorInfo
      profilePic="/images/avatar.svg"
      name={name}
      userID={'user_' + user.id}
    />
  );
};

export const AuthorLoader = ({ author }: { author: unknown }): JSX.Element => {
  const validation = authorSchema.validate(author);
  if (validation.isValid) {
    switch (validation.value.type) {
      case 'USER_AUTHOR':
        return <UserAuthorLoader user={validation.value} />;
      case 'GUEST_AUTHOR':
        return <GuestAuthorLoader user={validation.value} />;
    }
  }

  return (
    <AuthorInfo profilePic="/images/avatar.svg" name="Unknown" userID={''} />
  );
};

function FormatDistance({ date }: { date: Date }) {
  const [format, setFormat] = useState<string>(
    formatDistance(new Date(), date)
  );
  const intervalFormat = useCallback(() => {
    setFormat(formatDistance(new Date(), date));
  }, [date]);
  useInterval(intervalFormat, 1000);
  return <>{format}</>;
}

type CommentCommand =
  | {
      type: 'CREATE_COMMENT';
      body: string;
    }
  | {
      type: 'ADD_REACTION';
      commentId: string;
    }
  | {
      type: 'REMOVE_REACTION';
      commentId: string;
    }
  | {
      type: 'EDIT_COMMENT';
      commentId: string;
      body: string;
    }
  | {
      type: 'DELETE_COMMENT';
      commentId: string;
    };

function useCommentState(initialComment: string) {
  const [editState, setEditState] = useState(false);
  const [editedComment, setEditedComment] = useState(initialComment);

  return {
    editState,
    setEditState,
    editedComment,
    setEditedComment,
  };
}

export const CommentItem = ({
  comment,
  onEditComment,
  onDeleteComment,
  onLikeComment,
  onDislikeComment,
  user,
  guestUserId,
  isPreview,
  timelineSeeked,
}: {
  comment: PerformanceComment;
  onEditComment: (commentId: string, commentBody: string) => void;
  onDeleteComment: (commentId: string) => void;
  onLikeComment: (commentId: string) => void;
  onDislikeComment: (commentId: string) => void;
  user: IUser | null;
  guestUserId: string | null;
  isPreview: boolean;
  timelineSeeked: (time: number) => void;
}) => {
  const commentState = useCommentState(comment.commentBody);
  const [focusedComment, setFocusedComment] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null); // Create a ref for the textarea element

  useEffect(() => {
    // Set focus on the textarea when the edit state changes and it's in edit mode
    if (commentState.editState && focusedComment) {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(
          textarea.value.length,
          textarea.value.length
        );
      }
    }
  }, [commentState.editState, focusedComment]);

  const handleTextareaKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      commentState.setEditState(false);
      onEditComment(comment.id, commentState.editedComment);
    }
  };

  const handleEditClick = () => {
    commentState.setEditState(true);
    setFocusedComment(true);
  };

  const handleTextareaBlur = () => {
    commentState.setEditState(false);
    setFocusedComment(false);
  };

  const isLiker = comment.reactions.some((reaction: string) => {
    if (!user && !guestUserId) {
      return false;
    } else if (user) {
      console.log(reaction, `USER_AUTHOR${user.uid}`);
      return reaction === `USER_AUTHOR${user.uid}`;
    }

    return reaction === `GUEST_AUTHOR${guestUserId}`;
  });

  return (
    <div
      key={comment.id}
      className={css`
        padding: 10px;
        border-bottom: 2px solid black;
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          > * {
            margin-right: 10px;
          }
        `}
      >
        <AuthorLoader author={comment.author} />
        <div
          style={{
            marginTop: '.2em',
            fontSize: '0.8em',
          }}
        >
          <FormatDistance date={new Date(comment.createdAt)} />
        </div>
        <div
          style={{
            color: '#5B84C1',
            fontSize: '0.9em',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={() => timelineSeeked(comment.time || 0)}
        >
          {formatDuration(comment.time, false)}
        </div>
      </div>

      <div>
        {commentState.editState !== true ? (
          // Non-editable version
          comment.commentBody
            .split('\n\n')
            .map((paragraph: string, i: number, arr: string[]) => (
              <p
                key={i}
                style={{
                  marginBottom: i !== arr.length - 1 ? '16px' : '0',
                  lineHeight: '16px',
                }}
              >
                {paragraph
                  .trim()
                  .split('\n')
                  .map((line: string, j: number, arr: string[]) => (
                    <React.Fragment key={j}>
                      {line}
                      {j !== arr.length - 1 && <br />}
                    </React.Fragment>
                  ))}
              </p>
            ))
        ) : (
          // Editable version (Blank textarea)
          <textarea
            ref={textareaRef} // Assign the ref to the textarea
            onBlur={handleTextareaBlur}
            onKeyPress={handleTextareaKeyPress}
            value={commentState.editedComment}
            onChange={e => commentState.setEditedComment(e.target.value)}
            style={{
              width: '100%',
              marginBottom: 13,
              background: 'rgb(38, 38, 38)',
              borderRadius: 5,
              color: 'white',
              border: 'none',
              padding: '5px 10px',
              minHeight: '50px',
            }}
          />
        )}
      </div>

      <div
        style={{
          marginTop: 20,
          display: 'flex',
        }}
      >
        {isLiker ? (
          <AiFillHeart
            onClick={() => {
              onDislikeComment(comment.id);
            }}
            style={{
              color: 'rgb(180, 255, 135)',
              cursor: 'pointer',
            }}
          />
        ) : (
          <AiOutlineHeart
            onClick={() => {
              onLikeComment(comment.id);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
        )}{' '}
        <div
          style={{
            display: 'inline-block',
            marginTop: 0,
            marginLeft: 5,
          }}
        >
          {comment.reactions.length}
        </div>
        <div style={{ marginLeft: 'auto', marginRight: '.8em' }}>
          {(!isPreview || comment.author.id === guestUserId) && (
            <ContextMenu
              portal
              align="start"
              position="auto"
              menuButton={
                <PerformanceOptions>
                  <BsThreeDots />
                </PerformanceOptions>
              }
              transition
            >
              {user?.uid === comment.author.id && (
                <MenuItem onClick={() => handleEditClick()}>
                  <BsPencilSquare color="#FFFFFF" size={15} />
                  <p> Edit </p>
                </MenuItem>
              )}
              <MenuItem
                style={{
                  color: '#EE3636',
                }}
                onClick={() => onDeleteComment(comment.id)}
              >
                <BsTrashFill color="#EE3636" size={15} />
                <p> Delete </p>
              </MenuItem>
            </ContextMenu>
          )}
        </div>
      </div>
    </div>
  );
};

export const ChatTab = ({
  isMobile,
  isPreview,
  performance,
  timelineState,
  timelineSeeked,
}: {
  isMobile: boolean;
  isPreview: boolean;
  performance: Performance;
  timelineState: TimelineState;
  timelineSeeked: (time: number) => void;
}) => {
  const {
    comments,
    createComment,
    addReaction,
    removeReaction,
    editComment,
    deleteComment,
  } = useChatContext();
  const selectedTime = time(performance, timelineState);
  const { theme } = useContext(ThemeContext);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [guestUserId, setGuestUserId] = useState<null | string>(null);
  const user = useUserStore(state => state.user);
  const pendingCommandsRef = useRef<CommentCommand[]>([]);
  const [value, setValue] = useState<string>('');

  const onCommentSubmit = () => {
    if (!user && !guestUserId) {
      pendingCommandsRef.current.push({ type: 'CREATE_COMMENT', body: value });
      setShouldShowModal(true);
    } else if (user) {
      createComment(value, selectedTime, { type: 'USER_AUTHOR', id: user.uid });
    } else {
      createComment(value, selectedTime, {
        type: 'GUEST_AUTHOR',
        id: guestUserId!,
      });
    }
    setValue('');
  };

  const onLikeComment = (commentId: string) => {
    if (!user && !guestUserId) {
      pendingCommandsRef.current.push({ type: 'ADD_REACTION', commentId });
      setShouldShowModal(true);
    } else if (user) {
      addReaction(commentId, { type: 'USER_AUTHOR', id: user.uid });
    } else {
      addReaction(commentId, { type: 'GUEST_AUTHOR', id: guestUserId! });
    }
  };

  const onDislikeComment = (commentId: string) => {
    if (!user && !guestUserId) {
      pendingCommandsRef.current.push({ type: 'REMOVE_REACTION', commentId });
      setShouldShowModal(true);
    } else if (user) {
      removeReaction(commentId, { type: 'USER_AUTHOR', id: user.uid });
    } else {
      removeReaction(commentId, { type: 'GUEST_AUTHOR', id: guestUserId! });
    }
  };

  const onEditComment = (commentId: string, commentBody: string) => {
    if (!user && !guestUserId) {
      pendingCommandsRef.current.push({
        type: 'EDIT_COMMENT',
        commentId,
        body: commentBody,
      });
      setShouldShowModal(true);
    } else if (user) {
      editComment(commentId, commentBody, {
        type: 'USER_AUTHOR',
        id: user.uid,
      });
    } else {
      editComment(commentId, commentBody, {
        type: 'GUEST_AUTHOR',
        id: guestUserId!,
      });
    }
  };

  const onDeleteComment = (commentId: string) => {
    if (!user && !guestUserId) {
      pendingCommandsRef.current.push({
        type: 'DELETE_COMMENT',
        commentId,
      });
      setShouldShowModal(true);
    } else if (user) {
      deleteComment(commentId, { type: 'USER_AUTHOR', id: user.uid });
    } else {
      deleteComment(commentId, { type: 'GUEST_AUTHOR', id: guestUserId! });
    }
  };

  const clearPendingCommands = (id: string) => {
    for (const command of pendingCommandsRef.current) {
      const type = command.type;
      switch (type) {
        case 'CREATE_COMMENT':
          createComment(command.body, selectedTime, {
            type: 'GUEST_AUTHOR',
            id,
          });
          break;
        case 'ADD_REACTION':
          addReaction(command.commentId, { type: 'GUEST_AUTHOR', id });
          break;
        case 'REMOVE_REACTION':
          removeReaction(command.commentId, { type: 'GUEST_AUTHOR', id });
          break;
        default:
          let _exhaustiveCheck: never = type;
      }
    }
    pendingCommandsRef.current = [];
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(15, 15, 15)',
        fontWeight: 'bold',
        fontSize: '0.75em',
        color: 'rgba(188, 188, 188)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
    >
      {!isMobile && (
        <div
          style={{
            backgroundColor: theme.sidebar.tabs.background,
            padding: 13,
            borderBottom: '2px solid black',
          }}
        >
          <h2
            style={{
              textTransform: 'uppercase',
              marginBottom: 20,
            }}
          >
            Comments
          </h2>
        </div>
      )}

      <div style={{ flex: 1, overflowY: 'scroll' }}>
        {isMobile ? (
          <div
            style={{
              backgroundColor: '#262626',
            }}
          >
            {comments.map(comment => (
              <CommentItem
                timelineSeeked={timelineSeeked}
                isPreview={isPreview}
                key={comment.id}
                comment={comment}
                onEditComment={onEditComment}
                onDeleteComment={onDeleteComment}
                onLikeComment={onLikeComment}
                onDislikeComment={onDislikeComment}
                user={user}
                guestUserId={guestUserId}
              />
            ))}
          </div>
        ) : (
          comments.map(comment => (
            <CommentItem
              timelineSeeked={timelineSeeked}
              isPreview={isPreview}
              key={comment.id}
              comment={comment}
              onEditComment={onEditComment}
              onDeleteComment={onDeleteComment}
              onLikeComment={onLikeComment}
              onDislikeComment={onDislikeComment}
              user={user}
              guestUserId={guestUserId}
            />
          ))
        )}
      </div>

      <SimpleForm
        onChange={setValue}
        onSubmit={() => {
          onCommentSubmit();
        }}
        value={value}
        userId={
          user
            ? 'user_' + user.uid
            : guestUserId
            ? 'guest_' + guestUserId
            : null
        }
      />

      {shouldShowModal ? (
        <GuestPromptModal
          onCancel={() => {
            setShouldShowModal(false);
          }}
          onGuestUserId={id => {
            setGuestUserId(id);
            setShouldShowModal(false);
            clearPendingCommands(id);
          }}
        />
      ) : null}
    </div>
  );
};
