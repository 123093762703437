export interface ReadOnlyMapNoGetter<K, V> {
  has(key: K): boolean;
  entries(): IterableIterator<[K, V]>;
  forEach(cb: (value: V, key: K, map: ReadOnlyMapNoGetter<K, V>) => void): void;
  keys(): IterableIterator<K>;
  values(): IterableIterator<V>;
  readonly size: number;
}

export interface ReadOnlyMap<K, V> extends ReadOnlyMapNoGetter<K, V> {
  get(key: K): V | undefined;
  [Symbol.iterator](): IterableIterator<[K, V]>;
}

export interface ReadOnlySet<V> extends ReadOnlyMapNoGetter<V, V> {
  [Symbol.iterator](): IterableIterator<V>;
}

export function createReadOnlySet<V>(set: ReadOnlySet<V>): ReadOnlySet<V> {
  return {
    has: set.has.bind(set),
    entries: set.entries.bind(set),
    forEach: set.forEach.bind(set),
    keys: set.keys.bind(set),
    values: set.values.bind(set),
    [Symbol.iterator]: set[Symbol.iterator].bind(set),
    get size() {
      return set.size;
    },
  };
}
