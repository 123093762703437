import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FullPageLoader from '~/components/Loaders/FullPageLoader';
import { LSTORAGE_USER_KEY } from '~/config/constants';
import firebaseService from '~/services/firebase';

const Logout = () => {
  const logUserOut = async () => {
    await firebaseService.auth.signOut();
  };
  const navigate = useNavigate();

  useEffect(() => {
    logUserOut();
    localStorage.removeItem(LSTORAGE_USER_KEY);
    navigate('/');
  });
  return <FullPageLoader />;
};

export default Logout;
