import { PERFORMANCES_ENDPOINTS } from '~/config/constants';
import { SGApi } from './axios';

export function createPerformance(
  projectId: string,
  payload: PerformancePayload
) {
  const _endpoint = PERFORMANCES_ENDPOINTS.createPerformance(projectId);
  return SGApi.post(_endpoint, payload);
}

export function getPerformance(projectId: string, performanceId: string) {
  const _endpoint = PERFORMANCES_ENDPOINTS.performance(
    projectId,
    performanceId
  );
  return SGApi.get(_endpoint);
}

export function deletePerformance(projectId: string, performanceId: string) {
  const _endpoint = PERFORMANCES_ENDPOINTS.performance(
    projectId,
    performanceId
  );
  return SGApi.delete(_endpoint);
}

export function updatePerformance(
  projectId: string,
  performanceId: string,
  payload: Partial<ProjectPerformance>
) {
  const _endpoint = PERFORMANCES_ENDPOINTS.performance(
    projectId,
    performanceId
  );
  return SGApi.put(_endpoint, payload);
}

export function getPublicPerformance(projectId: string, performanceId: string) {
  const _endpoint = PERFORMANCES_ENDPOINTS.publicPerformance(
    projectId,
    performanceId
  );

  return SGApi.get(_endpoint);
}
