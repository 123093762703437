import { useFetchArrayBuffer } from '../../../hooks/use-fetch-array-buffer';
import { useDecodeAudioBuffer } from './use-get-visualization-data/use-decode-audio-buffer';
import { useGetAveragePCM } from './use-get-visualization-data/use-get-average-pcm/use-get-average-pcm';

export function useFetchAudio(audioSource: string | null) {
  const fetchState = useFetchArrayBuffer(audioSource);
  const audioBuffer = useDecodeAudioBuffer(
    fetchState.type === 'LOADED' ? fetchState.data : null
  );
  const pcm = useGetAveragePCM(audioBuffer);

  return { audioBuffer, pcm };
}
