import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import LargeButton from '~/components/Buttons/LargeButton';
import InputWithLabelAndBottomBorder from '~/components/Inputs/InputWithLabelAndBottomBorder';
import { createProjectSelector, useProjectsStore } from '~/store/projectsStore';
import BaseModal, { BaseModalSharedProps } from './BaseModal';
import { Body, HeaderConatiner, HeaderTitle } from './styles';

type FormData = {
  projectTitle: string;
};

const CreateProjectModal = (props: BaseModalSharedProps) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const createProject = useProjectsStore(createProjectSelector);

  const handleOnCreateProject = handleSubmit(async data => {
    try {
      await createProject(data.projectTitle);
      toast.success('Project was created');
      reset();
      props.handleOnClose();
    } catch (error) {
      toast.error('Error creating the project');
    }
  });

  return (
    <BaseModal {...props}>
      <Fragment>
        <HeaderConatiner>
          <HeaderTitle>New Project</HeaderTitle>
        </HeaderConatiner>

        <Body
          style={{
            padding: '56px 47px 29px 52px',
          }}
        >
          <form onSubmit={handleOnCreateProject}>
            <InputWithLabelAndBottomBorder
              label="Project Name"
              customStyle={{
                marginBottom: '63px',
              }}
              error={errors.projectTitle}
              inputProps={{
                type: 'text',
                ...register('projectTitle', {
                  required: 'The Project Name is required',
                }),
              }}
            />

            <LargeButton
              type="submit"
              handleOnClick={handleOnCreateProject}
              text="CREATE PROJECT"
            />
          </form>
        </Body>
      </Fragment>
    </BaseModal>
  );
};

export default CreateProjectModal;
