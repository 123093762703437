import React from 'react';
import { Img, Logo } from '../style';

export const SKLogo = () => (
  <Logo>
    <Img src="/images/SKIconBlack.svg" alt="Stagekeep" />

    <p>STAGEKEEP</p>
  </Logo>
);
