import { useEffect, useRef, useState } from 'react';

interface DragState {
  isDragging: boolean;
  dragStartX: number;
  offsetX: number;
}

interface DragHandlers {
  onMouseDown: React.MouseEventHandler;
  onMouseUp: React.MouseEventHandler;
  onTouchStart?: React.TouchEventHandler;
  onTouchEnd?: React.TouchEventHandler;
}

const useDrag = (): [
  DragHandlers,
  DragState,
  React.Dispatch<React.SetStateAction<DragState>>,
  React.RefObject<HTMLDivElement>
] => {
  const [dragState, setDragState] = useState<DragState>({
    isDragging: false,
    dragStartX: 0,
    offsetX: 0,
  });

  const dragRef = useRef<HTMLDivElement>(null);

  const handleDragStart = (event: React.MouseEvent | React.TouchEvent): void => {
    event.preventDefault();

    let clientX: number;

    if ('clientX' in event) {
      // Mouse Event
      clientX = event.clientX;
    } else if (event.touches && event.touches.length > 0) {
      // Touch Event
      clientX = event.touches[0].clientX;
    } else {
      // Unsupported event type
      return;
    };

    setDragState(prevState => ({
      ...prevState,
      isDragging: true,
      dragStartX: clientX,
    }));
  };

  const handleDragMove = (clientX: number): void => {
    if (!dragState.isDragging) {
      return;
    }

    setDragState(prevState => ({
      ...prevState,
      offsetX: clientX - dragState.dragStartX + dragState.offsetX,
    }));
  };

  const handleDragEnd = (): void => {
    setDragState(prevState => ({
      ...prevState,
      isDragging: false,
    }));
  };

  useEffect(() => {
    const onMouseMove = (event: MouseEvent): void => {
      event.preventDefault();
      handleDragMove(event.clientX);
    };

    const onTouchMove = (event: TouchEvent): void => {
      //stoppping intervention errors
      // event.preventDefault();
      handleDragMove(event.touches[0].clientX);
    };

    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchend', handleDragEnd);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchend', handleDragEnd);
      document.removeEventListener('touchmove', onTouchMove);
    };
  }, [dragState.isDragging]);

  const dragHandlers: DragHandlers = {
    onMouseDown: handleDragStart,
    onMouseUp: handleDragEnd,
    onTouchStart: handleDragStart,
    onTouchEnd: handleDragEnd,
  };

  return [dragHandlers, dragState, setDragState, dragRef];
};

export default useDrag;
