import React, { useEffect, useRef } from 'react';

function isRealNumber(number: unknown): boolean {
  if (typeof number !== 'number') {
    return false;
  }
  if (isNaN(number)) {
    return false;
  }
  if (number === Infinity || number === -Infinity) {
    return false;
  }
  return true;
}

function inferRealNumber(number: unknown): number {
  if (typeof number === 'number') {
    return isRealNumber(number) ? number : 0;
  }
  if (typeof number === 'string') {
    const f = parseFloat(number);
    return isRealNumber(f) ? f : 0;
  }
  return 0;
}

type VideoProps = {
  isPlaying: boolean;
  startTime: number;
  startTimeChanged: (value: number) => void;
  src: string;
  muted: boolean;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

export function Video({
  startTime,
  isPlaying,
  startTimeChanged,
  ...videoProps
}: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const offsetNumber = inferRealNumber(startTime);

  useEffect(() => {
    let interval: number;
    if (videoRef.current && isPlaying) {
      interval = requestAnimationFrame(() => {
        if (!videoRef.current) return;
        startTimeChanged(videoRef.current.currentTime);
      });
    }

    return () => {
      if (typeof interval === 'number') {
        cancelAnimationFrame(interval);
      }
    };
  }, [startTime, isPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        if (videoRef.current.paused || videoRef.current.ended) {
          videoRef.current.currentTime = offsetNumber;
          videoRef.current.play();
        }
      } else {
        videoRef.current.currentTime = offsetNumber;
        videoRef.current.pause();
      }
    }
  }, [startTime, isPlaying]);

  return <video ref={videoRef} {...videoProps}></video>;
}
