import styled from 'styled-components';

import { VerticalCenteredRow } from '~/assets/styles/styledBaseComponents';

export const Container = styled.div`
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 242px;
  max-width: 242px;
  min-width: 242px;
`;

export const Logo = styled.img`
  width: 22px;
  height: 22px;
  margin-left: 13px;
  margin-top: 18px;
  margin-bottom: 150px;
  object-fit: contain;
`;

export const UserNameContainer = styled(VerticalCenteredRow)`
  cursor: pointer;
  margin-bottom: 18px;
  padding-left: 19px;
  transition: all 0.5s ease;
  &:hover {
    opacity: 0.8;
    }

  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
    color: #627552;
    margin-right: 16px;
    margin-left: 9.82px;
  }

  svg {
    &:last-child {
      cursor: pointer;
    }
  }
`;

export const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-left: 9px;
  padding-right: 8px;
`;

export const ProjectItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? '#2F333E' : 'unset')};
  border: ${props =>
    props.isSelected ? '2px solid transparent' : '2px solid transparent'};
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 0px;
  padding: 14px 13px 12px 12px;
  transition: all 0.2s ease;
  &:hover {
    background: ${props => (props.isSelected ? '#2F333E' : '#2F333E')};
    border-radius: 5px;
    border: 2px solid #406DB1;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-left: 9px;
  }

  svg {
    :first-child {
      margin-right: 8.82px;
    }
  }
`;

export const SidebarFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  border-top: 0.5px solid #5f5f5f;
  padding-left: 21px;
  padding-top: 16px;
  padding-bottom: 10px;
`;

export const SidebarOption = styled.div<{
  color?: string;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
    color: ${(props) => (props.color ? props.color : '#ffffff')} !important;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const SidebarOptionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin-right: 15px;

  img {
    width: 26px;
    height: 26px;
    border-radius: calc(26px / 2);
    object-fit: contain;
  }

  svg {
  }
`;
