import { SGApi, getArray } from './axios';

/**
 * Sends a "create comment" event to the server
 * @param params An object containing two string fields: projectId and
 *   performanceId
 * @param data The data, containing the author and a comment body
 * @returns The response from the POST request
 */
export const createComment = (
  { projectId, performanceId }: { projectId: string; performanceId: string },
  data: {
    commentBody: string;
    time: number;
    author: unknown;
  }
) => {
  return SGApi.post(`/comments/${projectId}/${performanceId}/create`, data);
};

export const eventsPath = ({
  projectId,
  performanceId,
}: {
  projectId: string;
  performanceId: string;
}) => `/comments/${projectId}/comments-events/${performanceId}`;

/**
 * Get the list of all comment events given a performance
 * @param params An object containing two string fields: projectId and
 *  performanceId
 * @returns An array of events
 */
export const getEvents = ({
  projectId,
  performanceId,
}: {
  projectId: string;
  performanceId: string;
}) => {
  return getArray(eventsPath({ projectId, performanceId }));
};

export const upsertGuestUser = ({
  emailAddress,
  name,
}: {
  emailAddress: string;
  name: string;
}) => {
  return SGApi.post('/guest-users', { email: emailAddress, name });
};

export const getGuestUser = (id: string) => {
  return SGApi.get(`/guest-users/${id}`);
};

export const addReaction = (
  { projectId, performanceId }: { projectId: string; performanceId: string },
  data: {
    commentId: string;
    author: unknown;
  }
) => {
  return SGApi.post(
    `/comments/${projectId}/${performanceId}/add-reaction`,
    data
  );
};

export const removeReaction = (
  { projectId, performanceId }: { projectId: string; performanceId: string },
  data: {
    commentId: string;
    author: unknown;
  }
) => {
  return SGApi.post(
    `/comments/${projectId}/${performanceId}/remove-reaction`,
    data
  );
};

export const editComment = (
  { projectId, performanceId }: { projectId: string; performanceId: string },
  data: {
    commentId: string;
    commentBody: string;
    author: unknown;
  }
) => {
  return SGApi.post(`/comments/${projectId}/${performanceId}/edit`, data);
}

export const deleteComment = (
  { projectId, performanceId }: { projectId: string; performanceId: string },
  data: {
    commentId: string;
    author: unknown;
  }
) => {
  return SGApi.post(`/comments/${projectId}/${performanceId}/delete`, data);
}
