import { useEffect, useState } from 'react';
import { jobSender } from './get-pcm-service';

/**
 * Given a multi-channel audio buffer, get an array of PCM data, containing
 * the average of the those channels.
 * @param audioBuffer An audio buffer containing raw multi-channel audio data.
 * @returns Null if AudioBuffer is null or if it hasn't been averaged yet;
 *   otherwise returns an array of PCM data.
 */
export function useGetAveragePCM(audioBuffer: AudioBuffer | null) {
  const [audioPcm, setAudioPcm] = useState<Float32Array | null>(null);

  useEffect(() => {
    let worker: Worker | null = null;
    if (audioBuffer && !audioPcm) {
      const channels = Array.from(
        { length: audioBuffer.numberOfChannels },
        (_, i) => audioBuffer.getChannelData(i)
      );
      jobSender.sendJob([channels, channels.map(c => c.buffer)]).then(data => {
        if (data) {
          setAudioPcm(data[0]);
        }
      });
    }
    return () => {
      worker?.terminate();
    };
  }, [audioBuffer, audioPcm]);

  return audioPcm;
}
