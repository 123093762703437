import {
  BaseButton,
  Row,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 17px;
  padding-right: 13px;
  padding-left: 23px;

  background-color: var(--altBlack);

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  z-index: 9;
`;

export const SearchPerformanceInput = styled.input`
  background-color: #313131;
  padding: 9px;
  width: 243px;
  height: 37px;
  border-radius: 5px;
  margin-bottom: 14px;

  border: none;

  font-size: 13px;
  line-height: 19px;
  color: white;

  ::placeholder {
    color: #6b6b6b;
  }
`;

export const Image = styled.img`
  width: 58px;
  height: 58px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 23px;
`;

export const Body = styled(VerticalCenteredRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 25px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  padding-bottom: 14px;
  margin-right: 22px;
  border-bottom: ${props => (props.isSelected ? '3px solid #b4ff87' : 'none')};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border-bottom: 3px solid var(--mainGreen);
  }
  p {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
`;

export const SelectButton = styled(BaseButton)`
  width: 66px;
  height: 21px;
  cursor: pointer;

  p {
    color: #090c13;
  }
`;

export const AltBase = styled(BaseButton)`
  p {
    color: black !important;
  }
`;

export const Filter = styled(VerticalCenteredRow)`
  margin-right: 63px;
  cursor: pointer;

  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 23px;
    margin-right: 7px;
    color: #cecece;
  }
`;

export const MoreOptions = styled.div`
  cursor: pointer;
`;
