import React, { useState, useEffect } from 'react';
import { Wrapper } from './styles';

type CheckboxProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
  handleChange: (checked: boolean) => void;
};

const Checkbox = ({ label, checked, disabled, handleChange, ...props }: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsChecked((prev) => !prev);
    handleChange(!isChecked);
  };

  return (
    <Wrapper>
      <label>
        <input
          {...props}
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          onChange={handleClick}
        />
        <span>{label}</span>
      </label>
    </Wrapper>
  );
};

export default Checkbox;
