import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { getPricingPayment } from '~/API/payments';
import { useUserStore } from '~/store/userStore';
import { IUser } from '~/config/interfaces';
import Spinner from '~/components/Loaders/Preloader';
import { BackButton, Body, Container } from './styles';

const ThankYou = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const setUser = useUserStore(state => state.setUser);

  useEffect(() => {
    if (!sessionId) {
      navigate('/');
    } else {
      setLoading(true);

      const fetchData = async (): Promise<void> => {
        try {
          const response: IUser = (await getPricingPayment(sessionId as string))
            .data.data;
          setUser(response);
          setLoading(false);
        } catch (error) {
          //
        }
      };

      fetchData();
    }
  }, [sessionId]);

  return (
    <Container>
      <Body>
        {loading && <Spinner fz="4rem" h="100%" color="white" />}
        {!loading && (
          <>
            <h2>Your Payment is Successful!</h2>
            <p>
              Thanks for your payment and for supporting a company that supports
              other performers like you. Now go make that vision happen you
              creative beast.
            </p>
            <BackButton onClick={() => navigate('/')}>
              Go Back To Creating
            </BackButton>
          </>
        )}
      </Body>
    </Container>
  );
};

export default ThankYou;
