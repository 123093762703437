import { useCallback, useEffect, useRef, useState } from 'react';
import {
  State as FetchState,
  useFetchArrayBuffer,
} from '@/hooks/use-fetch-array-buffer';
import { useDecodeAudioBuffer } from './use-decode-audio-buffer';
import { useGetAveragePCM } from './use-get-average-pcm/use-get-average-pcm';
import { jobSender } from './get-visualization-data-service';

export type VisualizationData = {
  samples: ArrayLike<number>;
  startTime: number;
  duration: number;
  zoom: number;
};

export function useGetVisualizationData(
  pcm: ArrayLike<number>,
  audioBuffer: AudioBuffer,
  startTime: number,
  duration: number,
  maxSamples: number,
  zoom: number
): VisualizationData | null {
  const [visualizationData, setVisualizationData] =
    useState<VisualizationData | null>(null);

  const lastPcmRef = useRef<ArrayLike<number> | null>(null);

  const updateVisualizationData = useCallback(() => {
    (async () => {
      if (lastPcmRef.current !== pcm) {
        const newPcm = new Float32Array(pcm.length);
        newPcm.set(pcm);
        jobSender.sendMessage([
          {
            type: 'SET_PCM',
            data: newPcm,
          },
          [newPcm.buffer],
        ]);
        lastPcmRef.current = pcm;

        await new Promise(resolve => setTimeout(resolve, 1));
      }

      type Data = {
        startTime: number;
        duration: number;
        sampleRate: number;
        maxSamples: number;
      };

      const data: Data = {
        startTime,
        duration,
        sampleRate: audioBuffer.sampleRate,
        maxSamples,
      };

      const msg = await jobSender.sendJob([{ type: 'DOWNSAMPLE', data }]);

      if (!msg) return;

      const actualDuration = Math.min(
        pcm.length - msg[0].startTime * (audioBuffer.sampleRate / 1000),
        msg[0].duration * (audioBuffer.sampleRate / 1000)
      );

      const samples = {
        samples: msg[0].pcm,
        startTime: msg[0].startTime,
        duration: actualDuration,
        zoom,
      };

      setVisualizationData(samples);
    })().catch(console.error);
  }, [
    pcm,
    audioBuffer,
    startTime,
    duration,
    maxSamples,
    zoom,
    visualizationData,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      const v = visualizationData;
      if (!v) return;

      if (v.startTime !== startTime) {
        updateVisualizationData();
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [
    pcm,
    audioBuffer,
    startTime,
    duration,
    maxSamples,
    zoom,
    visualizationData,
  ]);

  useEffect(() => {
    updateVisualizationData();
  }, [audioBuffer, pcm, startTime, duration, zoom, maxSamples]);

  return visualizationData;
}
