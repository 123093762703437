export function map<T, V>(
  iterable: Iterable<T>,
  mapping: (x: T) => V
): Iterable<V> {
  return {
    *[Symbol.iterator]() {
      for (const it of iterable) {
        yield mapping(it);
      }
    },
  };
}

export function filter<T>(
  iterable: Iterable<T>,
  predicate: (x: T) => boolean
): Iterable<T> {
  return {
    *[Symbol.iterator]() {
      for (const it of iterable) {
        if (predicate(it)) {
          yield it;
        }
      }
    },
  };
}

export function at<T>(iterable: Iterable<T>, index: number): T | undefined {
  if (index < 0 || !Number.isInteger(index)) return undefined;
  if (Array.isArray(iterable)) return iterable[index];

  let i = 0;
  for (const it of iterable) {
    if (i === index) {
      return it;
    }
    i++;
  }
  return undefined;
}

export function length(iterable: Iterable<unknown>): number {
  if (Array.isArray(iterable)) return iterable.length;
  if (iterable instanceof Set) return iterable.size;
  if (iterable instanceof Map) return iterable.size;

  let i = 0;
  for (const _ of iterable) {
    i++;
  }
  return i;
}

export function getKV<K, V>(it: Iterable<[K, V]>, key: K): V | undefined {
  if (it instanceof Map) {
    return it.get(key);
  }

  for (const [k, v] of it) {
    if (k === key) {
      return v;
    }
  }
}

export function setKV<K, V>(
  it: Iterable<[K, V]>,
  key: K,
  value: V
): Iterable<[K, V]> {
  return {
    *[Symbol.iterator]() {
      let found = false;
      for (const [k, v] of it) {
        if (k === key) {
          found = true;
          yield [k, value];
        } else {
          yield [k, v];
        }
      }
      if (!found) {
        yield [key, value];
      }
    },
  };
}

export function unionKV<K, V>(it1: Iterable<[K, V]>, it2: Iterable<[K, V]>) {
  return new Map([...it1, ...it2]);
}

export function has<T>(it: Iterable<T>, value: T): boolean {
  if (it instanceof Set) {
    return it.has(value);
  }

  for (const v of it) {
    if (v === value) {
      return true;
    }
  }

  return false;
}

export function hasKV<K>(it: Iterable<[K, unknown]>, key: K): boolean {
  if (it instanceof Map) {
    return it.has(key);
  }

  for (const [k] of it) {
    if (k === key) {
      return true;
    }
  }

  return false;
}
