import { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { createWrapperAndAppendToBody } from '~/config/utils';

const ReactPortal = ({
  children,
  wrapperId = 'react-portal-wrapper',
}: {
  children: JSX.Element;
  wrapperId: string;
}) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null
  );

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let wasTheElementGenerated = false;

    // if element is not found with wrapperId or wrapperId is not provided,
    // create and append to body
    if (!element) {
      wasTheElementGenerated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }

    setWrapperElement(element);

    return () => {
      // delete the programatically created element
      if (wasTheElementGenerated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (!wrapperElement) return null;

  return createPortal(children, wrapperElement);
};

export default ReactPortal;
