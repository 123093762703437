export type Observable<T> = {
  subscribe: (listener: (value: T) => void) => () => void;
};

export function reduce<T, R>(
  observable: Observable<T>,
  reducer: (accumulator: R, next: T) => R,
  initial: R
): Observable<R> {
  let accumulator: R = initial;
  return {
    subscribe: (listener: (value: R) => void) => {
      return observable.subscribe(value => {
        accumulator = reducer(accumulator, value);
        listener(accumulator);
      });
    },
  };
}

export type Emitter<T> = {
  emit: (value: T) => void;
};

export type Subject<T> = Observable<T> & Emitter<T>;

export function createSubject<T>(): Subject<T> {
  let listeners: ((value: T) => void)[] = [];

  return {
    subscribe: (listener: (value: T) => void) => {
      listeners.push(listener);
      return () => {
        listeners.filter(l => l !== listener);
      };
    },
    emit: (value: T) => {
      for (const listener of listeners) {
        listener(value);
      }
    },
  };
}

export function once<T>(): {
  emit: (value: T) => void;
  subscribe: (listener: (value: T) => void) => () => void;
} {
  let listeners: ((value: T) => void)[] = [];
  let storedValue: [T] | null = null;
  return {
    emit: (value: T) => {
      if (storedValue) return;
      storedValue = [value];
      for (const listener of listeners) {
        listener(value);
      }
    },
    subscribe: (listener: (value: T) => void) => {
      if (storedValue) {
        listener(storedValue[0]);
        return () => {};
      }
      listeners.push(listener);
      return () => {
        listeners.filter(l => l !== listener);
      };
    },
  };
}

export function emitterToPromise<T>(once: Observable<T>): Promise<T> {
  return new Promise(resolve => {
    const subscribed = once.subscribe(value => {
      resolve(value);
      subscribed();
    });
  });
}
