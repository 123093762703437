import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #141712;
  display: flex;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1c2119;

  h2 {
    font-weight: 700;
    font-size: 45px;
    line-height: 69px;
    color: #ffffff;
    margin-bottom: 25px;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #d0d0d0;
    margin-bottom: 33px;
    max-width: 45%;
    text-align: center;
  }
`;

export const BackButton = styled.div`
  background-color: #b4ff87;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 11px 18px 12px 18px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #515151;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
`;
