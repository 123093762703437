import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MainBtn } from './auxiliaries/MainBtn';
import Spinner from '~/components/Loaders/Preloader';
import { ROUTES_MAP } from '~/config/constants';
import { useUserStore } from '~/store/userStore';
import { SKLogo } from './auxiliaries/Logo';
import {
  Container,
  OrText,
  Form,
  FormControl,
  Label,
  Input,
  NoAccount,
} from './style';
import { sendPasswordResetEmail } from 'firebase/auth';
import firebaseService from '~/services/firebase';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const loading = useUserStore(state => state.loading);
  const [email, setEmail] = useState('');

  async function triggerResetEmail(e: React.MouseEvent) {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(firebaseService.auth, email);

      toast.success('Please check your email for instructions.');
    } catch (error) {
      let errorMessage =
        'Sorry, something went wrong - please contact support@stagekeep.com';

      if (error.code == 'auth/email-already-in-use') {
        errorMessage =
          'This email is already in use, please reset your password.';
      } else if (
        ['auth/invalid-email', 'auth/missing-email'].includes(error.code)
      ) {
        errorMessage = 'Please provide a valid email address.';
      }

      toast.error(errorMessage);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }
  return (
    <Container>
      <SKLogo />
      <OrText>Forgot your password?</OrText>
      <Form>
        <FormControl>
          <Label htmlFor={'email'}></Label>
          <Input
            type={'email'}
            placeholder={'email'}
            autoFocus
            id={'email'}
            value={email}
            onChange={handleChange}
          ></Input>
        </FormControl>

        <MainBtn fnClick={triggerResetEmail}>
          {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Send email'}
        </MainBtn>
      </Form>
      <NoAccount>
        Have an account?{' '}
        <span>
          <Link to={ROUTES_MAP.login}>Login</Link>
        </span>
      </NoAccount>
    </Container>
  );
};

export default ForgotPassword;
