import {
  HorizontalCenteredColumn,
  Row,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  inset: 0;
  z-index: 999;

  transition: all 0.3s ease-in-out;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.58);
`;

export const ContentContainer = styled.div`
  width: 637px;
  height: 461px;

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: #141712;
  border-radius: 5px;
`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;

  top: 21px;
  right: 24px;
`;

export const HeaderConatiner = styled.div`
  width: 100%;
  height: 196px;

  border-radius: 5px 5px 0px 0px;

  background-color: #1c2119;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  line-height: 34px;

  color: #ffffff;
`;

export const InputContainer = styled.div`
  border-bottom: 0.5px solid #5f5f5f;
  width: 100%;

  p {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;

    color: #d0d0d0;

    margin-bottom: 15px;
  }

  input {
    height: 19px;

    border: none;
    background: none;

    color: #ffffff;
  }
`;

export const Body = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 56px 47px 29px 52px;
`;

export const ImageDropZone = styled.div<{ error: boolean }>`
  width: 217px;
  height: 78px;
  border: 2px dashed ${props => (props.error ? '#ff4242' : '#585d66')};
  border-radius: 3px;

  div {
    &:first-child {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      img {
        width: 25px;
        height: 19px;
        object-fit: contain;
        margin-bottom: 9px;
      }

      p {
        font-weight: bold;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
      }
    }
  }
`;

export const MusicDropZone = styled.div<{ error: boolean }>`
  width: 217px;
  height: 68px;
  border: 2px dashed ${props => (props.error ? '#ff4242' : '#585d66')};
  border-radius: 3px;

  div {
    &:first-child {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      img {
        height: 28.6px;
        width: 21.5px;
        object-fit: contain;
        margin-bottom: 8.33px;
      }

      p {
        font-weight: bold;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
      }
    }
  }
`;

export const MusicTypeList = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  width: 78px;
  height: 68px;
`;

export const MusicTypeItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  background: ${props => (props.isSelected ? '#4299FF' : '#5a5f69')};
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  padding: 4px 6px;
  :first-child {
    margin-top: 10px;
  }
  :last-child {
    margin-bottom: 0;
  }

  p {
    margin-left: 8px;
    color: white;
    font-weight: bold;
    font-size: 7px;
    line-height: 10px;
    marigin-right 8px;
  }
`;

export const BlueText = styled.span`
  color: #4299ff;
`;

export const GoldText = styled.span`
  color: #ffe142;
  text-decoration: underline;
  font-weight: bold;
`;

export const PerformanceImagePreview = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 16px;
`;

export const BodyContent = styled.div`
  flex: 1;
`;

export const StageDesignPreviewContainer = styled.div`
  background-color: black;
  width: 200px;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 110px;
    height: 97px;
    object-fit: contain;
  }
`;

export const PerformanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: #d0d0d0;
  margin-bottom: 20px;

  &:not(:first-child) {
    margin-top: 32px;
  }
`;
