import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';

import {
  BaseButton,
  VerticalCenteredRow,
} from '~/assets/styles/styledBaseComponents';
import Spinner from '~/components/Loaders/Preloader';
import BaseModal from './BaseModal';

type DeleteModalProps = {
  title: string;
  description: string;
  isOpen: boolean;
  loading: boolean;
  handleOnArchive?: () => void;
  handleOnDelete: () => void;
  handleOnClose: () => void;
};

const DeleteModal = (props: DeleteModalProps) => {
  const [word, setWord] = useState<string>('');

  const {
    title,
    description,
    loading,
    handleOnClose,
    handleOnArchive,
    handleOnDelete,
  } = props;

  return (
    <BaseModal
      {...props}
      handleOnClose={handleOnClose}
      hideDefaultCloseButton
      customContentContainerStyles={{
        backgroundColor: '#282D38',
        width: '637px',
        height: '353px',
        padding: '55px 88px 56px 88px',
      }}
    >
      <Fragment>
        <CloseButton onClick={handleOnClose}>
          <AiOutlineClose color="black" size={32} />
        </CloseButton>

        <Title>{title}</Title>
        <Description>{description}</Description>

        <VerticalCenteredRow
          style={{
            justifyContent: 'center',
            marginBottom: '31px',
          }}
        >
          <Input
            placeholder="TYPE THE WORD DELETE"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
        </VerticalCenteredRow>

        <VerticalCenteredRow
          style={{
            justifyContent: 'center',
          }}
        >
          {handleOnArchive && (
            <BaseButton
              disabled={(word.toUpperCase() !== "DELETE") || loading}
              onClick={handleOnArchive}
              backgroundColor="#806BFF"
            >
              <p>Archive</p>
            </BaseButton>
          )}
          <BaseButton
            disabled={(word.toUpperCase() !== "DELETE") || loading}
            onClick={handleOnDelete}
            backgroundColor="#FF4242"
          >
            <p>Delete Forever</p>
          </BaseButton>
          {loading && <Spinner fz="28px" h="28px" color="black" />}
        </VerticalCenteredRow>
      </Fragment>
    </BaseModal>
  );
};

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 21px;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
`;

const Description = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 22px;
  text-align: center;
`;

const Input = styled.input`
  background-color: #5D6883;
  width: 100%;
  max-width: 243px;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  padding: 8px 9px 9px 9px;
  line-height: 1.2rem;
  text-align: center;

  &::placeholder {
    color: #fff;
  }
`;

export default DeleteModal;
