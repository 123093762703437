import React from 'react';
import Spinner from '~/components/Loaders/Preloader';

const FullPageLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      }}
    >
      <Spinner fz="" h="100%" />
    </div>
  );
};

export default FullPageLoader;
