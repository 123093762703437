import {
  InferType,
  exact,
  exclude,
  object,
  string,
  unknown,
} from '../utils/type-guardian';

export const createEventSchema = object({
  type: exact('CREATE'),
  data: object({
    id: string(),
    author: unknown(),
    commentBody: string(),
    time: unknown(),
  }),
});

export type CreateEvent = InferType<typeof createEventSchema>;

export type EventData = InferType<typeof createEventSchema>;

export type Event<T extends EventData> = {
  data: T;
  eventId: string;
  createdAt: Date;
};

export const untrustedEventSchema = object({
  data: exclude(exclude(unknown<EventData>(), exact(null)), exact(undefined)),
  eventId: string(),
  createdAt: string(),
});

export type UntrustedEvent = InferType<typeof untrustedEventSchema>;
