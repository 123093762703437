import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { ROUTES_MAP } from '~/config/constants';
import Login from '~/views/Auth/Login';
import Register from '~/views/Auth/Register';
import ForgotPassword from '~/views/Auth/ForgotPassword';
import PreviewView from '~/views/Project/PreviewView';

export default function UnauthorizedRoutes() {
  return (
    <Routes>
      <Route path={ROUTES_MAP.login} element={<Login />} />
      <Route path={ROUTES_MAP.register} element={<Register />} />
      <Route path={ROUTES_MAP.forgot} element={<ForgotPassword />} />
      <Route path={ROUTES_MAP.preview} element={<PreviewView />} />
      <Route
        path="*"
        element={
          <main>
            <p>Not found.</p>
            <small>Unauthorized Routes</small>
          </main>
        }
      />
    </Routes>
  );
}
