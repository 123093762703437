import React from 'react';
import { Container } from './styles';

type LargeButtonProps = {
  text: string;
  handleOnClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  type?: 'button' | 'submit' | 'reset';
};

const LargeButton = ({
  text,
  handleOnClick,
  backgroundColor = '#B4FF87',
  textColor = '#171B24',
  type = 'button',
}: LargeButtonProps) => (
  <Container
    type={type}
    onClick={handleOnClick}
    style={{
      backgroundColor,
      border: `1px solid ${backgroundColor}`,
    }}
  >
    <p
      style={{
        color: textColor,
      }}
    >
      {text}
    </p>
  </Container>
);

export default LargeButton;
