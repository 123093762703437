import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

import firebaseService from '~/services/firebase';
import { LoginInputs } from '~/config/interfaces';
import { ROUTES_MAP } from '~/config/constants';
import { useUserStore } from '~/store/userStore';
import Spinner from '~/components/Loaders/Preloader';
import { MainBtn } from './auxiliaries/MainBtn';
import { SKLogo } from './auxiliaries/Logo';
import { loginInputs, turnArrToObj } from './data';
import {
  Container,
  GoogleAuth,
  ImgContainer,
  GoogleImg,
  GoogleAuthBtn,
  OrText,
  Form,
  FormControl,
  Label,
  Input,
  NoAccount,
  ColumnContainer,
} from './style';
import googleImg from '~/assets/images/google.png';

const Register = () => {
  const registerDispatch = useUserStore(state => state.register);
  const authProviderDispatch = useUserStore(state => state.authProvider);
  const loading = useUserStore(state => state.loading);
  const navigate = useNavigate();

  const [inputs, setInputs] = useState<LoginInputs>(turnArrToObj(loginInputs));
  async function handleRegister(e: React.MouseEvent) {
    e.preventDefault();
    try {
      const user = await createUserWithEmailAndPassword(
        firebaseService.auth,
        inputs.Email,
        inputs.Password
      );
      if (user) {
        registerDispatch(user, navigate);
      }
    } catch (error) {
      let errorMessage =
        'Sorry, something went wrong - please contact support@stagekeep.com';

      if (error.code == 'auth/email-already-in-use') {
        errorMessage =
          'This email is already in use, please reset your password.';
      } else if (
        ['auth/invalid-email', 'auth/missing-email'].includes(error.code)
      ) {
        errorMessage = 'Please provide a valid email address.';
      }

      toast.error(errorMessage);
    }
  }

  const signInWithGoogle = async () => {
    try {
      const user = await signInWithPopup(
        firebaseService.auth,
        firebaseService.googleProvider
      );
      if (user) {
        authProviderDispatch(user, navigate);
      }
    } catch (err) {
      console.log('There is an error signing up the user w/ Google', err);
    }
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  return (
    <ColumnContainer>
      <SKLogo />
      <GoogleAuth onClick={signInWithGoogle}>
        <ImgContainer>
          <GoogleImg src={googleImg} alt="Google logo" />
        </ImgContainer>
        <GoogleAuthBtn>Continue With Google</GoogleAuthBtn>
      </GoogleAuth>
      <OrText>OR</OrText>
      <Form>
        {loginInputs.map((i, ind) => (
          <FormControl key={i}>
            <Label htmlFor={i}></Label>
            <Input
              type={i}
              placeholder={i}
              autoFocus={ind === 0 ? true : false}
              id={i}
              value={inputs[i]}
              name={i}
              onChange={handleChange}
            ></Input>
          </FormControl>
        ))}

        <MainBtn fnClick={handleRegister}>
          {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Create account'}
        </MainBtn>
      </Form>
      <NoAccount>
        Have an account?{' '}
        <span>
          <Link to={ROUTES_MAP.login}>Login</Link>
        </span>
      </NoAccount>
    </ColumnContainer>
  );
};

export default Register;
