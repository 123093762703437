import { useEffect, useRef, useState } from 'react';

export function useVideoLength(
  videoUrl: string | undefined | null
): number | undefined {
  const [videoLength, setVideoLength] = useState<number | undefined>(undefined);
  const currentUrl = useRef(videoUrl);

  useEffect(() => {
    currentUrl.current = videoUrl;

    if (!videoUrl) {
      setVideoLength(undefined);
      return;
    }

    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      if (videoUrl != currentUrl.current) {
        return;
      }
      setVideoLength(video.duration);
    };
    video.src = videoUrl;
  }, [videoUrl]);

  return videoLength;
}
