import React, { useEffect, useState } from 'react';
import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiFillFolder,
  AiFillQuestionCircle,
  AiFillPlusCircle,
  AiFillRocket,
} from 'react-icons/ai';
import { IoPowerSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  loadProjectsSelector,
  projectsSelector,
  useProjectsStore,
  selectedProjectIdSelector,
  selectProjectSelector,
} from '~/store/projectsStore';
import { useUserStore } from '~/store/userStore';
import { PRICING, ROUTES_MAP } from '~/config/constants';
import CreateProjectModal from '~/components/Modals/CreateProjectModal';
import {
  Container,
  Logo,
  ProjectItem,
  ProjectsList,
  SidebarFooter,
  SidebarOption,
  SidebarOptionIcon,
  UserNameContainer,
} from './styles';

type SidebarProps = {
  handleUpgrade: () => void;
};

const Sidebar = ({ handleUpgrade }: SidebarProps) => {
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const user = useUserStore(state => state.user);

  const loadProjects = useProjectsStore(loadProjectsSelector);
  const selectProject = useProjectsStore(selectProjectSelector);
  const selectedProjectId = useProjectsStore(selectedProjectIdSelector);
  const projects = useProjectsStore(projectsSelector);

  const loadData = async () => {
    try {
      await loadProjects();
    } catch (error) {
      toast.error('There was an error loading the projects');
    }
  };

  const logout = () => {
    navigate(ROUTES_MAP.logout);
  };

  useEffect(() => {
    loadData();
  }, []);

  // Show the first project's performances
  useEffect(() => {
    if (projects?.length > 0 && !selectedProjectId) {
      selectProject(projects[0]._id);
    }
  }, [projects]);

  return (
    <>
      <Container>
        <Logo src="/images/SKIconWhite.svg" />

        <UserNameContainer onClick={() => setIsCreateModalOpen(true)}>
          <p>Create a Project</p>
          <AiFillPlusCircle color="#b4ff87" size={28} />
        </UserNameContainer>

        <ProjectsList>
          {projects.map(_project => (
            <ProjectItem
              isSelected={_project._id === selectedProjectId}
              key={_project._id}
              onClick={() => selectProject(_project._id)}
            >
              {_project._id === selectedProjectId ? (
                <AiFillCaretDown color="#526075" size={12} />
              ) : (
                <AiFillCaretRight color="#526075" size={12} />
              )}{' '}
              <AiFillFolder color="#767980" size={25} />{' '}
              <p>
                {_project.name.length > 15
                  ? `${_project.name.substring(0, 15)}...`
                  : _project.name}
              </p>
            </ProjectItem>
          ))}
        </ProjectsList>

        <SidebarFooter>
          <SidebarOption>
            <SidebarOptionIcon>
              <img src={user?.photoURL || '/images/avatar.svg'} width="26" />
            </SidebarOptionIcon>
            <p>{`${user?.firstName} ${user?.lastName}`}</p>
          </SidebarOption>

          <SidebarOption>
            <SidebarOptionIcon>
              <AiFillQuestionCircle color="#CECECE" size={26} />
            </SidebarOptionIcon>
            <p>
              <a href="https://youtube.com/playlist?list=PLm1c3qouTkep4GNlKEO_a_l9vsbj6nWLu&si=VE8LEW319DZL0aUW">
                Tutorials
              </a>
            </p>
          </SidebarOption>

          <SidebarOption color="#FFE142" onClick={handleUpgrade}>
            <SidebarOptionIcon>
              <AiFillRocket color="#FFE142" size={26} />
            </SidebarOptionIcon>
            {user?.pricing !== 'FREE' ? <p>Manage Plan</p> : <p>Upgrade</p>}
          </SidebarOption>

          <SidebarOption onClick={logout}>
            <SidebarOptionIcon>
              <IoPowerSharp color="#CECECE" size={22} />
            </SidebarOptionIcon>
            <p>Logout</p>
          </SidebarOption>
        </SidebarFooter>
      </Container>

      <CreateProjectModal
        handleOnClose={() => setIsCreateModalOpen(false)}
        isOpen={isCreateModalOpen}
      />
    </>
  );
};

export default Sidebar;
