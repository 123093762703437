import React, { useEffect, useReducer, useRef, useState } from 'react';
import { LogarithmicValue } from '../../lib/logarithmic-value';
import {
  useGetVisualizationData,
  VisualizationData,
} from './hooks/use-get-visualization-data/use-get-visualization-data';

type AudioVisualizerProps = {
  pcm: ArrayLike<number>;
  audioBuffer: AudioBuffer;
  camera: Readonly<{
    position: number;
    zoom: LogarithmicValue;
  }>;

  /**
   * THe width is in pixels
   */
  width: number;
  height: number;

  x: number;
  y: number;

  theme: 'light' | 'dark';
};

export function AudioVisualizer({
  pcm,
  audioBuffer,
  camera,
  width,
  height,
  x,
  y,
  theme,
}: AudioVisualizerProps) {
  const avData = useGetVisualizationData(
    pcm,
    audioBuffer,

    // TODO: having to divide by the zoom is fucked up.
    //
    //   We need to refactor the code in the entire render function to use
    //   absolute positions.
    camera.position / camera.zoom.linear,
    width / camera.zoom.linear,
    width,
    camera.zoom.linear
  );
  const [newAvData, setNewAvData] = useState<
    (VisualizationData & { imageSource: string }) | null
  >(null);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const context2dRef = useRef<CanvasRenderingContext2D | null>(null);

  useEffect(() => {
    if (!avData) return;
    if (!canvasRef.current)
      canvasRef.current = document.createElement('canvas');

    const canvas = canvasRef.current;

    canvas.height = height;
    canvas.width = width;

    if (!context2dRef.current) context2dRef.current = canvas.getContext('2d');

    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (const [i, sample] of Array.from(avData.samples).entries()) {
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = theme === 'dark' ? 'black' : 'rgb(200, 200, 200)';
        ctx.fillStyle = theme === 'dark' ? 'black' : 'rgb(200, 200, 200)';

        ctx.fillRect(
          i,
          (canvas.height - sample * canvas.height) / 2,
          1,
          sample * canvas.height
        );

        ctx.stroke();
      }

      canvas.toBlob(url => {
        if (!url) return;
        if (newAvData) URL.revokeObjectURL(newAvData.imageSource);
        setNewAvData({ ...avData, imageSource: URL.createObjectURL(url) });
      });
    }

    return () => {
      if (!newAvData) return;
      URL.revokeObjectURL(newAvData.imageSource);
    };
  }, [avData]);

  if (!newAvData || !avData) return null;

  return newAvData ? (
    <image
      href={newAvData.imageSource}
      height={height}
      width={(width * camera.zoom.linear) / newAvData.zoom}
      x={x - (camera.position - newAvData.startTime * camera.zoom.linear)}
      y={y}
      preserveAspectRatio="none"
    />
  ) : (
    <></>
  );
}
