import {
  getKV,
  hasKV,
  map,
  setKV,
  unionKV,
  filter,
  has,
} from '../lib/iterable-helpers';
import produce from 'immer';
import { Vector2 } from '../lib/vector2';
import { memoize, memoizeWeak } from './memoize';

export type Entity = {
  color: string;
  shape: Shape;
  name: string;
};

type FormationTime = {
  id: string;
  duration: number;
  transitionDuration: number;
};

export type Formation = {
  id: string;
  name: string;
  positions: [string, EntityPlacement][];
  duration: number;
  transitionDuration: number;
};

type Music =
  | null

  // TODO: this really needs to go
  | {
      type: '8CountBeat';
    }
  | {
      type: 'custom';
      value: {
        name: string;
        url: string;
      };
    };

export type PerformanceProject = {
  name: string;
  imageUrl: string;
  entities: [string, Entity][];
  formations: Formation[];
  music: Music;
  videos?: Video[] | null;
  markers?: Marker[];
  showVideo?: boolean;
  hideGrid?: boolean;
  hidePaths?: boolean;
  snapToGrid?: boolean;
  visibleEntityWidth?: number;
};

// TODO: unit test this
/**
 * Get the entity placement for a given formation index
 * @param performanceProject The performance project
 * @param index The formation index for which to get the entity placement
 * @returns An entity placement
 */
const entityPlacement = memoizeWeak(
  (formations: PerformanceProject['formations']) =>
    memoize((formationIndex: number) =>
      memoize((entityId: string): EntityPlacement => {
        if (formationIndex < 0 || formationIndex > formations.length) {
          return { position: [0, 0] }; // TODO: deal with null values somehow
        }

        const formation = formations[formationIndex];
        if (!formation) {
          return { position: [0, 0] };
        }
        const placement = getKV(formation.positions, entityId);

        if (placement) {
          return placement;
        }

        if (!placement) {
          const formation = formations
            .slice(0, formationIndex)
            .reverse()
            .find(f => hasKV(f.positions, entityId));
          if (formation) {
            const entity = getKV(formation.positions, entityId);
            if (entity) {
              return entity;
            }
          }
        }

        if (!placement) {
          const formation = formations
            .slice(formationIndex + 1)
            .find(f => hasKV(f.positions, entityId));
          if (formation) {
            const entity = getKV(formation.positions, entityId);
            if (entity) {
              return entity;
            }
          }
        }

        return { position: [0, 0] };
      })
    )
);

const entityPlacementAtTime = memoizeWeak((project: PerformanceProject) =>
  memoize((time: number) =>
    memoize((entityId: string): EntityPlacement => {
      const { formations } = project;
      let timeAndFormation = {
        elapsedTime: 0,
        transition: 0,
        index: formations.length - 1,
      };

      for (const [index, formation] of formations.entries()) {
        timeAndFormation = {
          index,
          elapsedTime: timeAndFormation.elapsedTime + formation.duration,
          transition: formation.transitionDuration,
        };

        if (
          timeAndFormation.elapsedTime + formation.transitionDuration <
          time
        ) {
          timeAndFormation.elapsedTime += formation.transitionDuration;
        } else {
          break;
        }
      }

      // No formations exist
      if (timeAndFormation.index < 0) {
        return { position: [0, 0] };
      }

      const placement = entityPlacement(project.formations)(
        timeAndFormation.index
      )(entityId);

      if (time > timeAndFormation.elapsedTime) {
        const nextPlacement = entityPlacement(project.formations)(
          timeAndFormation.index + 1
        )(entityId);
        const transition = time - timeAndFormation.elapsedTime;
        const transitionProgress = transition / timeAndFormation.transition;

        return {
          position: [
            placement.position[0] +
              (nextPlacement.position[0] - placement.position[0]) *
                transitionProgress,
            placement.position[1] +
              (nextPlacement.position[1] - placement.position[1]) *
                transitionProgress,
          ],
        };
      }

      return placement;
    })
  )
);

const getFormation = memoizeWeak((proj: PerformanceProject) => {
  const { formations, entities, ...project } = proj;
  return memoize((index: number) => {
    const getEntityPlacement = entityPlacement(formations)(index);
    const placements = [
      ...map(
        entities,
        ([id]) => [id, getEntityPlacement(id)] as [string, EntityPlacement]
      ),
    ];
    const exists = 0 <= index && index < formations.length;

    return {
      get exists(): boolean {
        return exists;
      },

      entity: memoize((id: string) => ({
        get placement(): EntityPlacement {
          return getEntityPlacement(id);
        },

        setPlacement: (placement: EntityPlacement): PerformanceProject => {
          return produce(proj, draft => {
            if (index < 0 || index > formations.length) {
              return;
            }
            const { positions } = formations[index];
            draft.formations[index].positions = [
              ...setKV(positions, id, placement),
            ];
          });
        },

        setAttributes: (attributes: Partial<Entity>): PerformanceProject => {
          return produce(proj, draft => {
            draft.entities = [
              ...setKV(entities, id, {
                ...(getKV(entities, id) || {
                  color: 'black',
                  shape: 'circle' as 'circle',
                  name: '',
                }),
                ...attributes,
              }),
            ];
          });
        },
      })),

      clone(): Formation {
        return {
          ...formations[index],
          positions: [...this.placements],
        };
      },

      get placements(): [string, EntityPlacement][] {
        return placements;
      },

      getPlacementAtTime: memoize(
        (time: number): Iterable<[string, EntityPlacement]> => {
          const getEntityPlacementAtTime = entityPlacementAtTime({
            formations,
            entities,
            ...project,
          })(time);
          return map(entities, ([id]) => [id, getEntityPlacementAtTime(id)]);
        }
      ),

      setPlacements(
        placements: Iterable<[string, EntityPlacement]>
      ): PerformanceProject {
        return produce({ entities, formations, ...project }, draft => {
          if (index < 0 || index >= formations.length) return;
          draft.formations[index].positions = [
            ...unionKV(formations[index].positions, placements),
          ];
        });
      },

      setPositions(positions: Iterable<[string, Vector2]>): PerformanceProject {
        return produce({ entities, formations, ...project }, draft => {
          if (index < 0 || index >= formations.length) return;
          draft.formations[index].positions = [
            ...unionKV(
              map(entities, ([id]) => [id, getEntityPlacement(id)]),
              map(positions, ([id, position]) => [id, { position }])
            ),
          ];
        });
      },
    };
  });
});

const getFormationIndexById = memoizeWeak(
  ({ formations }: PerformanceProject) =>
    memoize((id: string): number | null => {
      const result = formations.findIndex(f => f.id === id);
      if (result < 0) {
        return null;
      }
      return result;
    })
);

// TODO: unit test this
export const performance = memoizeWeak((proj: PerformanceProject) => {
  const { entities, formations, markers, hideGrid, hidePaths, ...project } =
    proj;
  return {
    get music() {
      return project.music;
    },

    get entities(): Readonly<readonly [string, DeepReadonly<Entity>][]> {
      return entities;
    },

    get markers(): DeepReadonly<typeof markers> {
      return markers;
    },

    get showVideo(): boolean | undefined {
      return proj.showVideo;
    },

    get videos(): Video[] | null | undefined {
      return project.videos;
    },

    get hideGrid(): boolean | undefined {
      return hideGrid;
    },

    get hidePaths(): boolean | undefined {
      return hidePaths;
    },

    setEntity: (id: string, entity: Entity): PerformanceProject => {
      return produce(proj, draft => {
        // TODO: It shouldn't matter whether or not we convert an iterable to an
        //  array!
        draft.entities = [...setKV(entities, id, entity)];
      });
    },

    setEntities: (
      changedEntities: Iterable<[string, Entity]>
    ): PerformanceProject => {
      return produce(proj, draft => {
        draft.entities = [...unionKV(entities, changedEntities)];
      });
    },

    addEntity: (id: string, entity: Entity): PerformanceProject => {
      return produce(proj, draft => {
        draft.entities = [...entities, [id, entity]];
      });
    },

    deleteEntity: (id: string): PerformanceProject => {
      return produce(proj, draft => {
        // TODO: It shouldn't matter whether or not we convert an iterable to an
        //  array!
        draft.entities = [...filter(entities, ([entityId]) => entityId !== id)];
      });
    },

    deleteEntities: (ids: Iterable<string>): PerformanceProject => {
      return produce(proj, draft => {
        // TODO: It shouldn't matter whether or not we convert an iterable to an
        //  array!
        draft.entities = [
          ...filter(entities, ([entityId]) => !has(ids, entityId)),
        ];
      });
    },

    pushFormation: (
      name: string,
      duration: number,
      transitionDuration: number
    ): PerformanceProject => {
      return produce(proj, draft => {
        // TODO: use a stronger
        const ids = new Set(formations.map(({ id }) => id));
        let idNumber = formations.length;
        while (ids.has(idNumber.toString())) {
          idNumber++;
        }
        draft.formations.push({
          id: idNumber.toString(),
          name,
          positions: [],

          // TODO: there needs to be a way to ensure that duration is set to these
          //   these minimum defaults
          duration: duration < 0 ? 0 : duration,

          // Certain velocities are physically impossible, but at the same time,
          // the customer is always right 🤷‍♂️
          transitionDuration: transitionDuration < 10 ? 10 : transitionDuration,
        });
      });
    },

    pushMarker: (marker: Marker) => {
      return produce(proj, draft => {
        if (!draft.markers) {
          draft.markers = [marker];
        } else {
          draft.markers.push(marker);
        }
      });
    },

    deleteMarker: (index: number) => {
      return produce(proj, draft => {
        if (!draft.markers) return;
        draft.markers.splice(index, 1);
      });
    },

    setMarkers: (markers: Marker[]) => {
      return produce(proj, draft => {
        draft.markers = markers;
      });
    },

    setVideos: (videos?: Video[] | null) => {
      return produce(proj, draft => {
        draft.videos = videos;
      });
    },

    setShowVideo: (showVideo: boolean) => {
      return produce(proj, draft => {
        draft.showVideo = showVideo;
      });
    },

    setHideGrid: (hideGrid: boolean) => {
      return produce(proj, draft => {
        draft.hideGrid = hideGrid;
      });
    },

    setHidePaths: (hidePaths: boolean) => {
      return produce(proj, draft => {
        draft.hidePaths = hidePaths;
      });
    },

    setSnapToGrid: (snapToGrid: boolean) => {
      return produce(proj, draft => {
        draft.snapToGrid = snapToGrid;
      });
    },

    setVisibleEntityWidth: (visibleEntityWidth: number) => {
      return produce(proj, draft => {
        draft.visibleEntityWidth = visibleEntityWidth;
      });
    },

    insertFormation: (formation: Formation, after: number) => {
      return produce(proj, draft => {
        draft.formations.splice(after + 1, 0, formation);
      });
    },

    updateFormationName(index: number, name: string): PerformanceProject {
      return produce(proj, draft => {
        draft.formations[index].name = name;
      });
    },

    cloneFormation(index: number): Formation {
      // TODO: handle edge cases (index out of bound, etc.)!
      return {
        ...formations[index],
        positions: [...formations[index].positions],
      };
    },

    deleteFormation(index: number): PerformanceProject {
      return produce(proj, draft => {
        if (formations.length === 1) return;
        if (index === 0 && formations.length > 1) {
          draft.formations[1] = this.cloneFormation(1);
          draft.formations[1].transitionDuration =
            formations[0].duration +
            formations[0].transitionDuration +
            formations[1].transitionDuration;
        }
        if (index < formations.length - 1 && index > 0) {
          draft.formations[index - 1].transitionDuration =
            formations[index - 1].transitionDuration +
            formations[index].duration +
            formations[index].transitionDuration;
        }
        draft.formations.splice(index, 1);
      });
    },

    swapFormations(index1: number, index2: number): PerformanceProject {
      // TODO: handle edge cases!
      return produce(proj, draft => {
        const temp = this.cloneFormation(index1);
        draft.formations[index1] = this.cloneFormation(index2);
        draft.formations[index2] = temp;
      });
    },

    updateFormationTimes: (
      formationTimes: Iterable<FormationTime>
    ): PerformanceProject => {
      const formationsMap = new Map<string, FormationTime>(
        [...formationTimes].map(f => [f.id, f])
      );
      return produce(proj, draft => {
        for (const [
          index,
          { id, duration, transitionDuration },
        ] of formations.entries()) {
          draft.formations[index] = {
            ...draft.formations[index],
            ...(formationsMap.get(id) ?? { duration, transitionDuration }),
          };
        }
      });
    },

    get formationsCount(): number {
      return formations.length;
    },

    get formations(): readonly Formation[] {
      return formations;
    },

    get totalTime(): number {
      return formations.reduce(
        (acc, { duration, transitionDuration }, i) =>
          acc + duration + (i < formations.length - 1 ? transitionDuration : 0),
        0
      );
    },

    getFormationAtTime: memoize((time: number): [number, Formation] | null => {
      let elapsedTime = 0;

      for (const [index, formation] of formations.entries()) {
        const totalFormationDuration =
          formation.duration + formation.transitionDuration;
        if (
          elapsedTime <= time &&
          time < elapsedTime + totalFormationDuration
        ) {
          return [index, formation];
        }
        elapsedTime += totalFormationDuration;
      }

      return null;
    }),

    getStartTimeAtFormationIndex: memoize((index: number) => {
      let elapsedTime = 0;
      for (const [i] of formations.entries()) {
        if (i === index) {
          return elapsedTime;
        }
        elapsedTime +=
          formations[i].duration + formations[i].transitionDuration;
      }
    }),

    getEndTimeAtFormationIndex: memoize((index: number) => {
      let elapsedTime = 0;
      for (const [i] of formations.entries()) {
        if (i === index) {
          return elapsedTime + formations[i].duration;
        }
        elapsedTime +=
          formations[i].duration + formations[i].transitionDuration;
      }
    }),

    getFormationIndexById: memoize((id: string) =>
      getFormationIndexById({ entities, formations, ...project })(id)
    ),

    getFormationById: memoize(
      (id: string): ReturnType<ReturnType<typeof getFormation>> =>
        getFormation({ entities, formations, ...project })(
          getFormationIndexById({ entities, formations, ...project })(id) || -1
        )
    ),

    getFormationByIndex: (index: number) =>
      getFormation({ entities, formations, ...project })(index),
  };
});

export type Performance = ReturnType<typeof performance>;
export type FormationHelpers = ReturnType<Performance['getFormationByIndex']>;

export const joinPlacements = memoizeWeak(
  (source: Iterable<[string, EntityPlacement]>) =>
    memoizeWeak((destination: Iterable<[string, EntityPlacement]>) => {
      const result = new Map<
        string,
        { from: EntityPlacement; to: EntityPlacement }
      >();

      const destinationMap = new Map(destination);

      for (const [id, placement] of source) {
        const to = destinationMap.get(id);
        if (!to) {
          continue;
        }
        result.set(id, {
          from: placement,
          to,
        });
      }

      return result;
    })
);
