import { ReadOnlySet } from '@/lib/readonly-map-set';
import { getKV } from '@/lib/iterable-helpers';
import React, { useEffect, useState } from 'react';
import { canvasContext } from './canvas-context';

const Thumbnail = ({
  placements,
  entities,
  stageBackgroundImage,
  selectedEntities,
}: {
  placements: Iterable<[string, EntityPlacement]>;
  entities: Iterable<[string, Entity]>;
  stageBackgroundImage?: string | null;
  selectedEntities: ReadOnlySet<string>;
}) => {
  const width = 60; // Specify the desired width of the thumbnail canvas
  const height = 60; // Specify the desired height of the thumbnail canvas
  const [thumbnailData, setThumbnailData] = useState<string | null>(null);

  useEffect(() => {
    const renderThumbnail = async () => {
      const { canvas, context } = canvasContext();

      if (!context) return;

      canvas.setAttribute('width', width.toString());
      canvas.setAttribute('height', height.toString());

      let scale = 1;

      let minX = Infinity;
      let maxX = -Infinity;
      let minY = Infinity;
      let maxY = -Infinity;

      for (const [key, placement] of placements) {
        const position = placement.position;
        minX = Math.min(minX, position[0]);
        maxX = Math.max(maxX, position[0]);
        minY = Math.min(minY, position[1]);
        maxY = Math.max(maxY, position[1]);
      }
      let rangeX = maxX - minX;
      let rangeY = maxY - minY;
      let offsetX = (minX + maxX) / 2;
      let offsetY = (minY + maxY) / 2;
      let maxRange = Math.max(rangeX, rangeY);
      let padding = 0.5 * maxRange;

      [...entities].forEach(async ([key, entity]) => {
        const shape = entity.shape;
        const currentPosition = getKV(placements, key)?.position ?? [0, 0];
        const color = entity.color;
        context.fillStyle = color;
        context.strokeStyle = 'black';
        const xPos =
          (currentPosition[0] - offsetX) * (width / (maxRange + padding)) +
          width / 2;
        const yPos =
          (-currentPosition[1] + offsetY) * (height / (maxRange + padding)) +
          height / 2;
        let image;
        if (shape === 'circle') {
          context.beginPath();
          context.arc(xPos, yPos, 2, 0, 2 * Math.PI);
          context.stroke();
          context.fill();
        } else if (shape === 'square') {
          context.fillRect(xPos - 2, yPos - 2, 4, 4);
        } else if (shape === 'triangle') {
          context.beginPath();
          context.moveTo(xPos, yPos - 2);
          context.lineTo(xPos + 2, yPos + 2);
          context.lineTo(xPos - 2, yPos + 2);
          context.closePath();
          context.fill();
        }
      });

      const thumbnailDataURL = canvas.toDataURL(); // Convert the canvas image to data URL
      setThumbnailData(thumbnailDataURL);
    };

    renderThumbnail();
  }, [placements, entities, stageBackgroundImage, selectedEntities]);

  return (
    <div>{thumbnailData && <img src={thumbnailData} alt="Thumbnail" />}</div>
  );
};

export default Thumbnail;
