import { useEffect, useRef } from 'react';

const useInterval = (
  callback: () => void,
  delay: number,
  pause = false
): void => {
  // Create a ref
  const savedCallback = useRef<() => void>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Combine setInterval and clearInterval methods based on delay
  useEffect(() => {
    if (pause) {
      return;
    }

    const func = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    const intervalId = setInterval(func, delay);
    return () => clearInterval(intervalId);
  }, [delay, pause]);
};

export default useInterval;
