import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;

  p {
    &:first-child {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;

      color: #d0d0d0;

      margin-bottom: 15px;
    }
  }

  input {
    width: 100%;
    height: 19px;

    border: none;
    background: none;

    color: #ffffff;

    padding-bottom: 2px;
    border-bottom: 0.5px solid #5f5f5f;
  }
`;
