import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #141712;
  display: flex;

  p {
    color: white;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--altBlack);
`;

export const MobileContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(180, 255, 135, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 49px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 28px;
    padding: 0 40px;
  }

  a {
    background: #515151;
    border-radius: 10px;
    color: var(--mainGreen);
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    min-width: 235px;
    text-align: center;
    padding: 11px 15px 12px;
    text-transform: uppercase;
  }
`;
