import React, {
  ReactChild,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { css } from '@emotion/css';
import { ThemeContext } from '../contexts/theme';

type TabProps = {
  icon: string | ((selected: boolean) => ReactNode);
  children: ReactNode;
};

type TabElementChild = React.ReactElement<TabProps>;

export function Tab(_: TabProps) {
  return <></>;
}

type TabbedProps = {
  style?: React.CSSProperties;
  children: TabElementChild | TabElementChild[];
  isMobile?: boolean;
};

export function Tabbed({ children, style, isMobile }: TabbedProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const { theme } = useContext(ThemeContext);

  const childrenArray = React.Children.toArray(children).filter(
    child =>
      !React.isValidElement(child) ||
      React.Children.count(child.props.children) > 0
  ) as TabElementChild[];

  const child = childrenArray[tabIndex] ?? null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: isMobile ? '0px' : '10px',
        ...style,
      }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: row;

          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${theme.sidebar.tabs.background};
            width: ${isMobile
              ? '500px'
              : '45px'}; /* Move the ternary outside */
            justify-content: center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin-left: 5px;

            height: 30px;
            &.active {
              height: 35px;
              border-bottom: 5px solid ${theme.sidebar.tabs.background};
            }

            &:not(.active) {
              cursor: pointer;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }

            img {
              width: ${isMobile ? '22px' : '18px'};
              height: ${isMobile ? '22px' : '18px'};
            }
          }
        `}
      >
        {childrenArray.map((child, i) => (
          <div
            key={i}
            className={i === tabIndex ? 'active' : ''}
            onClick={() => setTabIndex(i)}
          >
            {typeof child.props.icon === 'string' ? (
              <img
                src={child.props.icon}
                style={{ opacity: i === tabIndex ? 1 : 0.5 }}
              />
            ) : (
              child.props.icon(tabIndex === i)
            )}
          </div>
        ))}
      </div>
      <div
        style={{ flex: '1', overflow: 'auto', touchAction: 'pan-y' }}
        onTouchMove={event => event.stopPropagation()} // Add this line
      >
        {child?.props?.children ?? null}
      </div>
    </div>
  );
}
