import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES_MAP } from '~/config/constants';
import {
  selectedProjectSelector,
  updateProjectSelector,
  savingProjectSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import { archiveProject, deleteProject } from '~/API/projects';
import Spinner from '~/components/Loaders/Preloader';
import DeleteModal from '~/components/Modals/DeleteModal';
import {
  BaseText,
  Container,
  Content,
  Title,
  Number,
  Grid,
  TextGroup,
  Button,
  Textarea,
  Actions,
} from './styles';

const ProjectInfo = () => {
  const selectedProject = useProjectsStore(selectedProjectSelector);
  const updateProject = useProjectsStore(updateProjectSelector);
  const savingProject = useProjectsStore(savingProjectSelector);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [description, setDescription] = useState<string | null | undefined>(
    selectedProject?.description
  );
  const [projName, setProjName] = useState<string | undefined>(
    selectedProject?.name
  );

  const navigate = useNavigate();

  const handleOnArchive = async () => {
    try {
      setIsSubmitting(true);
      await archiveProject(selectedProject?._id as string);

      setIsSubmitting(false);
      setIsDeleteModalOpen(false);
      navigate(0);
    } catch (error) {
      console.log('There was an error archiving the project', error);
    }
  };

  const handleOnDelete = async () => {
    try {
      setIsSubmitting(true);
      await deleteProject(selectedProject?._id as string);

      setIsSubmitting(false);
      setIsDeleteModalOpen(false);
      navigate(0);
    } catch (error) {
      console.log('There was an error deleting the project', error);
    }
  };

  const handleOnSave = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();

    try {
      await updateProject({
        description,
        name: projName,
      });

      setIsEditable(false);
    } catch (error) {
      console.log('There was an error updating the project', error);
    }
  };

  const handleEdit = (e: React.MouseEvent): void => {
    e.preventDefault();
    setDescription(selectedProject?.description);
    setIsEditable(true);
  };

  const handleEditProjectName = (e: React.MouseEvent): void => {
    e.preventDefault();
    setProjName(selectedProject?.name);
    setIsEditable(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setDescription(e.target.value);
  };

  const handleChangeProjectName = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setProjName(e.target.value);
  };

  return (
    <>
      <Container>
        <Content>
          <Title>Project Name</Title>
          <TextGroup>
            {isEditable ? (
              <>
                <Textarea
                  rows={1}
                  value={projName as string | undefined}
                  onChange={handleChangeProjectName}
                />
              </>
            ) : (
              <BaseText onClick={handleEditProjectName}>
                {selectedProject?.name}
              </BaseText>
            )}
            <br></br>
            <Title>Project Description</Title>
            {isEditable ? (
              <>
                <Textarea
                  rows={3}
                  value={description as string | undefined}
                  onChange={handleChange}
                />
                <Actions>
                  {savingProject && (
                    <Spinner fz="28px" h="28px" color="black" />
                  )}
                  <Button
                    backgroundColor={'#CECECE'}
                    color={'#000'}
                    disabled={savingProject}
                    onClick={() => setIsEditable(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    backgroundColor={'#B4FF87'}
                    color={'#000'}
                    disabled={savingProject}
                    onClick={handleOnSave}
                  >
                    Save
                  </Button>
                </Actions>
              </>
            ) : (
              <>
                {!selectedProject?.description && (
                  <BaseText onClick={handleEdit}>
                    Write a description for your amazing project!
                  </BaseText>
                )}
                <BaseText onClick={handleEdit}>
                  {selectedProject?.description}
                </BaseText>
              </>
            )}
          </TextGroup>

          <Grid>
            <TextGroup>
              <Title>Performances</Title>
              <Number>{selectedProject?.performances?.length}</Number>
            </TextGroup>
          </Grid>

          <TextGroup>
            <Title>Delete Project</Title>
            <Button
              onClick={() => setIsDeleteModalOpen(true)}
              backgroundColor={'#FF4242'}
              color={'#FFF'}
              disabled={isSubmitting || savingProject}
            >
              Delete
            </Button>
          </TextGroup>
        </Content>
      </Container>
      <DeleteModal
        title={'You are deleting this project'}
        description={
          'Once you press delete, this will delete your entire project and all\
          performances. All parties will lose access. This cannot be undone.\
          Consider archiving this project as well.'
        }
        loading={isSubmitting}
        handleOnArchive={handleOnArchive}
        handleOnDelete={handleOnDelete}
        handleOnClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
      />
    </>
  );
};

export default ProjectInfo;
