import { UserCredential } from 'firebase/auth';
import { AUTH_ENDPOINTS } from '~/config/constants';
import { SGApi } from './axios';

export function login(inputs: UserCredential) {
  return SGApi.post(AUTH_ENDPOINTS.login, inputs.user);
}

export function register(inputs: UserCredential) {
  return SGApi.post(AUTH_ENDPOINTS.register, inputs.user);
}

export function authProvider(inputs: UserCredential) {
  return SGApi.post(AUTH_ENDPOINTS.authProvider, inputs.user);
}

//Use POST login Axios instance since we need the accessToken set on the headers
//We set credentials to true so backend can access the cookie

export function refreshMyToken() {
  return SGApi.get(AUTH_ENDPOINTS.refresh, { withCredentials: true });
}
