import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import sha1 from 'sha1';

import {
  DEFAULT_CANVAS_SIZE,
  UNITS_AS_PIXELS,
  DEFAULT_ENTITY_CIRCLE_RADIUS,
  DEFAULT_ENTITY_SIZE,
} from '~/config/constants';

/**
 * Given a token, decode it and extract the email.
 * @param {string} idTkn - The token we want to decode.
 */
export const decodeIDTokenAndGetEmail = (idTkn: string) => {
  let decoded: any = jwt_decode(idTkn);

  return decoded.email as string;
};

/**
 * Functions to detect if two circles are intersecting.
 */
export function detectCircleIntersection(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  r1: number,
  r2: number
) {
  let distSq = (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);
  let radSumSq = (r1 + r2) * (r1 + r2);
  if (distSq == radSumSq) return true;
  else if (distSq > radSumSq) return false;
  else return true;
}

/**
 * Create a DOM element and append it to the Body with the given id.
 * @param {string} wrapperId - The id for the DOM element we will be creating. This will be necessary to find it.
 */
export const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);

  return wrapperElement;
};

const OMITTED_FIELDS = ['file', 'cloud_name', 'resource_type', 'api_key'];
/**
 * Creates a Signature for Cloudinary.
 * @param {Object} payload - The payload thats going to be send to Cloudinary.
 * @param {File} payload.file - The file thats going to be saved at Cloudinary.
 * @param {string} payload.folder - The folder's name where is going to be saved the file at Cloudinary.
 * @param {number} timestamp - The timestamp for the moment when the file its going to be send to Cloudinary.
 */
export const createSignature = async (
  payload: {
    file: File;
    folder: string;
  },
  timestamp: number
) => {
  const _payload = {
    ...payload,
    timestamp,
  };
  const stringPayload = _.map(
    _.keys(_.omit(_payload, OMITTED_FIELDS)),
    key => `${key}=${_payload[key as keyof typeof _payload]}`
  ).sort();
  return await sha1(
    _.join(stringPayload, '&') + import.meta.env.VITE_CLOUDINARY_API_SECRECT
  );
};

/**
 * Read a file to be able to render/show/use it in memory.
 * @param {File} file - The file took from the computer
 */
export const readFile = (file: File) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

/**
 * Calculates the size for the canvas in pixels. It decreases the multiplier if the
 * size exceeds the limits.
 * @param width Canva's width wrote by user
 * @param height Canvas's height wrote by user
 * @param unit Unit decided by the user
 * @returns Calculated size in pixels
 */
export const scaleCanvas = (
  width: number,
  height: number,
  unit: keyof typeof UNITS_AS_PIXELS
) => {
  let startingMultiplier = UNITS_AS_PIXELS[unit];
  let _width = width * startingMultiplier;
  let _height = height * startingMultiplier;
  while (
    _width > DEFAULT_CANVAS_SIZE.width ||
    _height > DEFAULT_CANVAS_SIZE.height
  ) {
    startingMultiplier -= 1;
    _width = width * startingMultiplier;
    _height = height * startingMultiplier;
  }

  return {
    width: _width,
    height: _height,
  };
};

export const generateInitialPositions = (
  entities: [string, Entity][]
): [string, EntityPlacement][] => {
  let rowsQty = entities.length / 5 > 1 ? Math.ceil(entities.length / 5) : 1;
  let positions: [string, EntityPlacement][] = [];

  let entitiesForRow = rowsQty > 1 ? 5 : entities.length;
  let entitiesIndex = 0;
  let entitiesLeft = entities.length;

  for (let i = 0; i < rowsQty; i++) {
    let y = (rowsQty / 2 - (i + 0.5)) * (DEFAULT_ENTITY_SIZE * 1.5);
    for (let j = 0; j < entitiesForRow; j++) {
      let x = (entitiesForRow / 2 - (j + 0.5)) * (DEFAULT_ENTITY_SIZE * 1.5);
      positions.push([entities[entitiesIndex][0], { position: [x, y] }]);

      entitiesIndex++;
    }
    // Decide the next number of entities for next row.
    if (i + 1 < rowsQty) {
      entitiesLeft -= entitiesForRow;
      entitiesForRow = entitiesLeft > 5 ? 5 : entitiesLeft;
    }
  }

  return positions;
};

export const generateWaveformImage = (songUrl: string) => {
  if (!songUrl) return;
  const subStrIndex = songUrl.indexOf('upload/') + 7;
  const waveformImageUrl =
    songUrl.slice(0, subStrIndex) +
    'c_scale,h_123,w_auto/fl_waveform,co_black,b_transparent/' +
    songUrl.slice(subStrIndex).replace('mp3', 'png');
  return waveformImageUrl;
};

/* @ts-ignore */
export const pad = (n, z = 2) => {
  return `00${n}`.slice(-z);
};

/* @ts-ignore */
export const msToTime = (s, isMillisecondsAvailable = false) => {
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;

  let res = `${pad(hrs)}:${pad(mins)}:${pad(secs)}`;
  if (isMillisecondsAvailable) {
    res += `.${pad(ms, 3)}`;
  }
  return res;
};

/* @ts-ignore */
export const msToMMSS = s => {
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;

  return `${pad(mins)}:${pad(secs)}`;
};

export const getNameInitials = (name: string) => {
  const names = name.split(' ');
  if (names.length > 1) {
    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  } else {
    return names[0].charAt(0);
  }
};

export function requestImageFromUser() {
  return new Promise<File>(resolve => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'accept="image/*';
    input.onchange = () => {
      if (input.files && input.files.length) {
        const file = input.files[0];
        resolve(file);
      }
    };

    input.click();
  });
}
