/**
 * This class used to keep track of time, and synchronize it with the an
 * optional audio element.
 */
export class AudioTimer {
  private startingCurrentTime: number = 0;
  private _audio: HTMLAudioElement | null = null;

  private startingPlayTime: number | null = 0;

  private playAudio() {
    if (this._audio && this.startingPlayTime !== null) {
      if (this._audio.paused) {
        this._audio.currentTime = this.currentTime / 1000;
        this._audio.play();
      }
    }
  }

  /**
   * Start the timer, and if there is an audio element, play it.
   */
  start() {
    this.startingPlayTime = Date.now();
    this.playAudio();
  }

  /**
   * Pause the timer, and if there is an audio element, pause it.
   */
  pause() {
    this.startingCurrentTime = this.currentTime;
    this.startingPlayTime = null;
    this._audio?.pause();
  }

  private pauseListener = () => {
    if (this.isPlaying) {
      if (this._audio) {
        this._audio.currentTime = this.internalTime / 1000;
        if (this._audio.duration > this.internalTime / 1000) {
          this._audio.play();
        }
      }
    }
  };

  setAudio(value: HTMLAudioElement | null) {
    if (this._audio) {
      this._audio.removeEventListener('pause', this.pauseListener);
      this._audio.pause();
    }
    this._audio = value;
    if (this._audio) {
      this._audio.addEventListener('pause', this.pauseListener);
      this.playAudio();
    }
  }

  private get audioTime(): number | null {
    if (!this._audio) return null;
    return this._audio.currentTime * 1000;
  }

  private get internalTime(): number {
    return (
      this.startingCurrentTime +
      (this.startingPlayTime ? Date.now() - this.startingPlayTime : 0)
    );
  }

  get currentTime(): number {
    const time = this.internalTime;

    if (this.audioTime) {
      return Math.max(time, this.audioTime);
    }

    return time;
  }

  set currentTime(value: number) {
    this.startingCurrentTime = value;
    if (this._audio) {
      this._audio.currentTime = value / 1000;
    }
    if (this.startingPlayTime !== null) {
      this.startingPlayTime = Date.now();
      this.playAudio();
    }
  }

  get isPlaying(): boolean {
    return this.startingPlayTime !== null;
  }

  get audioDuration(): number | null {
    return this._audio ? this._audio.duration * 1000 : null;
  }
}
