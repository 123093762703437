import React from 'react';
import { css } from '@emotion/css';

let count = 0;
function randomString(n: number) {
  // Generates a random string of n characters

  count++;
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  return `${Array.from({ length: n })
    .map(() => characters[Math.floor(Math.random() * characters.length)])
    .join('')}${count}`;
}

export function CheckBoxToggleSwitch({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  const randomStringRef = React.useRef(randomString(64));

  return (
    <div
      className={css`
        > input[type='checkbox'] {
          display: none;

          // add default box-sizing for this scope
          &,
          &:after,
          &:before,
          & *,
          & *:after,
          & *:before,
          & + label {
            box-sizing: border-box;
            &::selection {
              background: none;
            }
          }

          + label {
            outline: 0;
            display: block;
            width: 2em;
            height: 1em;
            position: relative;
            cursor: pointer;
            user-select: none;
            &:after,
            &:before {
              position: relative;
              display: block;
              content: '';
              width: 50%;
              height: 100%;
            }

            &:after {
              left: 0;
            }

            &:before {
              display: none;
            }
          }

          &:checked + label:after {
            left: 50%;
          }
        }

        > input[type='checkbox'] {
          & + label {
            padding: 4px;
            transition: all 0.2s ease;
            background: #fff;
            border-radius: 2em;

            &:after {
              transition: all 0.2s ease;
              background: black;
              content: '';
              border-radius: 2em;
              width: 0.6em;
            }
          }

          &:checked + label {
            background: rgb(180, 255, 146);
            &:after {
              left: 60%;
              background: black;
            }
          }
        }
      `}
    >
      <input
        type="checkbox"
        id={`checkbox-${randomStringRef.current}`}
        checked={checked}
        onChange={e => {
          onChange(e.target.checked);
        }}
      />
      <label id="chekbox" htmlFor={`checkbox-${randomStringRef.current}`} />
    </div>
  );
}
