import axios, { HeadersDefaults, AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { BACKEND_ROOT, ROUTES_MAP } from '~/config/constants';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

interface CommonHeaderProperties extends HeadersDefaults {
  authorization: string;
}

export const SGApi = axios.create({
  baseURL: BACKEND_ROOT,
  transformRequest: [
    (data, headers) => {
      if (data === undefined) return;
      if (headers) headers['Content-Type'] = 'application/json';

      // TODO: I fucked up when defining the project.
      //
      //   I need to figure out a good solution
      return JSON.stringify(
        data,
        (k, v) => {
          return !!v && typeof v === 'object' && v[Symbol.iterator]
            ? [...v]
            : v;
        },
        0
      );
    },
  ],
});

SGApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 401) {
      toast.error('Please login to access this resource');
      (window as Window).location = ROUTES_MAP.login;
    }
    return Promise.reject(error);
  }
);

export const headersName = 'authorization';

export function setHeaders(accessToken: string = '') {
  SGApi.defaults.headers = {
    [headersName]: `Bearer ${accessToken}`,
  } as CommonHeaderProperties;
}

export async function getArray(path: string): Promise<unknown[] | never[]> {
  const response = await SGApi.get<unknown>(path);

  if (!Array.isArray(response.data)) {
    return [];
  }

  return response.data as unknown[];
}
