/**
 * Memoizes a function. The underlying implementation is using a WeakMap. So
 * this means that if the object passed to the function is garbage collected,
 * the memoized value will be garbage collected as well, freeing up memory.
 *
 * Note: this will not work with primitive values, such as strings, numbers,
 * booleans, etc.
 * @param fn The unary function to memoize.
 * @returns A memoized version of the function.
 */
export function memoizeWeak<T extends object, V>(fn: (v: T) => V): (v: T) => V {
  const cache = new WeakMap<T, V>();
  return (v: T) => {
    if (cache.has(v)) {
      return cache.get(v) as V;
    }
    const result = fn(v);
    cache.set(v, result);
    return result;
  };
}

/**
 * Memoizes a function. The underlying implementation is using a Map. So this
 * means that any values passed to the function will be kept in memory until
 * the function itself is garbage collected.
 * @param fn The unary function to memoize.
 * @returns the memoized version of the function.
 */
export function memoize<T, V>(fn: (v: T) => V): (v: T) => V {
  const cache = new Map<T, V>();
  return (v: T) => {
    if (cache.has(v)) {
      return cache.get(v) as V;
    }
    const result = fn(v);
    cache.set(v, result);
    return result;
  };
}
