import axios from 'axios';
import { CLOUDINARY_URLS } from '~/config/constants';
import { CloudinaryResponse } from '~/config/interfaces';
import { createSignature } from '~/config/utils';

export const uploadAsset = async (file: File, assetType: 'image' | 'auto') => {
  try {
    if (!file) return false;
    const timestamp = Math.round(new Date().getTime() / 1000);
    const formData = new FormData();
    const folder = assetType === 'image' ? 'images' : 'files';
    const signature = await createSignature(
      {
        file,
        folder,
      },
      timestamp
    );
    formData.append('file', file);
    formData.append(
      'api_key',
      import.meta.env.VITE_CLOUDINARY_API_KEY as string
    );
    formData.append('timestamp', timestamp.toString());
    formData.append('signature', signature);
    formData.append('folder', folder);

    return (
      await axios.post<CloudinaryResponse>(
        CLOUDINARY_URLS[assetType],
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    ).data;
  } catch (error) {
    console.log('There was an error uploading the asset: ', error);
  }
};
