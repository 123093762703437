import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from '~/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import '~/assets/styles/fonts.css';
import '~/assets/styles/reset.css';
import '~/assets/styles/style.css';
import ReactGA from 'react-ga4';

import { enableMapSet } from 'immer';
enableMapSet();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//Initialize GA4
ReactGA.initialize("G-537R5Q6K8G");
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
