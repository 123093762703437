import { NavigateFunction } from 'react-router-dom';
import create from 'zustand';
import { User } from '@firebase/auth-types';
import { UserCredential } from 'firebase/auth';
import * as authAPI from '~/API/auth';
import * as userAPI from '~/API/user';
import { setHeaders } from '~/API/axios';
import { ROUTES_MAP, LSTORAGE_USER_KEY } from '~/config/constants';
import { OnboardData, PricedData, IUser } from '~/config/interfaces';

interface Tokens {
  accessToken: string;
}

interface State {
  user: IUser | null;
  loading: boolean;
  authorized: Boolean;
  error: boolean | string;
  register: (user: UserCredential, navigate: NavigateFunction) => void;
  authProvider: (user: UserCredential, navigate: NavigateFunction) => void;
  login: (user: UserCredential, navigate: NavigateFunction) => void;
  getMe: (user: User) => void;
  setUser: (user: IUser) => void;
  setTokens: (data: Tokens) => void;
  setAuthorized: () => void;
  setUnauthorized: () => void;
  handleErrors: (error: any) => void;
  onboard: (onboardData: OnboardData, navigate: NavigateFunction) => void;
  priced: (pricedData: PricedData) => void;
  startAction: () => void;
}

export const useUserStore = create<State>((set, get) => ({
  user: null,
  loading: false,
  error: false,
  authorized: false,
  setAuthorized: () => {
    set({
      authorized: true,
    });
  },
  setUnauthorized: () => {
    set({
      loading: false,
      authorized: false,
    });
  },
  startAction: () => {
    set(() => {
      return {
        loading: true,
        error: false,
      };
    });
  },
  setUser: user => {
    set({ user, loading: false });
  },
  setTokens: ({ accessToken }) => {
    setHeaders(accessToken);
  },
  register: async (user, navigate) => {
    try {
      get().startAction();
      const { data } = await authAPI.register(user);

      set({
        loading: false,
        user: {
          ...data.data,
          photoURL: user?.user.photoURL,
        },
      });
      //@ts-ignore
      setHeaders(user?.user.accessToken);
      localStorage.setItem(LSTORAGE_USER_KEY, data.data.uid);
      get().setAuthorized();

      navigate(ROUTES_MAP.onboarding);
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  authProvider: async (user, navigate) => {
    try {
      get().startAction();
      const { data } = await authAPI.authProvider(user);

      set({
        loading: false,
        user: {
          ...data.data,
          photoURL: user?.user.photoURL,
        },
      });
      //@ts-ignore
      setHeaders(user?.user.accessToken);
      localStorage.setItem(LSTORAGE_USER_KEY, data.data.uid);
      get().setAuthorized();

      navigate(data.isOnboarded ? ROUTES_MAP.dashboard : ROUTES_MAP.onboarding);
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  login: async (user, navigate) => {
    try {
      get().startAction();
      const { data } = await authAPI.login(user);

      set({
        loading: false,
        user: {
          ...data.data,
          photoURL: user?.user.photoURL,
        },
      });
      //@ts-ignore
      setHeaders(user?.user.accessToken);
      localStorage.setItem(LSTORAGE_USER_KEY, data.data.uid);
      get().setAuthorized();

      navigate(data.isOnboarded ? ROUTES_MAP.dashboard : ROUTES_MAP.onboarding);
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  onboard: async (onboardData, navigate) => {
    try {
      get().startAction();
      const { data } = await userAPI.submitOnboarding(onboardData);
      const user = get().user;
      set({
        loading: false,
        user: {
          ...user,
          ...data.data,
        },
      });
      get().setAuthorized();

      navigate(ROUTES_MAP.dashboard);
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  getMe: async user => {
    try {
      get().startAction();
      const { data } = await userAPI.getMe();

      set({
        loading: false,
        user: {
          ...data.data,
          photoURL: user.photoURL,
        },
      });
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  priced: async pricedData => {
    try {
      get().startAction();
      const { data } = await userAPI.submitIsPriced(pricedData);
      const user = get().user;
      set({
        loading: false,
        user: {
          ...user,
          ...data.data,
        },
      });
      get().setAuthorized();
    } catch (error: any) {
      get().handleErrors(error.response?.data?.message);
    }
  },
  handleErrors: async (error: any) => {
    set({
      loading: false,
      error: error || 'Something went wrong.',
    });
    setTimeout(() => set({ error: false }), 4000);
  },
}));
