import styled, { keyframes } from 'styled-components';
import { menuSelector, menuItemSelector } from '@szhsin/react-menu/style-utils';
import { Menu as MenuInner } from '@szhsin/react-menu';

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerticalCenteredRow = styled(Row)`
  align-items: center;
`;

export const SpacedBetweenRow = styled(Row)`
  justify-content: space-between;
`;

export const HorizontalCenteredColumn = styled(Column)`
  align-items: center;
`;

export const BaseButton = styled.div<{
  backgroundColor: string;
  disabled?: boolean;
}>`
  width: fit-content;
  height: 33px;
  border-radius: 7px;

  padding: 0 12.5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${props => props.backgroundColor};

  margin-right: 11px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  :last-child {
    margin-right: 0;
  }

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #ffffff;
  }
`;

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
export const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const MusicIcon = styled.img`
  width: 11px;
  height: 11px;
  object-fit: contain;
`;

export const ContextMenu = styled(MenuInner)`
  ${menuSelector.name} {
    user-select: none;
    border: none;
    min-width: 80px;
    max-width: 80px;
    width: 80px;

    background-color: #515151;
    border-radius: 3px;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    padding: 0;
    margin: 10px 6px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;

    p {
      margin-left: 10px;
    }

    color: #ffffff;

    img {
      margin-right: 11.35px;
    }
  }

  ${menuItemSelector.hover} {
    background-color: transparent;
  }

  ${menuItemSelector.submenu} {
    position: relative;

    ${menuSelector.name} {
      background-color: red !important;
    }

    &::after {
      content: url('icons/PerformerRightArrow.svg');
      position: absolute;
      width: 7px;
      right: 0;
    }
  }
`;
