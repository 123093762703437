function calculateDistance(p1: [number, number], p2: [number, number]): number {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];
  return Math.sqrt(dx * dx + dy * dy);
}

// Function to find the point from a placement
function findPointWithSmallestDistance(
  placements: [string, EntityPlacement][],
  point: [number, number]
): string | null {
  if (placements.length === 0) {
    return null; // Return null if the array is empty
  }

  let closestPoint = placements[0][0];
  let minDistance = calculateDistance(point, placements[0][1].position);

  for (const placement of placements) {
    const distance = calculateDistance(point, placement[1].position);
    if (distance < minDistance) {
      minDistance = distance;
      closestPoint = placement[0];
    }
  }

  return closestPoint;
}

export function alignToCircle(placements: [string, EntityPlacement][]): {
  [key: string]: [number, number];
} {
  // Calculate the center of the circle (average position)
  const center: [number, number] = placements.reduce(
    (sum, placement) => [
      sum[0] + placement[1].position[0],
      sum[1] + placement[1].position[1],
    ],
    [0, 0]
  );
  center[0] /= placements.length;
  center[1] /= placements.length;

  // Calculate the bounding box of the array
  let minX = Math.min(...placements.map(placement => placement[1].position[0]));
  let maxX = Math.max(...placements.map(placement => placement[1].position[0]));
  let minY = Math.min(...placements.map(placement => placement[1].position[1]));
  let maxY = Math.max(...placements.map(placement => placement[1].position[1]));

  if (minX === maxX) {
    minX -= (maxY - minY) / 2;
    maxX += (maxY - minY) / 2;
  }
  if (minY === maxY) {
    minY -= (maxX - minX) / 2;
    maxY += (maxX - minX) / 2;
  }

  // Calculate the radius of the circle
  const radius =
    [
      [center[0], maxY],
      [center[0], minY],
      [minX, center[1]],
      [maxX, center[1]],
    ].reduce(
      (sum, point) =>
        sum +
        Math.sqrt(
          Math.pow(point[0] - center[0], 2) + Math.pow(point[1] - center[1], 2)
        ),
      0
    ) / 4;

  // Calculate the number of lines
  const lines =
    Math.ceil(Math.sqrt(0.5 * (placements.length - 1) + 0.25) - 0.5) + 1;

  let remainingPlacements = [...placements];
  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Iterate over each line
  for (let i = 0; i < lines; i++) {
    const pointsPerLine = i === 0 ? 1 : i * 4;

    // Calculate the angle increment between points on the circle
    const angleIncrement = (2 * Math.PI) / pointsPerLine;
    const radiusPerLine = (radius * i) / (lines - 1);

    // Iterate over each step along the line
    for (let j = 0; j < pointsPerLine; j++) {
      // Apply polar to Cartesian coordinate conversion
      const angle = j * angleIncrement;
      const x = center[0] + radiusPerLine * Math.cos(angle);
      const y = center[1] + radiusPerLine * Math.sin(angle);

      const point = findPointWithSmallestDistance(remainingPlacements, [x, y]);
      if (point) {
        remainingPlacements = remainingPlacements.filter(
          placement => placement[0] !== point
        );
        alignedPlacements[point] = [x, y];
      }
    }
  }

  return alignedPlacements;
}

export function alignToSquare(placements: [string, EntityPlacement][]): {
  [key: string]: [number, number];
} {
  // Calculate the bounding box of the array
  let minX = Math.min(...placements.map(placement => placement[1].position[0]));
  let maxX = Math.max(...placements.map(placement => placement[1].position[0]));
  let minY = Math.min(...placements.map(placement => placement[1].position[1]));
  let maxY = Math.max(...placements.map(placement => placement[1].position[1]));

  if (minX === maxX) {
    minX -= (maxY - minY) / 2;
    maxX += (maxY - minY) / 2;
  }
  if (minY === maxY) {
    minY -= (maxX - minX) / 2;
    maxY += (maxX - minX) / 2;
  }

  // Calculate the number of points each line
  const pointsPerLine = Math.floor(Math.sqrt(placements.length)) + 1;
  const lines = Math.ceil(placements.length / pointsPerLine);

  // Calculate the distance between the points
  const dx = (maxX - minX) / (pointsPerLine - 1);
  const dy = (maxY - minY) / (lines - 1);

  // Define start point
  const start: [number, number] = [minX, maxY];

  let remainingPlacements = [...placements];
  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Iterate over each line
  for (let i = 0; i < lines; i++) {
    // Iterate over each step along the line
    for (let j = 0; j < pointsPerLine; j++) {
      const x = start[0] + j * dx;
      const y = start[1] - i * dy;

      const point = findPointWithSmallestDistance(remainingPlacements, [x, y]);
      if (point) {
        remainingPlacements = remainingPlacements.filter(
          placement => placement[0] !== point
        );
        alignedPlacements[point] = [x, y];
      }
    }
  }

  return alignedPlacements;
}

export function alignToTriangle(
  placements: [string, EntityPlacement][],
  reverted = false
): {
  [key: string]: [number, number];
} {
  // Calculate the bounding box of the array
  let minX = Math.min(...placements.map(placement => placement[1].position[0]));
  let maxX = Math.max(...placements.map(placement => placement[1].position[0]));
  let minY = Math.min(...placements.map(placement => placement[1].position[1]));
  let maxY = Math.max(...placements.map(placement => placement[1].position[1]));

  if (minX === maxX) {
    const offset = (Math.sqrt((maxY - minY) * (maxY - minY)) * 4) / 3;
    minX -= offset / 2;
    maxX += offset / 2;
  }
  if (minY === maxY) {
    const offset = (Math.sqrt((maxX - minX) * (maxX - minX)) * 3) / 4;
    minY -= offset / 2;
    maxY += offset;
  }

  // Calculate the number of lines
  const lines = Math.ceil(Math.sqrt(2 * placements.length + 0.25) - 0.5);

  // Calculate the distance between the points
  const dx = (maxX - minX) / (lines - 1);
  const dy = (maxY - minY) / (lines - 1);

  // Define the start point
  const start: [number, number] = reverted
    ? [(maxX - minX) / 2 + minX, minY]
    : [(maxX - minX) / 2 + minX, maxY];

  let remainingPlacements = [...placements];
  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Iterate over each line
  for (let i = 0; i < lines; i++) {
    // Iterate over each step along the line
    for (let j = 0; j < i + 1; j++) {
      const x = start[0] + j * dx - i * 0.5 * dx;
      const y = start[1] - i * dy * (reverted ? -1 : 1);

      const point = findPointWithSmallestDistance(remainingPlacements, [x, y]);
      if (point) {
        remainingPlacements = remainingPlacements.filter(
          placement => placement[0] !== point
        );
        alignedPlacements[point] = [x, y];
      }
    }
  }

  return alignedPlacements;
}

export function alignToCircleOutline(placements: [string, EntityPlacement][]): {
  [key: string]: [number, number];
} {
  // Calculate the center of the circle (average position)
  const center: [number, number] = placements.reduce(
    (sum, placement) => [
      sum[0] + placement[1].position[0],
      sum[1] + placement[1].position[1],
    ],
    [0, 0]
  );
  center[0] /= placements.length;
  center[1] /= placements.length;

  // Calculate the radius of the circle (average distance from center)
  const radius =
    placements.reduce(
      (sum, placement) =>
        sum +
        Math.sqrt(
          Math.pow(placement[1].position[0] - center[0], 2) +
            Math.pow(placement[1].position[1] - center[1], 2)
        ),
      0
    ) / placements.length;

  // Calculate the angle increment between points on the circle
  const angleIncrement = (2 * Math.PI) / placements.length;

  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Apply polar to Cartesian coordinate conversion
  placements.forEach((placement, index) => {
    const angle = index * angleIncrement;
    const alignedX = center[0] + radius * Math.cos(angle);
    const alignedY = center[1] + radius * Math.sin(angle);

    alignedPlacements[placement[0]] = [alignedX, alignedY];
  });

  return alignedPlacements;
}

export function alignToSquareOutline(placements: [string, EntityPlacement][]): {
  [key: string]: [number, number];
} {
  // Calculate the bounding box of the array
  let minX = Math.min(...placements.map(placement => placement[1].position[0]));
  let maxX = Math.max(...placements.map(placement => placement[1].position[0]));
  let minY = Math.min(...placements.map(placement => placement[1].position[1]));
  let maxY = Math.max(...placements.map(placement => placement[1].position[1]));

  if (minX === maxX) {
    minX -= (maxY - minY) / 2;
    maxX += (maxY - minY) / 2;
  }
  if (minY === maxY) {
    minY -= (maxX - minX) / 2;
    maxY += (maxX - minX) / 2;
  }

  // Define the square points based on the bounding box of the array
  const square: [number, number][] = [
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
    [minX, minY],
  ];

  // Calculate the number of points in the array and the square
  const numArrayPoints = placements.length;
  const numSquarePoints = square.length;

  // Calculate the number of points required along each edge of the square
  const numPointsPerEdge = Math.ceil(numArrayPoints / numSquarePoints);

  let remainingPlacements = [...placements];
  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Iterate over each edge of the square
  for (let i = 0; i < numSquarePoints; i++) {
    const start = square[i];
    const end = square[(i + 1) % numSquarePoints];

    // Calculate the distance between the start and end points
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];

    let numPoints =
      i < numSquarePoints - 1
        ? numPointsPerEdge - 1
        : remainingPlacements.length;
    if (numPoints < 1) {
      numPoints = 1;
    }
    // Iterate over each step along the edge
    for (let j = 0; j < numPoints; j++) {
      // Calculate the position of the point along the edge
      const t = j / numPoints;
      const x = start[0] + t * dx;
      const y = start[1] + t * dy;

      const point = findPointWithSmallestDistance(remainingPlacements, [x, y]);
      if (point) {
        remainingPlacements = remainingPlacements.filter(
          placement => placement[0] !== point
        );
        alignedPlacements[point] = [x, y];
      }
    }
  }

  return alignedPlacements;
}

export function alignToTriangleOutline(
  placements: [string, EntityPlacement][],
  reverted = false
): {
  [key: string]: [number, number];
} {
  // Calculate the bounding box of the array
  let minX = Math.min(...placements.map(placement => placement[1].position[0]));
  let maxX = Math.max(...placements.map(placement => placement[1].position[0]));
  let minY = Math.min(...placements.map(placement => placement[1].position[1]));
  let maxY = Math.max(...placements.map(placement => placement[1].position[1]));

  if (minX === maxX) {
    const offset = (Math.sqrt((maxY - minY) * (maxY - minY)) * 4) / 3;
    minX -= offset / 2;
    maxX += offset / 2;
  }
  if (minY === maxY) {
    const offset = (Math.sqrt((maxX - minX) * (maxX - minX)) * 3) / 4;
    minY -= offset / 2;
    maxY += offset;
  }

  // Define the triangle points based on the bounding box of the array
  const triangle: [number, number][] = reverted
    ? [
        [minX, maxY],
        [(maxX - minX) / 2 + minX, minY],
        [maxX, maxY],
      ]
    : [
        [maxX, minY],
        [(maxX - minX) / 2 + minX, maxY],
        [minX, minY],
      ];

  // Calculate the number of points in the array and the triangle
  const numArrayPoints = placements.length;
  const numTrianglePoints = triangle.length;

  // Calculate the number of points required along each edge of the triangle
  const numPointsPerEdge = Math.ceil(numArrayPoints / numTrianglePoints);

  let remainingPlacements = [...placements];
  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Iterate over each edge of the triangle
  for (let i = 0; i < numTrianglePoints; i++) {
    const start = triangle[i];
    const end = triangle[(i + 1) % numTrianglePoints];

    // Calculate the distance between the start and end points
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];

    let numPoints =
      i < numTrianglePoints - 1
        ? numPointsPerEdge - 1
        : remainingPlacements.length;
    if (numPoints < 1) {
      numPoints = 1;
    }
    // Iterate over each step along the edge
    for (let j = 0; j < numPoints; j++) {
      // Calculate the position of the point along the edge
      const t = j / numPoints;
      const x = start[0] + t * dx;
      const y = start[1] + t * dy;

      const point = findPointWithSmallestDistance(remainingPlacements, [x, y]);
      if (point) {
        remainingPlacements = remainingPlacements.filter(
          placement => placement[0] !== point
        );
        alignedPlacements[point] = [x, y];
      }
    }
  }

  return alignedPlacements;
}

export function alignToHorizontalLine(
  placements: [string, EntityPlacement][]
): {
  [key: string]: [number, number];
} {
  // Find the extreme points of the array
  const minX = Math.min(
    ...placements.map(placement => placement[1].position[0])
  );
  const maxX = Math.max(
    ...placements.map(placement => placement[1].position[0])
  );
  const step =
    (placements.length > 1
      ? (maxX - minX) / (placements.length - 1)
      : maxX - minX) || 20;

  const sortedPlacements = placements.sort((x, y) => {
    if (x[1].position[0] < y[1].position[0]) {
      return -1;
    }
    if (x[1].position[0] > y[1].position[0]) {
      return 1;
    }
    return 0;
  });

  // Calculate the average y-coordinate
  const avgY =
    placements.reduce((sum, placement) => sum + placement[1].position[1], 0) /
    placements.length;

  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Align each point to the average y-coordinate
  sortedPlacements.forEach((placement, index) => {
    alignedPlacements[placement[0]] = [minX + step * index, avgY];
  });

  return alignedPlacements;
}

export function alignToVerticalLine(placements: [string, EntityPlacement][]): {
  [key: string]: [number, number];
} {
  // Find the extreme points of the array
  const minY = Math.min(
    ...placements.map(placement => placement[1].position[1])
  );
  const maxY = Math.max(
    ...placements.map(placement => placement[1].position[1])
  );
  const step =
    (placements.length > 1
      ? (maxY - minY) / (placements.length - 1)
      : maxY - minY) || 20;

  // Calculate the average y-coordinate
  const avgX =
    placements.reduce((sum, placement) => sum + placement[1].position[0], 0) /
    placements.length;

  const sortedPlacements = placements.sort((x, y) => {
    if (x[1].position[1] < y[1].position[1]) {
      return -1;
    }
    if (x[1].position[1] > y[1].position[1]) {
      return 1;
    }
    return 0;
  });

  const alignedPlacements: { [key: string]: [number, number] } = {};

  // Align each point to the average y-coordinate
  sortedPlacements.forEach((placement, index) => {
    alignedPlacements[placement[0]] = [avgX, minY + step * index];
  });

  return alignedPlacements;
}
