import React from 'react';
import styled from 'styled-components';

interface Props {
  fnClick: Function;
  name: string;
  isSelected: boolean;
  imageSrc?: string;
}

interface BtnProps {
  isSelected: boolean;
  imageSrc?: string;
}
export const Btn = styled.button<BtnProps>`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--mainWhite);
  text-align: center;
  cursor: pointer;
  border: 2px solid;
  border-radius: 9px;
  background-image: ${props =>
    props.imageSrc ? `url(${props.imageSrc})` : 'none'};
  background-size: cover; /* Resize the background image to cover the entire button */
  background-position: center; /* Center the image within the button */
  padding: 0; /* Remove padding */
  width: 150px; /* Set the width */
  height: 150px; /* Set the height */
  &:hover {
    background-color: var(--btnHoverGreen);
    transform: scale(0.99, 0.99);
    -webkit-transform: scale(0.99, 0.99);
    -moz-transform: scale(0.99, 0.99);
    transition-timing-function: ease;
    transition: transform 0.2s;
  }
`;

export const GridBtn = ({ fnClick, name, isSelected, imageSrc }: Props) => (
  <Btn
    imageSrc={imageSrc}
    isSelected={isSelected}
    style={{
      borderColor: isSelected ? 'var(--mainGreen)' : '',
    }}
    onClick={e => fnClick(e, name)}
  >
    {name}
  </Btn>
);
