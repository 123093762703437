import React, { useState } from 'react';
import {
  BsThreeDotsVertical,
  BsPencilSquare,
  BsTrashFill,
  BsFillShiftFill,
} from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/theme-dark.css';

import { ROUTES_MAP } from '~/config/constants';
import { deletePerformance } from '~/API/performances';
import {
  loadingPerformanceSelector,
  openPerformanceSelector,
  selectedPerformanceSelector,
  selectedProjectSelector,
  selectPerformanceSelector,
  selectProjectSelector,
  selectedPerformanceIdSelector,
  setLoadingPerformanceStateSelector,
  useProjectsStore,
} from '~/store/projectsStore';
import { useUserStore } from '~/store/userStore';
import Spinner from '~/components/Loaders/Preloader';
import { ContextMenu } from '~/assets/styles/styledBaseComponents';
import UpdatePerformanceModal from '~/components/Modals/UpdatePerformanceModal';
import DeleteModal from '~/components/Modals/DeleteModal';
import { FallbackImg } from '~/components/FallbackImg';
import {
  BaseText,
  Container,
  PerformanceNameContainer,
  PerformanceOptions,
  PerformanceRow,
  TableHeader,
} from './styles';

const PerformancesTable = () => {
  const selectProject = useProjectsStore(selectProjectSelector);
  const selectedProject = useProjectsStore(selectedProjectSelector);
  const selectPerformance = useProjectsStore(selectPerformanceSelector);
  const selectedPerfomance = useProjectsStore(selectedPerformanceSelector);
  const selectedPerfomanceId = useProjectsStore(selectedPerformanceIdSelector);
  const openPerformance = useProjectsStore(openPerformanceSelector);
  const setLoadingPerformanceState = useProjectsStore(
    setLoadingPerformanceStateSelector
  );
  const loadingPerformance = useProjectsStore(loadingPerformanceSelector);
  const user = useUserStore(state => state.user);
  const getMeDispatch = useUserStore(state => state.getMe);

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [deletingPerfomanceId, setDeletingPerfomanceId] = useState<string>('');

  const navigate = useNavigate();

  const handleOnOpenPerformance = async (performanceId: string) => {
    try {
      const performanceResponse = await openPerformance(performanceId);
      setTimeout(() => {
        setLoadingPerformanceState(false);
        navigate(`/project/${selectedProject?._id}/${performanceId}`);
      }, 0);
    } catch (error) {
      console.log('There was an error opening the performance', error);
    }
  };

  const handleOnDelete = async () => {
    try {
      setIsSubmitting(true);
      await deletePerformance(
        selectedProject?._id as string,
        deletingPerfomanceId
      );
      await selectProject(selectedProject?._id as string);
      await getMeDispatch(user);

      setDeletingPerfomanceId('');
      setIsSubmitting(false);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log('There was an error deleting the project', error);
    }
  };

  const handleClick = (e: React.MouseEvent, performanceId: string) => {
    if (e.detail === 2) {
      // Double Click
      handleOnOpenPerformance(performanceId);
    }
  };

  return (
    <>
      <TableHeader>
        <p>Name</p>
        <p>Music</p>
        <p>Date Created</p>
      </TableHeader>
      <Container>
        {selectedProject?.performances.map(
          (performance: ProjectPerformance) => {
            const date = DateTime.fromISO(performance.createdAt);
            const formattedDate = date.toLocaleString(DateTime.DATETIME_MED);
            return (
              <PerformanceRow
                isSelected={performance._id == selectedPerfomanceId}
                key={performance._id}
                onClick={event => handleClick(event, performance._id)}
              >
                <PerformanceNameContainer
                  onClick={() => {
                    selectPerformance(performance._id);
                  }}
                >
                  <FallbackImg
                    src={performance.imageUrl || '/images/SKImageGreen.png'}
                    alt="Performance Image"
                  />
                  <BaseText>
                    {performance.name.length > 37
                      ? `${performance.name.substring(0, 37)}...`
                      : performance.name}
                  </BaseText>
                </PerformanceNameContainer>

                {/* TODO: FIX THIS! */}
                <BaseText>
                  {performance?.music?.value?.name.length > 30
                    ? `${performance?.music?.value?.name.substring(0, 30)}...`
                    : performance?.music?.value?.name ||
                      performance.music?.type !== 'noMusic'
                    ? performance?.music?.value?.name
                    : 'No Music'}
                </BaseText>

                <BaseText>{formattedDate}</BaseText>

                <ContextMenu
                  portal
                  align="start"
                  position="auto"
                  menuButton={
                    <PerformanceOptions>
                      {(!loadingPerformance ||
                        (loadingPerformance &&
                          performance._id !== selectedPerfomance?._id)) && (
                        <BsThreeDotsVertical color="#FFFFFF" size={28} />
                      )}
                      {selectedPerfomance?._id === performance._id &&
                        loadingPerformance && (
                          <Spinner fz="28px" h="28px" color="white" />
                        )}
                    </PerformanceOptions>
                  }
                  transition
                >
                  <MenuItem
                    onClick={() => handleOnOpenPerformance(performance._id)}
                  >
                    <BsFillShiftFill color="#FFFFFF" size={15} />
                    <p>Open</p>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      selectPerformance(performance._id);
                      setIsUpdateModalOpen(true);
                    }}
                  >
                    <BsPencilSquare color="#FFFFFF" size={15} />
                    <p>Edit</p>
                  </MenuItem>

                  <MenuItem
                    style={{
                      color: '#EE3636',
                    }}
                    onClick={() => {
                      setDeletingPerfomanceId(performance._id);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <BsTrashFill color="#EE3636" size={15} />
                    <p>Delete</p>
                  </MenuItem>
                </ContextMenu>
              </PerformanceRow>
            );
          }
        )}
      </Container>
      <UpdatePerformanceModal
        handleOnClose={() => setIsUpdateModalOpen(false)}
        isOpen={isUpdateModalOpen}
      />
      <DeleteModal
        title={'You are deleting this performance'}
        description={
          'Once you press delete, this will delete your performance\
          data. All parties will lose access. This cannot be undone.\
          Consider archiving this performance as well.'
        }
        loading={isSubmitting}
        handleOnDelete={handleOnDelete}
        handleOnClose={() => setIsDeleteModalOpen(false)}
        isOpen={isDeleteModalOpen}
      />
    </>
  );
};

export default PerformancesTable;
