import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OnboardData } from '~/config/interfaces';
import { useUserStore } from '~/store/userStore';
import Spinner from '~/components/Loaders/Preloader';
import { MainBtn } from './auxiliaries/MainBtn';
import {
  Container,
  Logo,
  Form,
  FormControl,
  Label,
  Input,
  Select,
  Text,
  ColumnContainer,
  GoogleImg,
} from './style';
import { Btn, GridBtn } from './auxiliaries/GridBtn';
import band from './assets/images/Band.jpeg';
import cheer from './assets/images/Cheer.jpeg';
import dance from './assets/images/Dance.jpeg';
import film from './assets/images/Film.jpeg';
import other from './assets/images/Other.jpeg';
import theater from './assets/images/Theatre.jpeg';

interface ISelect {
  [key: string]: string[];
}

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1); // Track the current step of onboarding
  const onboardDispatch = useUserStore(state => state.onboard);
  const error = useUserStore(state => state.error);
  const loading = useUserStore(state => state.loading);
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const numSteps = 2;

  const [inputs, setInputs] = useState<OnboardData>({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    role: user?.role || '',
    purpose: user?.purpose || '',
    industry: user?.industry || '',
  });

  useEffect(() => {
    if (error) {
      const errorMessage =
        'Sorry, something went wrong - please contact support@stagekeep.com';
      toast.error(error || errorMessage);
    }
  }, [error]);

  function submitOnboard(e: React.MouseEvent) {
    e.preventDefault();
    onboardDispatch(inputs, navigate);
  }

  function nextStep(e: React.MouseEvent) {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  }

  // function prevStep(e: React.MouseEvent) {
  //   e.preventDefault();
  //   setCurrentStep(currentStep - 1);
  // }

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setInputs({ ...inputs, [target.name]: target.value });
  }

  function gridBtnClick(e: React.MouseEvent, name: string) {
    e.preventDefault();
    setInputs({ ...inputs, industry: name.toLowerCase() });
    console.log(inputs);
  }

  return (
    <>
      {currentStep === 1 && (
        <ColumnContainer>
          <Text>Tell Us About Yourself</Text>
          <Form>
            <FormControl>
              <Label htmlFor="firstName" />
              <Input
                placeholder="First Name"
                autoFocus
                id="firstName"
                value={inputs['firstName']}
                name="firstName"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="lastName" />
              <Input
                placeholder="Last Name"
                id="lastName"
                value={inputs['lastName']}
                name="lastName"
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="role" />
              <Select
                id="role"
                value={inputs['role']}
                name="role"
                onChange={handleChange}
              >
                <option disabled selected value="">
                  What kind of work do you do?
                </option>
                <option value="dancer">Dancer</option>
                <option value="cheerleader">Cheerleader</option>
                <option value="marchingBand">Marching Band</option>
                <option value="choreographer">Choreographer</option>
                <option value="coach">Coach</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
            <FormControl>
              <Label htmlFor="purpose" />
              <Select
                id="purpose"
                value={inputs['purpose']}
                name="purpose"
                onChange={handleChange}
              >
                <option disabled selected value="">
                  How will you use StageKeep?
                </option>
                <option value="personal">For personal use</option>
                <option value="work">For work</option>
              </Select>
            </FormControl>
            {/* <MainBtn fnClick={submitOnboard}>
              {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Done'}
            </MainBtn> */}
            <MainBtn fnClick={nextStep}>
              {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Next'}
            </MainBtn>
          </Form>
        </ColumnContainer>
      )}
      {currentStep === 2 && (
        <Container>
          <ColumnContainer>
            <Text>What Best Describes Your Industry?</Text>
          </ColumnContainer>
          <Form>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GridBtn
                fnClick={gridBtnClick}
                name={'Dance'}
                isSelected={inputs.industry === 'dance'}
                imageSrc={dance}
              />
              <GridBtn
                fnClick={gridBtnClick}
                name={'Theater'}
                isSelected={inputs.industry === 'theater'}
                imageSrc={theater}
              />
              <GridBtn
                fnClick={gridBtnClick}
                name={'Cheer'}
                isSelected={inputs.industry === 'cheer'}
                imageSrc={cheer}
              />
              <GridBtn
                fnClick={gridBtnClick}
                name={'Film'}
                isSelected={inputs.industry === 'film'}
                imageSrc={film}
              />
              <GridBtn
                fnClick={gridBtnClick}
                name={'Band'}
                isSelected={inputs.industry === 'band'}
                imageSrc={band}
              />
              <GridBtn
                fnClick={gridBtnClick}
                name={'Other'}
                isSelected={inputs.industry === 'other'}
                imageSrc={other}
              />
            </div>
            <ColumnContainer>
              <MainBtn fnClick={submitOnboard}>
                {loading ? <Spinner fz="1.5rem" h="100%" /> : 'Done'}
              </MainBtn>
            </ColumnContainer>
          </Form>
        </Container>
      )}
      <br />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
          marginLeft: '10px',
          marginBottom: '20px',
        }}
      >
        {Array.from({ length: numSteps }, (_, index) => (
          <div
            key={index}
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor:
                currentStep === index + 1 ? 'var(--mainGreen)' : 'gray',
              marginRight: '10px',
            }}
          ></div>
        ))}
      </div>
    </>
  );
};
export default Onboarding;
